#ir {
    // index.html
    .mainContent{
        @media screen and (max-width: 767px) {
            padding-bottom: 0;
        }
    }
    .sidebar{
        //margin-bottom: 30px;
        ul#js-active-sidebar {
            @media screen and (max-width: 767px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        li{
            &:nth-child(2) {
                @media screen and (max-width: 767px) {
                    border-top: none;
                }
            }
            .side_research_report{
                @media screen and (max-width: 767px) {
                    padding: 8.8px;
                    line-height: 1.2;
                }
            }
        }
    }
    .title03 {
        padding: 48px 25px 46px;
        background: url("../../src/ja/images/ir/bg_index01.jpg") no-repeat 100% 0, #f6f6f6;
    }
    .title05 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .title05NoIr {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .title06 {
        padding-left: 10px;
        border-left: 5px solid #333;
        font-size: 18px;
        margin-bottom: 20px;
    }
/*    .title07 {
        margin-bottom: 20px;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        border-left: none;
    }*/
    .mb10 {
        margin-bottom: 10px;
    }
    .mb20 {
        margin-bottom: 20px;
    }
    .mb50 {
        margin-bottom: 50px;
    }

    //stock_info.html
    .list01 {
        width: 225px;
        @media screen and (max-width: 440px) {
            width: 100%;
        }

        dt,
        dd {
            @media screen and (max-width: 400px) {
                float: none;
            }
        }

        dd {
            &:not(:last-child) {
                @media screen and (max-width: 400px) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .date01 {
        font-size: 14px;
        font-weight: 400;
        margin-top: 6px;
        display: block;
    }

    .leftContent01 {
        width: 52%;
        &.gift_left{width: 38%;}
        /*margin-top: 50px;*/
        @media screen and (max-width: 1000px) {
            width: 50%;
        }
        @media screen and (max-width: 800px) {
            width: 55%;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
            float: none;
            &.gift_left{width: 100%;}
        }
        p {
            width: 100%;
            // width: 62%;
            // @media screen and (max-width:900px) {
            //   width: 58%;
            // }
            @media screen and (max-width: 767px) {
                //   width: 42%;
                text-align: center;
                float: none;
            }
            // @media screen and (max-width:400px) {
            //   width: 57%;
            // }
        }

        ul {
            width: 112px;
            margin-top: 20px;
            @media screen and (max-width: 767px) and(min-width:401px) {
                margin-left: 3%;
                float: left;
            }
        }

        li {
            font-size: 12px;
            padding-left: 25px;
            text-indent: -25px;
            span {
                width: 20px;
                height: 8px;
                margin-right: 5px;
                display: inline-block;
            }

            &:first-child {
                span {
                    background: #ea544e;
                }
            }

            &:nth-child(2) {
                span {
                    background: #f99b35;
                }
            }

            &:nth-child(3) {
                span {
                    background: #f8b616;
                }
            }

            &:nth-child(4) {
                span {
                    background: #f9c545;
                }
            }

            &:nth-child(5) {
                span {
                    background: #74d0b9;
                }
            }

            &:nth-child(6) {
                span {
                    background: #3aaf9e;
                }
            }

            &:last-child {
                span {
                    background: #3b97ad;
                }
            }
        }
    }

    .rightContent01 {
        width: 45%;
        &.gift_right{width: 60%;}
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 10px;
            float: none;
            &.gift_right{width: 100%;}
        }

        p {
            margin-bottom: 5px;
        }
    }

    .content03 {
        ul {
            font-size: 12px;
            margin: 20px 0;
        }

        .attention01 {
            font-size: 12px;
        }
    }

    .content04 {
        .title04 {
            margin-bottom: 20px;
        }

        .text01 {
            margin-bottom: 30px;
            // a {
            //   text-decoration: underline;
            // }
        }

        ul {
            font-size: 12px;
            margin-top: 20px;
        }

        td {
            p {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .ir_dl_btn {
        width: 200px;
        margin-top: 20px;
        margin: 20px 0 30px;
        @media screen and (max-width: 767px) {
            // width: 260px;
            width: 80%;
            margin: 40px auto;
            text-align: center;
            float: none;
        }
        &:hover {
            opacity: 0.8;
        }
        a {
            color: #fff;
            background: url(../../src/ja/images/common/ico_common06.png) no-repeat;
            background-position: calc(100% - 10px) 50%;
            display: block;
            padding: 15px 0 14px;
            // padding:5px 10px;
            // padding-right: 25px;
            // color:#646464;
            border: 1px solid #646464;
            border-radius: 5px;
            @media screen and (max-width: 767px) {
                padding: 12px 0;
            }
            &:hover {
                opacity: 1;
            }
        }
    } /* ir_dl_btn end */

    .contentNav{
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        /*@media screen and (max-width: 767px) {
            justify-content: space-between;
            column-gap: inherit;
        }*/
        li{
            display: block;
            margin-right: 0;
        }
    }

    //ir/library cardlink
    .ir_cardlink_wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        @media screen and (max-width: 767px){
            display: block;
        }
        .ir_cardlink_list{
            width: calc(100% / 2 - 10px);
            margin-bottom: 20px;
            background-color: #fafafa;
            border: 1px solid #ccc;
            @media screen and (max-width: 767px){
                width: 100%;
            }
            a{
                display: block;
                padding: 20px;
                .ir_cardlink_ttl{
                    margin-bottom: 10px;
                    font-size: 18px;
                }
                .ir_cardlink_item{
                    display: flex;
                    justify-content: space-between;
                    .ir_cardlink_img{
                        flex-shrink: 0;
                        width: 120px;
                    }
                    .ir_cardlink_text{
                        width: 100%;
                        margin-left: 10px;
                    }
                }
            }
        }
    } /*/ir/library cardlink end */

    //ir/index.html
    .indexIr {
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
        /*.title03 {
            width: 1000px;
            margin: 0 auto 33px;
            @media screen and (max-width: 1060px) {
                width: 96%;
            }
        }*/
        
        .topContentWrap {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-bottom: 30px;
            @media screen and (max-width: 1399px) {
                flex-direction: column;
            }
            .spOnly {
                display: none;
            }
            @media screen and (max-width: 1399px) {
                .pcOnly {
                    display: none;
                }
                .spOnly {
                    display: block;
                }
            }
        }
        .topContent {
            background: url("../../src/ja/images/ir/ir_top_sumi_pc_x2.png");
            background-repeat: no-repeat;
            background-position: left top;
            padding: 30px 4vw;
            width: 100%;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            @media screen and (max-width: 1480px) {
                background: url("../../src/ja/images/ir/ir_top_sumi_pc_x2.png");
                background-repeat: no-repeat;
                background-position: -40px top;
                padding: 30px;
                width: 100%;
                background-size: cover;
            }
            @media screen and (max-width: 1399px) {
                background: url("../../src/ja/images/ir/ir_top_sumi_pc_x2.png");
                background-repeat: no-repeat;
                background-position: left top;
                padding: 100px;
                width: 100%;
                background-size: cover;
            }
            @media screen and (max-width: 1110px) {
                background: url("../../src/ja/images/ir/ir_top_sumi_tablet_x2.png");
                background-repeat: no-repeat;
                background-position: left top;
                padding: 100px 40px;
                width: 100%;
                background-size: cover;
            }
            @media screen and (max-width: 820px) {
                padding: 80px 40px;
            }
            @media screen and (max-width: 659px) {
                padding: 50px;
            }
            @media screen and (max-width: 450px) {
                padding: 30px 20px;
            }
            @media screen and (max-width: 400px) {
                padding: 15px;
            }
            .textArea {
                display: inline-block;
                padding-bottom: 20px;
                @media screen and (max-width: 1399px) {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                p {
                    font-size: 22px;
                    font-weight: 400;
                    //line-height: 39.27px;

                    @media screen and (max-width: 1399px) {
                        font-size: 24px;
                        font-weight: 400;
                        //line-height: 36.27px;
                    }
                    @media screen and (max-width: 1000px) {
                        font-size: 20px;
                        //line-height: 32.27px;
                    }
                    @media screen and (max-width: 860px) {
                        font-size: 18px;
                        //line-height: 32.27px;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 20px;
                        font-weight: 400;
                        //line-height: 32.27px;
                    }
                    @media screen and (max-width: 659px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 499px) {
                        font-size: 14px;
                    }
                    @media screen and (max-width: 370px) {
                        font-size: 10px;
                    }
                } /* p end */
            } /* textArea end */
        } /* topContent end */

        .linkBox01 {
            display: flex;
            @media screen and (max-width: 1399px) {
                justify-content: space-between;
            }
            p {
                margin-left: 4%;
                border-radius: 3px;
                width: 150px;
                &.linkBox01_left_btn{
                    width: 180px;
                }
                @media screen and (max-width: 1399px) {
                    width: 48%;
                    margin: 20px 0 30px;
                }
                &:first-child {
                    margin-left: 0px;
                    a {
                        /*@media screen and (max-width: 900px) {
                            padding-left: 15px;
                        }*/
                        @media screen and (max-width: 1399px) {
                            padding-left: 0;
                        }
                    }
                }

                // &:last-child {
                //   a {
                //     padding-left: 20px;
                //     @media screen and (max-width:900px) {
                //       padding-left: 30px;
                //     }
                //     @media screen and (max-width:767px) {
                //       padding-left: 0;
                //     }
                //   }
                // }

                &:hover {
                    opacity: 0.8;
                }
            }

            a {
                // color:#646464;
                color: #fff;
                // background-color:#ffffff;
                // border:1px solid #646464;
                border-radius: 3px;
                display: inline-block;
                // padding:10px 20px;
                padding: 10px 5px;
                // padding-right:30px;
                // background:url(../../src/ja/images/common/ico_common06.png) no-repeat calc(100% - 10px) 50%;
                background: url(../../src/ja/images/common/ico_common06.png) no-repeat 88% 50%;
                display: block;
                /*@media screen and (max-width: 900px) {
                    font-size: 12px;
                }*/
                @media screen and (max-width: 1399px) {
                    width: 100%;
                    padding: 16px 24px;
                    display: block;
                    font-size: 14px;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
        .indexLinkBtn01 {
            p {
                padding-left: 16px;
            }
        }

        .stockPriceInfo {
            flex-shrink: 0;
            width: 354px;
            margin-left: 20px;
            border: 1px solid #cccccc;
            @media screen and (max-width: 1399px) {
                width: 100%;
                margin-left: 0;
            }
            .textArea {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 25px 20px;
                border-bottom: 1px solid #cccccc;
                .ttl {
                    font-size: 24px;
                    font-weight: 400;
                }
                .toChart a {
                    display: block;
                    position: relative;
                    font-size: 12px;
                    font-weight: 700;
                    font-family: sans-serif;
                    color: #808080;
                    padding-right: 15px;
                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 10px;
                        height: 10px;
                        background-image: url(../../src/ja/images/common/ico_common03.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
            .inner {
                padding: 16px 12px;
                min-height: 200px;
                text-align: center;
                .stockInfoImg {
                    background-image: url(https://www.stockweather.co.jp/customize_close/6089/img/6089j_01.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    width: 330px;
                    height: 200px;
                    margin: 0 auto;
                }
            }

        }/* stockPriceInfo end */

        /* タブのCSS */
        label {
            &.panel-label {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                display: block;
                width: 100%;
                color: #000000;
                cursor: pointer;
                background-color: #dcdcdc;
                -webkit-transition-property: background-color, color;
                transition-property: background-color, color;
                -webkit-transition-duration: 200ms;
                transition-duration: 200ms;

                &:hover {
                    color: #000000;
                }
            } /* &.panel-label end */
        } /* .label */

        #panels {
            background-color: #ffffff;
            border: 1px solid #dfdfdf;
            border-top: none;
            @media screen and (max-width: 767px) {
                border: 0;
            }
            .mainContainer {
                box-sizing: border-box;
                max-height: 0;
                opacity: 0;
                -webkit-transition: opacity 600ms;
                transition: opacity 600ms;
                overflow-y: hidden;
            } /* mainContainer end */
        } /* panels end */

        #panel-1-ctrl {
            &:checked {
                & ~ #panels {
                    #panel-1 .mainContainer {
                        max-height: initial;
                        opacity: 1;
                    }
                } /* ~ #panels end */

                & ~ #tabs-list {
                    #li-for-panel-1 {
                        pointer-events: none;
                        cursor: default;
                        -webkit-transform: translate3d(0, 1px, 0);
                        transform: translate3d(0, 1px, 0);
                        box-shadow: none;
                        border-right: none;
                        @media screen and (max-width: 767px) {
                            border-left: 1px solid #dfdfdf;
                            border-bottom: 1px solid #dfdfdf;
                        }
                        &.last {
                            border-right: 1px solid transparent;
                        }

                        & + li {
                            border-left: 1px solid #dfdfdf;
                        }

                        .panel-label {
                            background-color: white;
                            color: #000000;
                            padding-top: 24px;
                            border-left: 1px solid #dfdfdf;
                            position: relative;
                            &:after {
                                background-color: #de0716;
                                border-right: 3px solid #de0716;
                                position: absolute;
                                left: -2px;
                                right: 0;
                            }
                        }
                    } /* li-for-panel1 end */
                } /* ~ #tabs-list end */
            }
        } /* panel-1-ctrl end */

        #panel-2-ctrl {
            &:checked {
                & ~ #panels {
                    #panel-2 .mainContainer {
                        max-height: initial;
                        opacity: 1;
                    }
                }

                & ~ #tabs-list {
                    #li-for-panel-2 {
                        pointer-events: none;
                        cursor: default;
                        -webkit-transform: translate3d(0, 1px, 0);
                        transform: translate3d(0, 1px, 0);
                        box-shadow: none;
                        border-right: none;

                        &.last {
                            border-right: 1px solid transparent;
                        }

                        & + li {
                            border-left: 1px solid #dfdfdf;
                        }

                        .panel-label {
                            background-color: #ffffff;
                            color: #000000;
                            padding-top: 24px;

                            &::after {
                                background-color: #de0716;
                            }
                        }
                    } /* li-for-panel2 end */
                } /* ~ #tabs-list end */
            }
        } /* panel-2-ctrl */

        #panel-3-ctrl {
            &:checked {
                & ~ #panels {
                    #panel-3 .mainContainer {
                        max-height: initial;
                        opacity: 1;
                    }
                }

                & ~ #tabs-list {
                    #li-for-panel-3 {
                        pointer-events: none;
                        cursor: default;
                        -webkit-transform: translate3d(0, 1px, 0);
                        transform: translate3d(0, 1px, 0);
                        box-shadow: none;
                        border-right: none;

                        &.last {
                            border-right: 1px solid transparent;
                        }

                        & + li {
                            border-left: 1px solid #dfdfdf;
                        }

                        .panel-label {
                            background-color: #ffffff;
                            color: #000000;
                            padding-top: 24px;
                            border-right: 1px solid #f5f5f5;

                            &::after {
                                background-color: #de0716;
                            }
                        }
                    } /* li-for-panel3 end */
                } /* ~ #tabs-list end */
            }
        } /* panel-3-ctrl */

        #tabs-list {
            display: flex;
            justify-content: left;
            list-style: none;
            text-align: center;
            border-bottom: 1px solid #dfdfdf;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                text-align: center;
                font-size: 16px;
                width: 25%;
                box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
                border-right: 2px solid #fff;
                position: relative;
                @media screen and (max-width: 767px) {
                    width: 38%;
                }
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
                &:hover {
                    -webkit-transition: none;
                    transition: none;
                    border-right: none;

                    &.last {
                        border-right: 1px solid transparent;
                    }

                    & + li {
                        border-left: 1px solid #f5f5f5;
                    }
                } /* &:hover end */

                .panel-label {
                    position: relative;
                    padding: 24px 0;

                    &:hover {
                        &::after {
                            background-color: #de0716;
                            -webkit-transition-duration: 200ms;
                            transition-duration: 400ms;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 6px;
                        top: 0;
                        left: 0;
                        // bottom: 100%;
                        background-color: #bcbcbc;
                    } /* &::after end */
                } /* &.panel-label end */
            } /* li end */

            .one01 {
                @media screen and (max-width: 767px) {
                    padding: 35px 0 !important;
                }
            }
        } /* tabs-list end */

        .panel-radios {
            display: none;
        }

        /* タブのCSS　ここまで */

        .mainContainer {
            margin: 0 auto;
            width: 90%;
            font-size: 15px;
            color: #333333;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        } /* mainContainer end */

        .news {
            li {
                padding: 20px 0px;
                border-bottom: 1px dotted #646464;
                color: #646464;
                @media screen and (max-width: 350px) {
                    font-size: 12px;
                }

                &:last-child {
                    border-bottom: none;
                    @media screen and (max-width: 767px) {
                        border-bottom: 1px dotted #646464;
                    }
                }

                p {
                    &.tag {
                        font-size: 12px;
                        margin-top: 5px;
                        padding: 3px 0px;
                        background-color: #b30404;
                        color: #ffffff;
                        text-align: center;
                    }

                    &.pdf {
                        margin-top: 5px;

                        span {
                            font-size: 12px;
                            color: #de0716;
                            vertical-align: top;
                            margin-right: 5px;
                        }
                    }
                }

                .left {
                    &:first-child {
                        width: 78px;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            float: none;
                        }
                    }

                    &:last-child {
                        width: 80%;
                        margin-left: 3%;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin-left: 0;
                            float: none;
                        }
                    }

                    .date,
                    .category01 {
                        @media screen and (max-width: 767px) {
                            float: left;
                        }
                    }

                    .category01 {
                        @media screen and (min-width: 768px) {
                            width: 100%;
                            margin: 5px 0 0 0;
                        }
                        @media screen and (max-width: 370px) {
                            font-size: 10px;
                            width: 58px;
                        }
                    }

                    .pdf {
                        @media screen and (max-width: 767px) {
                            margin: 0 0 0 10px;
                            float: left;
                        }
                    }
                }
            } /* li end */
        } /* ul end */

        .toNews {
            margin-top: 20px;
            @media screen and (max-width: 767px) {
                // width: 260px;
                width: 80%;
                margin: 40px auto 0;
                text-align: center;
                float: none;
            }
            &:hover {
                opacity: 0.8;
            }
            a {
                color: #fff;
                background: url(../../src/ja/images/common/ico_common06.png) no-repeat;
                background-position: calc(100% - 10px) 50%;
                display: block;
                padding: 16px 24px;
                // padding:5px 10px;
                // padding-right: 25px;
                // color:#646464;
                border: 1px solid #646464;
                border-radius: 5px;
                @media screen and (max-width: 767px) {
                    padding: 12px 0;
                }
                &:hover {
                    opacity: 1;
                }
            }
        } /* toNews end */

        .document {
            width: calc(100% + 20px);
            margin-left: -10px;
            border-collapse: separate;
            border-spacing: 10px 0px;

            tr {
                td {
                    border-bottom: 1px dotted #646464;
                    // padding-left:10px;
                    vertical-align: middle;
                    padding: 20px 0 19px;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        height: auto;
                        display: block;
                    }

                    a {
                        padding: 0 0 0 10px;
                        // display: block;
                        border-bottom: 1px solid #fff;
                        &:hover {
                            color: #de0716;
                            border-bottom: 1px solid #de0716;
                            transition-duration: 0ms;
                        }
                    }

                    &:nth-child(2) {
                        border-top: 1px dotted #646464;
                    }

                    &.grayBox {
                        width: 17%;
                        border: none;
                        color: #333333;
                        background-color: #e6e6e6;
                        font-size: 16px;
                        font-weight: bold;
                        padding: 15px;
                        vertical-align: top;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            text-align: center;
                            vertical-align: middle;
                        }
                    } /* grayBox end */
                } /* td end */
            } /* tr end */
        } /* table.document end */

        .questionList {
            padding-bottom: 50px;
            // border-bottom:1px dotted #bcbcbc;
            margin-bottom: 10px;
            @media screen and (max-width: 767px) {
                padding-bottom: 25px;
                border-bottom: 0;
            }
            li {
                background: #333;
                width: 32%;
                // height:70px;
                float: left;
                margin-left: 2%;
                margin-bottom: 20px;
                border-radius: 3px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-left: 0;
                    float: none;
                    margin-bottom: 10px;
                }
                &:nth-child(3n + 1) {
                    @media screen and (min-width: 768px) {
                        margin: 0px;
                        clear: both;
                    }
                }

                // .questionContainer {
                //   // height:100%;
                //   a {
                //     padding:10px 30px 10px 10px;
                //    background:url("../../src/ja/images/common/ico_common13.png") no-repeat calc(100% - 15px) 50%;
                //     display: block;
                //   }

                //   .whiteBlock {
                //     // position:relative;
                //     width:50px;
                //     height:50px;
                //     background:#fff;
                //     font-size:20px;
                //     font-weight:bold;
                //     text-align:center;
                //     padding-top:9px;
                //     // display: inline-block;
                //     float: left;
                //     @media screen and (max-width:1000px) and (min-width:768px) {
                //       width: 34px;
                //       height: 34px;
                //       padding-top: 1px;
                //     }
                //   } /* whiteBlock end */

                //   .text {
                //     color:#ffffff;
                //     font-weight:normal;
                //     padding-top:11px;
                //     margin-left:12px;
                //     font-size:18px;
                //     float: left;
                //     // display: inline-block;
                //     @media screen and (max-width:1000px) and (min-width:768px) {
                //       font-size: 14px;
                //       margin-left: 8px;
                //     }
                //   } /* text end */
                // } /* questionContainer */
            } /* li end */
        } /* questionList end */

        .link {
            width: 100%;
            border-collapse: separate;
            border-spacing: 20px 20px;

            tbody {
                tr {
                    td {
                        height: 65px;
                        position: relative;
                        padding: 5px;
                        padding-left: 70px;
                        width: 32%;
                        color: #646464;
                        vertical-align: middle;
                        border: 1px solid #646464;
                        border-radius: 2px;

                        &:after {
                            position: absolute;
                            content: url("../../src/ja/images/common/ico_common04.png");
                            top: calc(50% - 12px);
                            right: 10px;
                        }

                        background-size: 40px 40px;

                        &:nth-child(1) {
                            background: url(../../src/ja/images/ir/ico_01.png) no-repeat 5px 50%;
                        }

                        &:nth-child(2) {
                            background: url(../../src/ja/images/ir/ico_02.png) no-repeat 5px 50%;
                        }

                        &:nth-child(3) {
                            background: url(../../src/ja/images/ir/ico_03.png) no-repeat 5px 50%;
                        }

                        &:nth-child(4) {
                            background: url(../../src/ja/images/ir/ico_04.png) no-repeat 5px 50%;
                        }

                        &:nth-child(5) {
                            background: url(../../src/ja/images/ir/ico_05.png) no-repeat 5px 50%;
                        }

                        &:nth-child(6) {
                            background: url(../../src/ja/images/ir/ico_06.png) no-repeat 5px 50%;
                        }

                        &:nth-child(7) {
                            background: url(../../src/ja/images/ir/ico_07.png) no-repeat 5px 50%;
                        }

                        &:nth-child(8) {
                            background: url(../../src/ja/images/ir/ico_08.png) no-repeat 5px 50%;
                        }

                        &:nth-child(9) {
                            background: url(../../src/ja/images/ir/ico_09.png) no-repeat 5px 50%;
                        }

                        a {
                            display: block;
                        }
                    } /* td end */
                } /* tr end */
            } /* tbody end */
        } /* table.link end */
    } /* indexIr end */

    .linkArea01 {
        width: 49%;
        margin-bottom: 40px;
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 20px;
            float: none;
        }

        &:not(:nth-child(2n)) {
            @media screen and (min-width: 768px) {
                margin-right: 2%;
            }
        }
        h3 {
            font-size: 16px;
            font-weight: 500;
            padding: 10px 0;
            border-top: 2px solid #999;
            @media screen and (max-width: 767px) {
            }
            a {
                @media screen and (max-width: 767px) {
                    display: block;
                }
            }
        }

        li {
            // width: 32%;
            // margin-bottom: 15px;
            // border: 1px solid #999;
            // border-radius: 3px;
            // line-height: 1.2;
            margin-top: 15px;
            // @media screen and (max-width:767px) {
            //   // width: 100%;
            //   // margin: 15px 0 0 0;
            //   // border: 0;
            // }
            a {
                padding: 10px 20px;
                background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
                // padding: 14px 25px 18px 70px;
                // display: block;
                // @media screen and (max-width:767px) {
                //   padding: 10px 20px;
                // }
            }

            // &:not(:nth-child(3n)) {
            //   @media screen and (min-width:768px) {
            //     margin-right: 2%;
            //   }
            // }

            // &:first-child {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_01.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(2) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_02.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(3) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_03.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(4) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_04.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(5) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_05.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(6) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_06.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(7) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_07.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 50%;
            //     }
            //   }
            // }

            // &:nth-child(8) {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_08.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 13px;
            //     }
            //   }
            // }

            // &:last-child {
            //   a {
            //     background: url("../../src/ja/images/ir/ico_09.png") no-repeat 6px 50% , url("../../src/ja/images/common/ico_common12.png") no-repeat 95% 50%;
            //     @media screen and (max-width:767px) {
            //       background: url("../../src/ja/images/common/ico_common03.png") no-repeat 10px 13px;
            //     }
            //   }
            // }
        }

        // .one01 {
        //   a {
        //     padding: 24px 25px 24px 70px;
        //   }
        // }

        &:first-child {
            h3 {
                background: url("../../src/ja/images/ir/ico_03.png") no-repeat 35% 50%, #e6e6e6;
                background-size: 40px;
                @media screen and (max-width: 1000px) {
                    background: url("../../src/ja/images/ir/ico_03.png") no-repeat 28% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    // padding: 10px 0;
                    background: url("../../src/ja/images/ir/ico_03.png") no-repeat 42% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 640px) {
                    background: url("../../src/ja/images/ir/ico_03.png") no-repeat 40% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 500px) {
                    background: url("../../src/ja/images/ir/ico_03.png") no-repeat 35% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 400px) {
                    background: url("../../src/ja/images/ir/ico_03.png") no-repeat 32% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 350px) {
                    background: url("../../src/ja/images/ir/ico_03.png") no-repeat 30% 50%, #e6e6e6;
                    background-size: 40px;
                }
            }
        }

        &:nth-child(2) {
            h3 {
                background: url("../../src/ja/images/ir/ico_01.png") no-repeat 18% 50%, #e6e6e6;
                background-size: 40px;
                // padding: 10px 0;
                @media screen and (max-width: 1000px) {
                    background: url("../../src/ja/images/ir/ico_01.png") no-repeat 4% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    background: url("../../src/ja/images/ir/ico_01.png") no-repeat 32% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 640px) {
                    background: url("../../src/ja/images/ir/ico_01.png") no-repeat 27% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 500px) {
                    background: url("../../src/ja/images/ir/ico_01.png") no-repeat 20% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 400px) {
                    background: url("../../src/ja/images/ir/ico_01.png") no-repeat 15% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 350px) {
                    background: url("../../src/ja/images/ir/ico_01.png") no-repeat 10% 50%, #e6e6e6;
                    background-size: 40px;
                }
            }
        }

        &:nth-child(3) {
            h3 {
                background: url("../../src/ja/images/ir/ico_02.png") no-repeat 28% 50%, #e6e6e6;
                background-size: 40px;
                @media screen and (max-width: 1000px) {
                    background: url("../../src/ja/images/ir/ico_02.png") no-repeat 20% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    background: url("../../src/ja/images/ir/ico_02.png") no-repeat 38% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 640px) {
                    background: url("../../src/ja/images/ir/ico_02.png") no-repeat 34% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 500px) {
                    background: url("../../src/ja/images/ir/ico_02.png") no-repeat 28% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 400px) {
                    background: url("../../src/ja/images/ir/ico_02.png") no-repeat 26% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 350px) {
                    background: url("../../src/ja/images/ir/ico_02.png") no-repeat 22% 50%, #e6e6e6;
                    background-size: 40px;
                }
            }
        }

        &:nth-child(4) {
            h3 {
                background: url("../../src/ja/images/ir/ico_04.png") no-repeat 28% 50%, #e6e6e6;
                background-size: 40px;
                @media screen and (max-width: 1000px) {
                    background: url("../../src/ja/images/ir/ico_04.png") no-repeat 20% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    background: url("../../src/ja/images/ir/ico_04.png") no-repeat 38% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 640px) {
                    background: url("../../src/ja/images/ir/ico_04.png") no-repeat 34% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 500px) {
                    background: url("../../src/ja/images/ir/ico_04.png") no-repeat 28% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 400px) {
                    background: url("../../src/ja/images/ir/ico_04.png") no-repeat 26% 50%, #e6e6e6;
                    background-size: 40px;
                }
                @media screen and (max-width: 350px) {
                    background: url("../../src/ja/images/ir/ico_04.png") no-repeat 22% 50%, #e6e6e6;
                    background-size: 40px;
                }
            }
        }

        &:not(:last-child) {
            @media screen and (max-width: 767px) {
                margin-bottom: 30px;
            }
        }

        &.linkArea02 {
            width: 100%;
            margin-top: 60px;
            @media screen and (max-width: 767px) {
                margin-top: 0;
            }
            li {
                width: 32%;
                border: 1px solid #999;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    // border: 1px solid #999;
                }
                &:first-child {
                    background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 22% 50%,
                        url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                    background-size: 20px, auto;
                    @media screen and (max-width: 1000px) {
                        background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 10% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 767px) {
                        // background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 40% 50%, url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                        background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 40% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 640px) {
                        background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 36% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 500px) {
                        background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 32% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 400px) {
                        background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 30% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 350px) {
                        background: url("../../src/ja/images/ir/ico_05_sp.png") no-repeat 28% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                }

                &:nth-child(2) {
                    background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 28% 50%,
                        url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                    background-size: 20px, auto;
                    @media screen and (max-width: 1000px) {
                        background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 18% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 767px) {
                        background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 42% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 18px, auto;
                    }
                    @media screen and (max-width: 640px) {
                        background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 38% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 18px, auto;
                    }
                    @media screen and (max-width: 500px) {
                        background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 34% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 18px, auto;
                    }
                    @media screen and (max-width: 400px) {
                        background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 32% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 18px, auto;
                    }
                    @media screen and (max-width: 350px) {
                        background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 30% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 18px, auto;
                    }
                }

                &:last-child {
                    background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 22% 50%,
                        url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                    background-size: 18px, auto;
                    // @media screen and (max-width:767px) {
                    //   background: url("../../src/ja/images/ir/ico_06_sp.png") no-repeat 42% 50%, url("../../src/ja/images/common/ico_common03.png") no-repeat 98% 50%;
                    //   background-size: 18px, auto;
                    // }

                    @media screen and (max-width: 1000px) {
                        background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 8% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                        background-size: 20px, auto;
                    }

                    @media screen and (max-width: 767px) {
                        background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 38% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 640px) {
                        background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 35% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 500px) {
                        background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 30% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 400px) {
                        background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 28% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                    @media screen and (max-width: 350px) {
                        background: url("../../src/ja/images/ir/ico_07_sp.png") no-repeat 25% 50%,
                            url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 20px, auto;
                    }
                }

                &:not(:nth-child(3n)) {
                    @media screen and (min-width: 768px) {
                        margin-right: 2%;
                    }
                    @media print {
                        margin-right: 2%;
                    }
                }
            }

            a {
                padding: 15px 0;
                display: block;
                @media screen and (max-width: 767px) {
                    // padding: 15px 0;
                }
            }
        }
    }

/*-------------------------------- 
  株主総会資料の電子提供制度のご案内
  --------------------------------*/
    .documents_digitization_link_wrap{
      margin-bottom: 30px;
      padding: 20px 10px;
      background-color: #f6f6f6;
      .documents_digitization_link_ttl{
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }
      .documents_digitization_p{
        margin-bottom: 15px;
        text-align: center;
      }
      .documents_digitization_btn{
        width: 160px;
        padding-left: 25px;
        text-align: left;
        margin: 0 auto;
        a{
          color: #fff;
          border-radius: 3px;
          display: inline-block;
          padding: 10px 5px;
          background: url(../../src/ja/images/common/ico_common06.png) no-repeat 82% 50%;
          display: block;
        }
      }
    }
/*--- QA アコーディオン ---*/
    .accordion {
      .accordion__item {
        border: 1px solid #ccc;
        margin-top: 16px;
        cursor: pointer;
        .accordion__title {
          position: relative;
          padding: 10px 65px;
          font-size: 16px;
          cursor: pointer;
          .accordion__title_q{
            position: absolute;
            background-color: #999;
            padding: 0 20px;
            left: 0px;
            top: 0px;
            display: flex;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            height: 100%;
            align-items: center;
          }
          &::before,&::after{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            background-color: #333;
            width: 50px;
            height: 100%;
            transition: all 0.3s;
            border-top: 1px solid #333;
            border-bottom: 1px solid #333;
          }
          &::before{
            top: -4px;
            right: 20px;
            width: 8px;
            height: 8px;
            border-top: 1.5px solid #fff;
            border-right: 1.5px solid #fff;
            transform: rotate(135deg);
            z-index: 1;
          }
        }
        .accordion__content {
          padding: 10px 20px 10px 65px;
          display: none;
          cursor: pointer;
          position: relative;
          .accordion__title_a{
            position: absolute;
            background-color: #f6f6f6;
            padding: 0 20px;
            left: 0px;
            top: 0px;
            display: flex;
            font-size: 20px;
            font-weight: bold;
            height: 100%;
            align-items: center;
            border-right: 1px solid #ccc;
          }
          &.is-open{
            display: block;
          }
        }
        &.is-active{
          .accordion__title{
            border-bottom: 1px solid #ccc;
            &::before{
              top: 4px;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    .indexIr {
        .linkArea01 {
            li {
                a {
                    margin: 10px 0;
                    padding: 0 20px;
                    @media screen and (max-width: 767px) {
                        margin: 0;
                    }
                    &:hover {
                        color: #de0716;
                        // border-bottom: 1px solid #de0716;
                        text-decoration: underline #de0716;
                        transition-duration: 0ms;
                    }
                }
            }
        }
        .linkArea02 {
            li {
                a {
                    margin: 0;
                    padding: 10px 20px;
                    display: inline-block;
                    // border-bottom: 1px solid #fff;
                    background: none;
                    // display:inline-block;
                    display: block;
                    @media screen and (max-width: 767px) {
                        margin: 10px 0;
                        padding: 0;
                    }
                    // &:hover {
                    //   color: #de0716;
                    //   border-bottom: 1px solid #333;
                    //   transition-duration: 0ms;
                    // }
                    // span {
                    //   display: inline-block;
                    //   &:hover {
                    //   color: #de0716;
                    //   border-bottom: 1px solid #333;
                    //   transition-duration: 0ms;
                    //   }
                    // }
                }
            }
        }
    }

    //performance.html
    .performance01 {
        .detail01 {
            width: 48%;
            margin-bottom: 50px;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 60px;
                float: none;
            }
            p {
                margin: 20px 0 10px;
            }

            &:nth-last-child(2),
            &:last-child {
                @media screen and (min-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
        .contentNav {
            a {
                padding: 0px 0 0px 11px;
                background-position: 0px 50% !important;
                display: inline-block;
            }
            li {
                width: 18%;
                text-align: left;
                @media screen and (max-width: 767px) {
                    width: 50%;
                    margin-right: 0;
                }
                &:nth-child(5n) {
                    // padding: 12px 0 12px 28px;
                    margin-right: 0;
                }
                &:nth-child(5n + 1) {
                    clear: both;
                    @media screen and (max-width: 768px) {
                        clear: none;
                    }
                }
                &:nth-child(4) {
                    @media screen and (max-width: 767px) {
                        padding: 0;
                    }
                    a {
                        @media screen and (max-width: 767px) {
                            padding: 0 0 0 15px;
                        }
                    }
                }
                &:nth-child(3) {
                    margin-right: 2%;
                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                    }
                }
                // &:nth-child(4n+1) {
                //   clear: both;
                // }
                &:nth-child(5n + 1) {
                    clear: both;
                    @media screen and (max-width: 767px) {
                        clear: none;
                    }
                }
                &:nth-child(5) {
                    a {
                        background-position: 3% 50%;
                        @media screen and (max-width: 767px) {
                            background-position: 0 50%;
                        }
                    }
                }
                &:nth-child(6) {
                    margin-right: 2%;
                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                    }
                }
            }
            a {
                background-position: 10px 50%;
                @media screen and (max-width: 767px) {
                    background-position: 0 50%;
                }
            }
        }
    }

    .message01 {
        .president01 {
            // ul {
            //   width: 222px;
            //   @media screen and (max-width:500px) {
            //     width: 49%;
            //   }
            //   @media screen and (max-width:370px) {
            //     width: 57%;
            //   }
            // }

            li {
                &:last-child {
                    @media screen and (max-width: 500px) {
                        // margin-top: 15px;
                    }
                }
            }

            p {
                @media screen and (max-width: 767px) {
                    width: 50%;
                }
                @media screen and (max-width: 370px) {
                    width: 43%;
                }
            }

            // br {
            //   @media screen and (min-width:501px) {
            //     display: none;
            //   }
            // }
        }

        .content01 {
            margin-top: 40px;
        }

        .title05 {
            color: #c00;
        }

        .textArea01 {
            p {
                margin-top: 20px;
            }

            span {
                font-size: 12px;
            }
        }
    }

    .governance01 {
        .contentNav {
            margin-bottom: 0;
        }
        .blankLink01 {
            margin-bottom: 40px;
            text-align: right;
            a {
                padding-right: 18px;
                background: url("../../src/ja/images/common/ico_common07.png") no-repeat 100% 4px;
                background-size: 15px;
                &:hover {
                    color: #de0716;
                    border-bottom: 1px solid #de0716;
                    transition-duration: 0ms;
                }
            }
        }
        .content02 {
            li {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
            }

            h4 {
                font-weight: bold;
                margin-bottom: 10px;
            }
        }

        .image01 {
            margin-top: 30px;
        }
    }
    // IRカレンダー
    .irCalenderList {
        margin-bottom: 20px;
        li {
            padding-top: 8px;
            padding-bottom: 8px;
            border-bottom: 1px dotted #ccc;
            display: grid;
            grid-template-columns: 120px 1fr;
            grid-column-gap: 10px;
            &:first-child {
                border-top: 1px dotted #ccc;
            }
            span {
                display: block;
            }
        }
    }
    //よくある質問
    .faq01 {
        .line01 {
            padding: 10px 0;
            @media screen and (max-width: 767px) {
                padding: 0;
            }
        }
        .btn01 {
            transition: 400ms;
            width: 240px;
            background: url("../../src/ja/images/common/ico_common06.png") no-repeat 95% 50% #333;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-top: 10px;
            }
            &:hover {
                opacity: 0.8;
            }
            a {
                padding: 8px 0 7px;
                &:hover {
                    opacity: 1;
                }
            }
        }
        .faqList01 {
            h4 {
                font-size: 16px;
                font-weight: bold;

                span {
                    &:first-child {
                        color: #fff;
                        width: 45px;
                        margin-right: 6px;
                        padding: 10px 0 12px;
                        background: #de0716;
                    }

                    &:nth-child(2) {
                        width: calc(100% - 53px);
                    }
                }
            }

            .answer01 {
                clear: both;
                padding-top: 10px;

                .answer02:first-child {
                    padding: 10px 0 12px;
                    width: 45px;
                    margin-right: 8px;
                    background: #efefef;
                }
            }

            .linkBtn01 {
                width: 240px;
                margin: 10px 10px 0 0px;

                a {
                    color: #fff;
                    padding: 8px 0 7px;
                    background: url("../../src/ja/images/common/ico_common06.png") no-repeat 92% 50% #333;
                    border-radius: 3px;
                    display: block;
                }
            }

            h4 {
                display: table;
                width: 100%;

                span {
                    display: table-cell;
                    vertical-align: middle;

                    &:nth-child(2) {
                        width: calc(100% - 53px);
                    }
                }
            }

            li {
                margin-bottom: 40px;

                .linkBtn01 {
                    margin-right: 0;
                    margin-top: 10px;
                }
            }

            @media screen and (max-width: 767px) {
                .faqList01 li .linkBtn01 {
                    width: 100%;
                }
            }

            .answerText01 {
                margin-top: 10px;
                width: calc(100% - 53px);
            }

            .answerText02 {
                padding-top: 0;
                width: calc(100% - 53px);
            }

            .answerText03,
            .answerText04,
            .answerText03,
            .answerText05 {
                margin-top: 10px;
                width: calc(100% - 53px);
            }

            @media screen and (max-width: 460px) {
                .answerText03 {
                    padding-top: 0;
                }
            }

            @media screen and (max-width: 360px) {
                .answerText04 {
                    padding-top: 0;
                }
            }

            @media screen and (max-width: 500px) {
                .answerText03 {
                    padding-top: 0;
                }
            }

            @media screen and (max-width: 700px) {
                .answerText05 {
                    padding-top: 0;
                }
            }

            .linkBtn02 {
                width: 100%;
                margin: 10px 15px 0 0;
                border: 1px solid #ccc;
                border-radius: 3px;

                a {
                    padding: 8px 0 7px;
                    background: url("../../src/ja/images/common/ico_common03.png") no-repeat 92% 50%;
                    display: block;
                }
            }

            .linkBtn03 p:first-child {
                margin: 10px 15px 0 0;
            }
            .linkBtn01 {
                width: auto;
                margin-right: 10px;
                text-align: left;
                a {
                    text-decoration: underline;
                    color: #333;
                    background: url("../../src/ja/images/common/ico_common03.png") no-repeat 0 50%;
                    display: inline-block;
                    padding: 0 0 0 10px;
                    // border-bottom: 1px solid #fff;
                    &:hover {
                        color: #de0716;
                        // border-bottom: 1px solid #333;
                        text-decoration: underline #de0716;
                        transition-duration: 0ms;
                    }
                }
            }
        }
    }

    // .faqList01 {
    //   h4 {
    //     font-size: 16px;
    //     font-weight: bold;
    //     span {
    //       color: #fff;
    //       width: 45px;
    //       margin-right: 6px;
    //       padding: 2px 0 6px;
    //       background: #de0716;
    //       display: inline-block;
    //     }
    //   }

    //   p {
    //     span {
    //       padding: 2px 0 4px;
    //       display: block;
    //       &:first-child {
    //         width: 45px;
    //         margin-right: 8px;
    //         background: #efefef;
    //         border: 1px solid #ccc;
    //         border-top: 0;
    //       }

    //       &:last-child {
    //       }
    //     }
    //   }

    //   a {
    //     padding-left: 8px;
    //     background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px;
    //   }
    // }

    //個人投資家の皆様へ
    .individual01 {
        .contentNav02 {
            li {
                &:nth-child(3) {
                    margin-right: 2%;
                    @media screen and (max-width: 767px) {
                        margin-right: 5%;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                &:nth-child(2n) {
                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                    }
                }
            }
        }

        // .title04 {
        //   color: #de0716;
        //   background: none;
        //   border-top: 1px solid #de0716;
        // }

        .idea01 {
            margin-top: 50px;
            @media screen and (max-width: 767px) {
                margin-top: 32px;
            }
        }

        .ideaList01 {
            width: 23.5%;
            background: url("../../src/ja/images/ir/bg_index02.png");
            @media screen and (max-width: 1000px) {
                width: 49%;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
                float: none;
            }
            @media screen and (max-width: 400px) {
                font-size: 10px;
            }

            h4 {
                font-size: 20px;
                color: #de0716;
                background: #fff;
                border: 1px solid #de0716;
                @media screen and (max-width: 800px) {
                    font-size: 19px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .text01 {
                font-size: 46px;
                color: #fff;
                width: 55px;
                background: #de0716;
                line-height: 1.2;
                display: inline-block;
                @media screen and (max-width: 800px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 30px;
                    width: 42px;
                }
            }

            .text02 {
                padding-left: 8px;
                display: inline-block;
                @media screen and (max-width: 767px) {
                    padding-left: 5px;
                }
            }

            .floatArea01 {
                @media screen and (max-width: 767px) {
                    float: left;
                }
                @media screen and (max-width: 400px) {
                    width: 50%;
                }
            }

            .textArea01 {
                padding: 10px 10px 15px 12px;
                line-height: 1.8;
                @media screen and (min-width: 768px) {
                    font-size: 12px;
                }

                @media screen and (max-width: 767px) {
                    padding-bottom: 15px;
                }

                @media screen and (max-width: 400px) {
                    padding: 10px 5px 15px 8px;
                }
            }

            ul {
                margin-top: 10px;
                @media screen and (max-width: 767px) {
                    width: 48%;
                    margin-top: 0;
                    padding: 10px 15px;
                }
                // @media screen and (max-width:400px) {
                //   font-size: 13px;
                // }
            }

            li {
                padding-left: 8px;
                text-indent: -8px;
            }

            h6 {
                font-size: 16px;
                margin: 0 0 10px -10px;
                padding-left: 10px;
                text-indent: -10px;
            }

            .text03 {
                font-size: 16px;
                margin: 0 -6px 0 -8px;
                color: #de0716;
                @media screen and (max-width: 767px) {
                    font-size: 18px;
                    margin-right: 0;
                }
                @media screen and (max-width: 400px) {
                    font-size: 13px;
                }
            }

            &:not(:last-child) {
                @media screen and (min-width: 1001px) {
                    margin-right: 2%;
                }
                @media screen and (max-width: 1000px) {
                    margin-bottom: 20px;
                }
            }

            &:nth-child(2n + 1) {
                @media screen and (max-width: 1000px) and (min-width: 768px) {
                    clear: both;
                }
            }

            &:not(:nth-child(2n)) {
                @media screen and (max-width: 1000px) and (min-width: 768px) {
                    margin-right: 2%;
                }
            }
        }

        .individualText01 {
            margin-top: 45px;
            ul {
                // width: 240px;
                margin-top: 20px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    float: none;
                }
                @media screen and (max-width: 400px) {
                    width: 100%;
                    float: none;
                }
            }

            li {
                width: 250px;
                margin-top: 10px;
                border-radius: 3px;
                background: #333;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin: 20px auto 0;
                    float: none;
                }
                &:hover {
                    opacity: 0.8;
                }
                &:nth-child(2) {
                    margin-left: 20px;
                    @media screen and (max-width: 767px) {
                        margin: 10px auto 0;
                    }
                }
            }

            a {
                color: #fff;
                padding: 8px 0 7px;
                background: url("../../src/ja/images/common/ico_common06.png") no-repeat 92% 50%;
                display: block;
                // padding-left: 10px;
                // background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px;
                &:hover {
                    opacity: 1;
                }
            }
        }

        .image01 {
            margin-top: 50px;
        }

        // ビジネスモデル
        .individualServiceWrap {
            display:flex;
            justify-content: space-between;
            @media screen and (max-width: 820px) {
                flex-direction: column;
                justify-content: inherit;
            }
            .individualServiceImg {
                width: 48%;
                @media screen and (max-width: 820px) {
                    width: 100%;
                    //padding-inline: 40px;
                }
                @media screen and (max-width: 767px) {
                    padding-inline: 0;
                }
            }
            .individualServiceText {
                width: 50%;
                @media screen and (max-width: 820px) {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        // 数字で見るウィルグループ
        // ーーデバイス別表示切り替え
        .figureDisplayTab{
            display: none;
        }
        .figureDisplaySp{
            display: none;
        }
        @media screen and (max-width: 820px) {
            .figureDisplayPc{
                display: none;
            }
            .figureDisplayTab{
                display: block;
            }
        }
        @media screen and (max-width: 767px) {
            .figureDisplayTab{
                display: none
            }
            .figureDisplaySp{
                display: block;
            }
        }
        // ーーマーケットシェア レイアウト
        .figureBoxWrap_3to1{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-top: 20px;
            padding-bottom: 20px;
            @media screen and (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .figureBoxWrap_4to2to1{
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 820px) {
                flex-direction: column;
                justify-content: inherit;
            }
            .figureBoxWrap_4to2to1_inner {
                width: 50%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                border-top: 1px solid #ccc;
                padding-top: 20px;
                padding-bottom: 20px;
                @media screen and (max-width: 820px) {
                    width: 100%;
                }
                @media screen and (max-width: 767px) {
                    grid-template-columns: repeat(1, 1fr);
                    border-top: none;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
        // ーーマーケットシェア レイアウト共通中身
        .figureBoxItem{
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #ccc;
            &:last-child {
                border-right: none;
            }
            @media screen and (max-width: 767px) {
                border-right: none;
                border-bottom: 1px solid #ccc;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
        .rightBorder {
            border-right: 1px solid #ccc !important;
            @media screen and (max-width: 820px) {
                border-right: none !important;
            }
        }
        .bottomBorder {
            @media screen and (max-width: 767px) {
                border-bottom: none !important;
            }
        }
        .topBorder{
            border-top: 1px solid #ccc;
            @media screen and (max-width: 767px) {
                border-top: none !important;
            }
        }
        .mg_bottom{
            margin-bottom: 2rem;
        }
        .rankingTitle {
            font-size: 16px;
            font-weight: bold;
        }
        .grassCrown {
            position: relative;
            color: #4d4d4d;
            font-size: 16px;
            font-weight: 700;
            padding-inline: 32px;
            text-align: center;
            display: inline-block;
            span {
                color: #F00000;
                font-size: 64px;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 30px;
                height: 70px;
                background-image: url("../../src/ja/images/ir/grass crown_L.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 30px;
                height: 70px;
                background-image: url("../../src/ja/images/ir/grass crown_R.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        .figureIcon {
            max-width: 100px;
        }
        
        // ーー強み部分
        .figureColumn_3to1{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: 820px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .figureColumnItem{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                .figureColumnImg{
                    width: 100%;
                    max-width: 90px;
                }
                .figureColumnList {
                    dt{
                        color: #F00000;
                        font-size: 24px;
                        text-align: center;
                        margin-bottom: 10px;
                    }
                    dd {
                        color: #000;
                        font-size: 16px;
                        padding-left: 1em;
                        &::before {
                            content: "・";
                            margin-left: -1em;
                        }
                    }
                }
            }
        }
        // ーーグラフ部分
        .figureGraphWrap {
            padding: 20px;
            border-bottom: 1px solid #ccc;
            &:last-child {
                border-bottom: none;
            }
            @media screen and (max-width: 820px) {
                padding: 20px 0;
            }
        }
        // ーー事業基盤
        .BusinessFoundation_3to1 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-top: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
            @media screen and (max-width: 820px) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
        .BusinessFoundation_2to1 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
            @media screen and (max-width: 820px) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
        .BusinessFoundationItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #ccc;
            padding-inline: 10px;
            &:last-child {
                border-right: none;
            }
            @media screen and (max-width: 820px) {
                border-right: none;
                border-bottom: 1px solid #ccc;
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .numberTitle {
                font-size: 16px;
                font-weight: bold;
                text-align: center;
            }
            .numberText {
                font-size: 14px;
                text-align: center;
                span {
                    color: #F00000;
                    font-size: 60px;
                    font-weight: bold;
                }
            }
        }
        // 個人投資家向け説明会
        .sessionWrap {
            display: flex;
            justify-content: space-between;
            margin-bottom: 48px;
            @media screen and (max-width: 819px) {
                flex-direction: column;
                justify-content: inherit;
            }
            .sessionImg {
                width: 220px;
                flex-shrink: 0;
                margin-right: 20px;
                margin-bottom: 20px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-right: 0;
                }
                img {
                    max-height: 129px;
                    display: block;
                    @media screen and (max-width: 767px) {
                        max-height: 100%;
                    }
                }
            }
            .sessionText {
                width: 100%;
                a {
                    text-decoration: underline;
                    &:hover {
                        color: #de0716;
                    }
                }
            }
        }
    }

    /*中期経営計画*/
    .center {
        text-align: center;
    }
    .clearfix {
        &:after {
            content: "";
            clear: both;
            display: block;
        }
    }
    .strategy01 {
        .contentNav {
            li {
                &:first-child {
                    margin-right: 2%;
                    @media screen and (max-width: 767px) {
                        margin-right: 5%;
                    }
                }

                &:nth-child(2n + 1) {
                    @media screen and (max-width: 767px) {
                        clear: both;
                    }
                }
            }
        }
    }

    .strategyBtnArea01 {
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        margin-bottom: 30px;
        li {
            max-width: 235px;
            width: 100%;
            margin-right: 15px;
            background: url("../../src/ja/images/ir/ico_strategy01.png") no-repeat 96% center;
            @media screen and (max-width: 767px) {
                width: 49%;
                margin-right: 0;
            }
            &:first-child {
                @media screen and (max-width: 767px) {
                    margin-right: 2%;
                    background: url("../../src/ja/images/ir/ico_strategy01.png") no-repeat 64% center;
                }
            }
            &:last-child {
                @media screen and (max-width: 767px) {
                    background: url("../../src/ja/images/ir/ico_strategy01.png") no-repeat 70% center;
                }
            }
            a {
                font-size: 15px;
                padding: 19px 0 18px;
                border: 1px solid #999;
                border-radius: 3px;
                display: block;
                @media screen and (max-width: 767px) {
                    padding: 17px 0 14px;
                }
            }
        }
    }
    .strategysubTittle01 {
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
        span {
            font-size: 16px;
            @media screen and (max-width: 767px) {
                font-size: 12px;
                display: block;
            }
        }
    }
    .pdfArea01 {
        margin-bottom: 30px;
        .strategysubPdf {
            &:last-child {
                margin-bottom: 0;
            }
            a {
                &:hover {
                    border-bottom: 1px solid #de0716;
                    transition-duration: 0ms;
                }
            }
            margin-bottom: 10px;
        }
    }
    .contenttxt01 {
        font-size: 16px;
        font-weight: bold;
        @media screen and (max-width: 767px) {
            font-size: 10px;
        }
    }
    .strategyContent01 {
        border: 2px solid #de0716;
        border-bottom: 0;
        padding: 22px 20px;
        background: url("../../src/ja/images/ir/bg_strategy01.jpg");
        .contenttxt02 {
            color: #de0716;
            // font-size: 22px;
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 10px;
            @media screen and (max-width: 767px) {
                font-size: 15px;
                margin-bottom: 25px;
            }
        }
    }
    .strategyContent02 {
        border: 2px solid #de0716;
        padding: 22px 20px;
        background: url("../../src/ja/images/ir/bg_strategy01.jpg");
        @media screen and (max-width: 767px) {
            padding: 24px 11px;
            font-weight: normal;
        }
    }
    .contenttxt03 {
        color: #de0716;
        font-size: 22px;
        font-weight: bold;
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
        span {
            color: #333;
            font-size: 16px;
            font-weight: normal;
            display: block;
        }
    }
    .strategyContent03 {
        padding: 112px 0 70px;
        max-width: 735px;
        width: 100%;
        margin: 0 auto;
        background: url("../../src/ja/images/ir/ico_strategy02.png") no-repeat center 11px;
        @media screen and (max-width: 767px) {
            padding: 142px 0 82px;
            background-position: center 30px;
        }
        @media screen and (max-width: 640px) {
            background-size: 200px;
        }
        li {
            width: 32%;
            margin-right: 2%;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 40px;
                float: none;
            }
            &:last-child {
                margin-right: 0;
                @media screen and (max-width: 767px) {
                    margin-bottom: 0;
                }
                .box02 {
                    .b0x02Txt01 {
                        // padding: 23px 0 23px;
                        padding: 20px 0 20px;
                        &:first-child {
                            padding: 10px 23px;
                        }
                    }
                }
            }
            .box01 {
                width: 100%;
                h4 {
                    font-size: 16px;
                    line-height: 1;
                    vertical-align: bottom;
                    padding: 7px 0 5px;
                    span {
                        font-size: 25px;
                        margin-left: 10px;
                    }
                }
                p {
                    font-size: 22px;
                    font-weight: bold;
                    padding: 17px;
                    @media screen and (max-width: 767px) {
                        font-size: 13px;
                        padding: 22px 0;
                    }
                }
            }
            .box02 {
                width: 100%;
                padding-top: 51px;
                background: url("../../src/ja/images/ir/ico_strategy03.png") no-repeat center 20px;
                .b0x02Txt01 {
                    padding: 27px 0;
                    background: url("../../src/ja/images/ir/bg_strategy02.jpg");
                    border: 1px solid #bdbdbd;
                    border-bottom: 0;
                    @media screen and (max-width: 767px) {
                        padding: 13px 0 14px;
                    }
                    &:last-child {
                        border-bottom: 1px solid #bdbdbd;
                    }
                }
            }

            &:not(:last-child) {
                .box01 {
                    p {
                        padding-top: 30px;
                        @media screen and (max-width: 1048px) {
                            padding-top: 48px;
                        }
                        @media screen and (max-width: 1057px) {
                            padding-top: 64px;
                        }
                        @media screen and (max-width: 767px) {
                            padding-top: 22px;
                        }
                    }
                }
            }
        }
    }
    .strategyList01text01 {
        &:hover {
            color: #de0716;
            text-decoration: underline #de0716;
            // border-bottom: 1px solid #de0716;
            transition-duration: 0ms;
        }
    }
    .strategylist01 {
        border: 1px solid #c76429;
        &:hover {
            opacity: 0.8;
            border-bottom: 1px solid #de0716;
            transition-duration: 0ms;
        }
        h4 {
            background: #ead2c4;
        }
    }
    .strategylist02 {
        border: 1px solid #95bac6;
        &:hover {
            opacity: 0.8;
        }
        h4 {
            background: #bae0ea;
        }
    }
    .strategylist03 {
        border: 1px solid #468152;
        &:hover {
            opacity: 0.8;
        }
        h4 {
            background: #c6e1cb;
        }
    }
    .strategyContent04 {
        margin-bottom: 70px;
        @media screen and (max-width: 767px) {
            margin-bottom: 80px;
        }
        h2 {
            font-size: 16px;
            padding: 11px 0;
            @media screen and (max-width: 767px) {
                padding-bottom: 0;
            }
        }
        h3 {
            font-size: 22px;
            font-weight: bold;
            padding: 22px 8px;
            @media screen and (max-width: 767px) {
                font-size: 14px;
                padding: 18px 15px;
                text-align: center;
            }
        }
        .strategyTxt01 {
            margin: 12px auto 21px;
            @media screen and (max-width: 767px) {
                font-size: 12px;
                margin: 28px auto 52px;
            }
        }
        .strategyContent04Box01 {
            table {
                margin-bottom: 20px;
                min-height: 147px;
                border: 1px solid #bdbdbd;
                &:last-child {
                    padding-bottom: 0;
                }
                th,
                td {
                    @media screen and (max-width: 767px) {
                        display: block;
                    }
                }
                th {
                    font-size: 18px;
                    font-weight: bold;
                    width: 38%;
                    padding: 0 20px;
                    text-align: left;
                    vertical-align: middle;
                    @media screen and (max-width: 767px) {
                        font-size: 13px;
                        width: 100%;
                        padding: 18px 20px 21px;
                    }
                }
                td {
                    font-size: 15px;
                    width: 62%;
                    padding: 20px;
                    vertical-align: middle;
                    @media screen and (max-width: 767px) {
                        font-size: 12px;
                        width: 100%;
                    }
                }
            }
        }
    }
    .strategyLed {
        color: #c76429;
    }
    .strategyBgLed {
        background: #ead2c4;
    }
    .trategyThBg01 {
        background: url("../../src/ja/images/ir/bg_strategy03.jpg");
    }
    .strategytable01 {
        // border: 1px solid #c76429;
        th {
            background: url("../../src/ja/images/ir/bg_strategy02.jpg");
        }
    }

    .strategyBlue {
        color: #238199;
    }
    .strategyBgBlue {
        background: #bae0ea;
    }
    .trategyThBg02 {
        background: url("../../src/ja/images/ir/bg_strategy02.jpg");
    }
    .strategytable02 {
        // border: 1px solid #95bac6;
    }

    .strategyGreen {
        color: #468152;
    }
    .strategyBgGreen {
        background: #c6e1cb;
    }
    .trategyThBg03 {
        background: url("../../src/ja/images/ir/bg_strategy02.jpg");
    }
    .strategytable03 {
        // border: 1px solid #468152;
    }
    .strategyContent05 {
        .strategytext01 {
            margin-bottom: 20px;
        }
        h2 {
            font-size: 22px;
            @media screen and (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        p {
            font-size: 15px;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
    .strategyContent05ImgBox01 {
        p {
            width: 49%;
            @media screen and (max-width: 640px) {
                width: 100%;
                float: none;
            }
            &:first-child {
                margin-right: 2%;
                @media screen and (max-width: 640px) {
                    margin: 20px 0 40px 0;
                }
            }
        }
    }

    // e-notice 修正
    .noticeList {
        li {
            padding: 20px 0;
            border-bottom: 1px dotted #646464;
            .left {
                &:first-child {
                    width: 78px;
                }
                &:last-child {
                    width: 80%;
                    margin-left: 3%;
                    @media screen and (max-width: 640px) {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            img {
                margin: 0 5px 0 1em;
                @media screen and (max-width: 640px) {
                    margin: 0 5px 0 0;
                }
            }
            a {
                @media screen and (max-width: 640px) {
                    display: block;
                }
                &:hover {
                    color: #de0716;
                    border-bottom: 1px solid #de0716;
                    transition-duration: 0ms;
                }
            }
        }
    }

    //ir全体 テーブル修正
    .table01 {
        th {
            border-top: 1px solid #ccc;
            // border-left: 1px solid #ccc;
            text-align: left;
            // text-align: right;
            padding-left: 5px;
            @media screen and (max-width: 767px) {
                width: 30%;
                display: table-cell;
            }
            &:first-child {
                text-align: left;
                background: #f4f4f4;
            }
        }
        td {
            @media screen and (max-width: 767px) {
                width: 70%;
                display: table-cell;
            }
        }
    }

    .stockInfo01 {
        .contentNav02 {
            li {
                width: 13%;
                margin-right: 1.5%;
                text-align: left;
                @media screen and (max-width: 767px) {
                    width: auto;
                    margin-right: 20px;
                }

                &:nth-child(5) {
                    @media screen and (max-width: 400px) {
                        margin-right: 22px;
                    }
                }
                &:nth-child(6) {
                    margin-right: 1.5%;
                    @media screen and (min-width: 768px) {
                        // margin-right: 2%;
                    }
                }

                &:nth-child(2n + 1) {
                    @media screen and (max-width: 767px) {
                        clear: none;
                    }
                }

                &:nth-child(3n) {
                    @media screen and (max-width: 767px) {
                        // margin-right: 1.5%;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            a {
                padding-left: 18px;
                @media screen and (max-width: 767px) {
                    background-position: 3px center;
                }
                @media screen and (max-width: 350px) {
                    padding-left: 16px;
                }
            }
        }
        .table02 {
            th,
            td {
                @media screen and (max-width: 767px) {
                    padding: 10px 1%;
                }
            }
            tr {
                &:nth-child(1) {
                    th {
                        &:nth-child(n + 1) {
                            width: 40%;
                            @media screen and (max-width: 767px) {
                                width: 33.333%;
                            }
                        }
                    }
                }
            }
            th {
                border-top: 1px solid #ccc;
                @media screen and (max-width: 767px) {
                    width: 40%;
                }
            }
            td {
                @media screen and (max-width: 767px) {
                    width: 30%;
                }
            }
        }
        .devidedTable01 {
            th,
            td {
                @media screen and (max-width: 767px) {
                }
                width: 16.66%;
            }
        }
        .devidedTable02 {
            th,
            td {
                @media screen and (max-width: 767px) {
                    width: 20%;
                }
            }
        }
        .benefitTable {
            th,
            td {
                @media screen and (max-width: 767px) {
                    width: 33.33%;
                }
            }
            tr {
                &:nth-child(2) {
                    th {
                        background: none;
                        // text-align: right;
                        text-align: center;
                    }
                }
                td {
                    // text-align: right;
                    padding: 10px 0;
                    &:first-child {
                        text-align: left;
                        padding: 10px;
                    }
                }
            }
        }
        .amountTable {
            th {
                // text-align: center;
                @media screen and (max-width: 767px) {
                    width: 40%;
                }
            }
            td {
                @media screen and (max-width: 767px) {
                    width: 60%;
                }
            }
        }
    }
    .performanceContentTitle01 {
        margin: 20px auto;
        li {
            position: relative;
            padding-left: 20px;
            @media screen and (max-width: 580px) {
                width: 50%;
                text-align: center;
            }
            &:first-child {
                &:after {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: #e3c9ae;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }
            &:nth-child(2) {
                &:after {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: #a0c5d5;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }
            &:nth-child(3) {
                &:after {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: #acdca7;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }
            &:last-child {
                &:after {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: #674e82;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }
            &:nth-child(-n + 2) {
                @media screen and (max-width: 580px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .performanceTable01 {
        border: 1px solid #ccc;
        th {
            border-right: 1px solid #ccc;
            @media screen and (max-width: 767px) {
                width: 16%;
            }
        }
        td {
            border-right: 1px solid #ccc;
        }
    }
    .message01 {
        h3 {
            font-weight: bold;
        }
        .individual01 {
            .contentNav02 {
                a {
                    background-position: 12px 50%;
                }
                li {
                    @media screen and (max-width: 767px) {
                        width: 50%;
                    }
                    &:nth-child(2n + 1) {
                        @media screen and (max-width: 767px) {
                            clear: both;
                        }
                    }
                    &:not(:nth-child(3n)) {
                        @media screen and (max-width: 767px) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .irTopName {
            width: 500px;
            margin: 0 auto;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            li {
                width: 50%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    float: none;
                }
                &:last-child {
                    text-align: right;
                    @media screen and (max-width: 767px) {
                        text-align: left;
                        float: none;
                    }
                }
            }
        }
        .president01 {
            .president01LeftContent01 {
                // width: 49%;
                // margin-right: 2%;
                // @media screen and (max-width: 540px) {
                //   width: 100%;
                //   float: none;
                //   margin-right: 0;
                // }
                h3 {
                    color: #333;
                    font-weight: bold;
                }
                li {
                    @media screen and (max-width: 540px) {
                        width: 49%;
                        float: left;
                    }
                    // @media screen and (max-width: 500px) {
                    //   width: 100%;
                    // }
                    &:first-child {
                        @media screen and (max-width: 540px) {
                            margin-right: 2%;
                        }
                    }
                    &:last-child {
                        @media screen and (max-width: 540px) {
                            margin-top: 0;
                        }
                    }
                }
            }
            .president01RightContent01 {
                width: 500px;
                margin: 0 auto;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                //   width: 49%;
                //   @media screen and (max-width:767px) {
                //     width: 49%;
                // }
                // @media screen and (max-width: 540px) {
                //   width: 100%;
                //   float: none;
                //   text-align: center;
                // }
            }
        }
    }
    .pc540 {
        @media screen and (max-width: 540px) {
            display: none;
        }
    }
    .sp540 {
        display: none;
        @media screen and (max-width: 540px) {
            display: block;
        }
    }
    .messageCOntent01 {
        @media screen and (max-width: 767px) {
            text-align: left;
        }
        h3 {
            .Tittletext01 {
                font-size: 22px;
                color: #c00;
            }
        }
        h4 {
            font-weight: bold;
            .Tittletext01 {
                font-size: 22px;
                color: #c00;
            }
        }
    }
    .spTittleTxt01 {
        color: #333;
        @media screen and (max-width: 767px) {
            font-size: 17px;
        }
    }
    .questionContainer {
        &:nth-child(4) {
            // .text {
            //   padding-top: 9px;
            // }
        }
        a {
            // padding:10px 30px 10px 10px;
            padding: 5px 10px 60px 0;
            // background:url("../../src/ja/images/common/ico_common13.png") no-repeat calc(100% - 15px) 50%;
            background: url("../../src/ja/images/common/ico_common13.png") no-repeat;
            background-position: calc(100% - 15px) 50%;
            display: block;
            @media screen and (max-width: 1000px) and (min-width: 768px) {
                padding: 5px 10px 48px 10px;
            }
        }
        .whiteBlock {
            // position:relative;
            width: 50px;
            height: 50px;
            background: #fff;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            padding-top: 9px;
            display: inline-block;
            float: left;
            @media screen and (max-width: 1000px) and (min-width: 768px) {
                width: 34px;
                height: 34px;
                padding-top: 1px;
            }
        } /* whiteBlock end */

        .text {
            color: #ffffff;
            font-weight: normal;
            // padding-top:11px;
            margin-left: 12px;
            font-size: 18px;
            float: left;
            display: inline-block;
            @media screen and (max-width: 1000px) and (min-width: 768px) {
                font-size: 14px;
                margin-left: 0;
            }
        } /* text end */

        &.one01 {
            a {
                padding: 19px 10px 46px 0;
                @media screen and (max-width: 1000px) and (min-width: 768px) {
                    padding: 16px 10px 37px 10px;
                }
            }
        }
    } /* questionContainer */
    .calenderImg01 {
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        li {
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
                float: none;
            }
        }
    }
    /*
    #shareholder {//table.scssのtable-TypeBと干渉するので非表示に設定　20220526（奥田）
        th {
            text-align: right;
            &:first-child {
                text-align: left;
                background: #f4f4f4;
            }
        }
        td {
            &:first-child {
                background: #f4f4f4;
            }
        }
    }
    */
    #complimentary {
        td {
            &:first-child {
                background: #f4f4f4;
            }
        }
    }
    .spOnlyInline {
        display: none;
        @media screen and (max-width: 767px) {
            display: inline-block;
            padding: 0 20px !important;
        }
    }
    .newsList01 ul {
        &:not(:nth-child(2)) {
            display: block;
        }
    }

    .indexnewsArea01 {
        .tab02 {
            li {
                border-bottom: 1px solid #dfdfdf;
                @media screen and (max-width: 767px) {
                    width: 33.333%;
                    border-bottom: none;
                }
                &.tabLast01 {
                    background: none;
                    height: 49px;
                    border-top: none;
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
                &.active {
                    border-bottom: none;
                    &:nth-child(3n) {
                        border-right: 1px solid #dfdfdf;
                    }
                }
                &:first-child {
                    border-left: 1px solid #dfdfdf;
                }
            }
        }

        .pdf {
            @media screen and (max-width: 767px) {
                margin-left: 10px;
            }
        }
        ul {
            li {
                &:nth-child(2) {
                    @media screen and (max-width: 767px) {
                        padding: 16px 0 20px;
                    }
                }
            }
        }
        .comment {
            a {
                &:hover {
                    color: #de0716;
                    border-bottom: 1px solid #de0716;
                    transition-duration: 0ms;
                }
            }
        }
        .category01 {
            margin-left: 0;
            @media screen and (max-width: 767px) {
                margin-left: 10px;
            }
        }
        .tag02 {
            width: 65px !important;
            padding: 2px 0 !important;
        }
        .newsDataList01 {
            .newsDetail01 {
                padding: 20px 0px;
                border-bottom: 1px dotted #646464;
                color: #646464;
                p {
                    &:nth-child(2) {
                        width: auto;
                    }
                }
                div {
                    &:first-child {
                        width: 78px;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            float: none;
                        }
                        p {
                            @media screen and (max-width: 767px) {
                                float: left;
                            }
                        }
                    }
                    &:last-child {
                        width: 80%;
                        margin-left: 3%;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin-left: 0;
                            float: none;
                        }
                    }
                }
            }
        }
    }
    .newsContent01 {
        background-color: #ffffff;
        border: 1px solid #dfdfdf;
        border-top: none;
        li {
        }
    }
    .indexnewsArea01 .newsContent01 .newsList01:not(.active) {
        display: none;
    }
    .individualContent {
        .contentNav02 {
            li {
                @media screen and (max-width: 767px) {
                    width: 50%;
                    margin-right: 0;
                }
                &:nth-child(3) {
                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                    }
                }
                &:nth-child(5n) {
                    clear: both;
                    @media screen and (max-width: 820px) {
                        clear: none;
                    }
                    &:nth-child(4) {
                        @media screen and (max-width: 820px) {
                            clear: both;
                        }
                        @media screen and (max-width: 767px) {
                            clear: none;
                        }
                    }
                }
            }
        }
    }
    #meeting {
        .table01 {
            td {
                a {
                    &:hover {
                        color: #de0716;
                        border-bottom: 1px solid #de0716;
                        transition-duration: 0ms;
                    }
                }
            }
        }
    }
    .wysiwyg-wrapper {
        h1 {
            padding: 2px 5px;
            border-left: 4px solid #de0716;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        h2 {
            padding: 2px 5px;
            border-left: 4px solid #de0716;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
            line-height: 1;
        }

        h3 {
            padding: 2px 5px;
            border-left: 4px solid #de0716;
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 1;
        }

        ul {
            list-style: square;
            padding-left: 5%;
        }

        ol {
            list-style: decimal;
            padding-left: 5%;
        }

        strong {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }

        img {
            height: auto;
        }

        p {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        blockquote {
            border-left: 3px solid #b9b5b5;
            padding: 0em 1em 0em 1em;
            margin: 1em 3%;
        }

        a {
            padding-right: 10px;
            background-size: 15px;
            border-bottom: 1px solid #333;
            -webkit-transition-duration: 0ms;
            -o-transition-duration: 0ms;
            transition-duration: 0ms;
        }

        .webpage {
            padding-right: 18px;
            background: url(../../src/ja/images/common/ico_common09.png) no-repeat 100% 4px;
            background-size: 15px;
            border-bottom: 1px solid #333;
        }

        .pdfclass {
            padding-right: 10px;
            background: url(../../src/ja/images/common/ico_common07.png) no-repeat 100% 4px;
            background-size: 15px;
            border-bottom: 1px solid #333;
        }

        a:hover {
            color: #de0716;
            border-bottom: 1px solid #de0716;
            -webkit-transition-duration: 0ms;
            -o-transition-duration: 0ms;
            transition-duration: 0ms;
        }
    }

    .extitleH4 {
        font-size: 18px;
        font-weight: bold;
        color: #333;
    }

    .container {
        .main {
            .ir-content {
                margin-bottom: 60px;
            }
        }
    }

    .e-notice-ancher {
        .ir-link {
            &:hover {
                color: #de0716;
            }
        }
    }

    // 株式情報 //
    // ーー株主総会 スマート招集
    .smart-shoshuBox {
        border: 1px solid #ccc;
        padding: 10px;
        display: inline-flex;
        column-gap: 10px;
        align-items: center;
        @media screen and (max-width: 767px) {
            display: flex;
            column-gap: inherit;
            row-gap: 10px;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }
    }

    // IR内　共通使用可能パーツ追加 //
    .txtR{
        text-align: right;
    }
    .irTextArea {
        p {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    // 背景グレーのリンクボックス
    .bgGrayBox {
        width: 100%;
        background-color: #f5f5f5;
        padding: 20px;
        margin-top: 20px;
        .bgGrayBoxTitle {
            font-weight: 700;
            margin-bottom: 8px;
        }
        ul.bgGrayBoxLink li a {
            display: inline-block;
            position: relative;
            font-size: 14px;
            color: #333;
            padding:5px 25px 5px 15px;
            text-decoration: underline;
            &:hover {
                color: #de0716;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                background-image: url(../../src/ja/images/common/ico_common03.png);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}