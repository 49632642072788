/** @format */

@import "reset.scss";
@import "common.scss";
@import "header.scss";
@import "xj.scss";
@import "sidebar.scss";
@import "top.scss";
@import "profile.scss";
@import "ir.scss";
@import "sustainability.scss";
@import "privacy.scss";
@import "map.scss";
@import "contact.scss";
@import "recruit.scss";
@import "news.scss";
@import "table.scss";
@import "newNav.scss";