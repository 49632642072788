#map {
    a{
      &:hover{
        opacity: .4;
      }
    }
    .map_border_top{
      padding-top: 30px;
      border-top: 1px solid #999;
    }
    .map_link_group{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    div{
      .map_link_feature{
        width: 200px;
        margin-bottom: 16px;
        font-size: 20px;
      }
      .map_link_bottom{
          width: 100%;
          margin-bottom: 16px;
          font-size: 20px;
        }
      .map_link_item{
        display: flex;
        justify-content: space-between;
        width: 74%;
        ul{
          width: 100%;
          li{
            margin-bottom: 4px;
            .map_link_detail{
              li{
                a{
                  text-indent: 10px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  
    @media screen and (max-width: 767px) {
      margin-top: 50px;
      .map_link_group{
        display: block;
      }
      div{
        .map_link_item{
          display: block;
          ul{
            margin-bottom: 16px;
          }
        }
      }
    }
  
  
  /*    @media screen and (max-width: 767px) {
          .mainContent {
              margin-bottom: 40px;
          }
      }
  
      @media screen and (max-width: 767px) {
          .mainContent .container {
              width: 96%;
          }
      }
  
      .mapList01 {
          width: 31%;
  
          a {
              padding-left: 20px;
              background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px;
          }
  
          .blankLink a {
              padding-right: 22px;
              background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px,
                  url("../../src/ja/images/common/ico_common09.png") no-repeat 100% 4px;
              background-size: auto, 15px;
              border-bottom: 0;
          }
  
          li:not(:last-child) {
              margin-bottom: 12px;
          }
  
          .title04 {
              margin-bottom: 22px;
          }
  
          &:not(:last-child) {
              margin-bottom: 50px;
          }
  
          &:nth-child(2) {
              width: 65.5%;
          }
  
          &:nth-child(3) {
              width: 100%;
          }
      }
  
      @media screen and (max-width: 767px) {
          .mapList01 {
              width: 100%;
              float: none;
          }
      }
  
      @media screen and (max-width: 767px) {
          .mapList01 li:not(:last-child) {
              margin-bottom: 20px;
          }
      }
  
      @media screen and (min-width: 768px) {
          .mapList01:not(.clear) {
              margin-left: 3.5%;
          }
      }
  
      @media screen and (max-width: 767px) {
          .mapList01:nth-child(2) {
              width: 100%;
          }
      }
  
      .mapListBox01 ul {
          &:first-child {
              width: 36%;
              margin-right: 16%;
          }
  
          &:last-child {
              width: 44%;
          }
      }
  
      @media screen and (max-width: 1080px) {
          .mapListBox01 ul:first-child {
              margin-right: 9%;
          }
      }
  
      @media screen and (max-width: 600px) {
          .mapListBox01 ul:first-child {
              width: 100%;
              float: none;
              margin-bottom: 20px;
          }
      }
  
      @media screen and (max-width: 1080px) {
          .mapListBox01 ul:last-child {
              width: 54%;
          }
      }
  
      @media screen and (max-width: 600px) {
          .mapListBox01 ul:last-child {
              width: 100%;
              float: none;
          }
      }
  
      @media screen and (max-width: 767px) {
          .listWrap01 {
              width: 94%;
              margin: 0 auto;
          }
      }
  
      .listWrap01 {
          .mapList01 {
              h3 a,
              &:nth-child(3) a,
              &:nth-child(4) a,
              &:last-child a {
                  text-decoration: underline;
              }
          }
  
          a {
              color: #333;
              text-decoration: underline;
  
              &:hover {
                  color: #de0716;
                  text-decoration: underline;
                  -webkit-transition-duration: 0ms;
                  -o-transition-duration: 0ms;
                  transition-duration: 0ms;
              }
          }
  
          h3 a {
              text-decoration: underline;
              background: none;
  
              &:hover {
                  color: #de0716;
                  text-decoration: underline;
                  -webkit-transition-duration: 0ms;
                  -o-transition-duration: 0ms;
                  transition-duration: 0ms;
              }
          }
      }
  
      .blankLink02 {
          a {
              text-decoration: none;
              border: 0;
  
              &:hover {
                  text-decoration: none;
                  border: 0;
              }
          }
  
          span {
              text-decoration: none;
              border-bottom: 1px solid #333;
              padding-right: 20px;
              background: url("../../src/ja/images/common/ico_common05.png") no-repeat 100% 50%;
              background-size: 15px auto;
              display: inline-block;
  
              &:hover {
                  border-bottom: 1px solid #de0716;
                  text-decoration: none;
              }
          }
      }*/
  }
  