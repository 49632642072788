/* PC/SP共通 背景固定指示
--------------------------------------*/
body.fixed {
	position: fixed;
	left: 0;
}
/*::-webkit-scrollbar {
    display: none;
  }*/
/* PC版メニュー
--------------------------------------*/
#headerPcOnly {
    #headerPc {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 76px;
        background-color: rgba(255, 255, 255, 0.9);
        -webkit-backdrop-filter: saturate(180%) blur(4px);
        backdrop-filter: saturate(180%) blur(4px);
        transition: 0.6s;
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.05);
        &.active {
            background-color: #f6f6f6;
        }
        .inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95%;
            height: 76px;
            margin: 0 auto;
            .logo {
                width: 195px;
                height: 30px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            /*メガメニュー
            -------------------------------*/
            .pcHeaderNavWrap {
                margin-left: auto;
                margin-right: 76px;
                ul.pcHeaderNav {
                    display: flex;
                    align-items: center;
                    list-style: none;
                    >li {
                        display: flex;
                        align-items: center;
                        height: 76px;
                        >a {
                            font-size: 16px;
                            font-weight: 400;
                            color: #000;
                            line-height: 76px;
                            position: relative;
                            padding-inline: 16px;
                            letter-spacing: 1px;
                            &:hover {
                                color: #F00000;
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                height: 2px;
                                width: 100%;
                                background: transparent;
                                transition: all 0.3s ease 0s;
                            }
                            &.current {
                                color: #F00000;
                                background: #FFFFFF;
                                &::after {
                                    background: #F00000;
                                }
                            }
                        }
                    }
                }
                .childMenu {
                    /* 初めはメニューを隠す */
                    display: none;
                    position: absolute;
                    top: 76px;
                    left: 0;
                    width: 100%;
                    height: auto;
                    background-color: #fff;
                    border-top: 1px solid #dbdbdb;
                    z-index: 99;
                    
                    /*パネル部分*/
                    .panelMenu {
                        width: 97.5%;
                        display: grid;
                        grid-template-columns: 1.1fr 1fr 1fr 1fr 1fr ;
                        gap: 16px;
                        .headImg {
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                            }
                            .title {
                                position: absolute;
                                top: 24px;
                                left: 13%;
                                font-size: 24px;
                                font-weight: 700;
                                color: #000;
                                z-index: 2;
                                font-family: sans-serif;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .menuList {
                            padding: 12px 0 28px;
                            dt {
                                font-size: 13px;
                                font-weight: 700;
                                color: #000;
                                padding-top: 16px;
                                font-family: sans-serif;
                            }
                            dd {
                                font-size: 13px;
                                font-weight: 400;
                                color: #000;
                                padding-left: 1rem;
                                padding-top: 16px;
                            }
                            dt a:hover,dd a:hover {
                                color: #F00000;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                /*黒い透過スクリーン*/
                .coverScreen {
                    display: none;
                }
                .coverScreen.active {
                    display: block;
                    position:absolute;
                    z-index: -999;
                    top:76px;
                    left:0;
                    width:100%;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }

            /*ハンバーガーメニューボタン
            -------------------------------*/
            .openBtn {
                position: fixed;
                z-index: 9999;
                top:0;
                right: 2.5%;
                cursor: pointer;
                width: 76px;
                height: 76px;
                .openBtnContent{
                    position: relative;
                    width: 76px;
                    height: 76px;
                    span {
                        display: inline-block;
                        transition: all .4s;
                        position: absolute;
                        left: 23px;
                        &:nth-of-type(1) {
                            top:23px; 
                            width: 30px;
                            height: 2px;
                            background-color: #000;
                        }
                        &:nth-of-type(2) {
                            top:31px; 
                            width: 30px;
                            height: 2px;
                            background-color: #000;
                        }
                        &:nth-of-type(3) {
                            top: 39px;
                            &::after {
                                content:"Menu";
                                position: absolute;
                                top: 0;
                                left: -2px;
                                color: #000;
                                font-size: 10px;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            /*activeクラス付与*/ 
            .openBtn.active {
                .openBtnContent{
                    span {
                        &:nth-of-type(1) {
                            top: 20px;
                            left: 23px;
                            transform: translateY(6px) rotate(-25deg);
                            width: 30px;
                        }
                        &:nth-of-type(2) {
                            top: 32px;
                            left: 23px;
                            transform: translateY(-6px) rotate(25deg);
                            width: 30px;
                        }
                        &:nth-of-type(3) {
                            top: 39px;
                            &::after {
                                content:"Close";
                                top: 0;
                                left: -3px;
                            }
                        }
                    }
                }   
            }
        }

        /*パネル部分
        -------------------------------*/
        #globalNavPanel {
            display: none;
            position:fixed;
            z-index: 999;
            background: #f6f6f6;
            top: 76px;
            left:0;
            width:100%;
            height: calc(100vh - 76px);
            overflow-y: auto;
            padding-top: 12px;
        }
        .fadeIn{
            animation-name:fadeInAnime;
            animation-delay: 0.5s;
            animation-duration:0.5s;
            animation-fill-mode:forwards;
            opacity:0;
        }
        @keyframes fadeInAnime{
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        .globalNavHead {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95%;
            height: 76px;
            margin: 0 auto;
            a {
                display: block;
                height: 30px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        #globalNavListWrap {
            width: 95%;
            margin: 0 auto 24px;
            display: grid;
            grid-template-columns: 1fr 3fr 2fr;
            gap: 1rem;
            .globalNavList {
                display: flex;
                flex-direction: column;
                //justify-content: space-between;
                row-gap: 1rem;
                .globalNavItem {
                    position: relative;
                    width: 100%;
                    background: #fff;
                    border-radius: 10px;
                    overflow: hidden;
                    transition-duration: 400ms;
                    &:hover {
                        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                        transform: translateY(-2px);
                    }
                    /*見出し部分*/
                    .headImg {
                        position: relative;
                        width: 100%;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.5) 100%);
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                        }
                        .title {
                            position: relative;
                            padding: 24px 16px;
                            z-index: 2;
                            font-size: 16px;
                            font-weight: 700;
                            font-family: sans-serif;
                            a:hover {
                                color: #F00000;
                            }
                        }
                    }
                    /*企業情報　背景画像*/
                    .headImgCompany {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/ja/images/top/link_company_profile.png) center / cover no-repeat;
                    }
                    /*IR　背景画像*/
                    .headImgIR {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/ja/images/top/link_to_individual.png) center / cover no-repeat;
                    }
                    /*サステナビリティ 　背景画像*/
                    .headImgSustainability {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/en/images/sustainability/pic_index02_on.jpg) center / cover no-repeat;
                    }

                    /*単体　見出し部分*/
                    .navBackImg {
                        position: relative;
                        width: 100%;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: linear-gradient(90deg, #fff 30%, rgba(255, 255, 255, 0.5) 100%);
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                        }
                        .title {
                            position: relative;
                            z-index: 2;
                            font-size: 16px;
                            font-weight: 700;
                            font-family: sans-serif;
                            a {
                                padding: 16px;
                                display: block;
                                &:hover{
                                    color: #F00000;
                                }
                            }
                        }
                    }
                    /*単体　採用情報　背景画像*/
                    .navBackImgRecruit {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/ja/images/top/link_recruit.png) center / cover no-repeat;
                    }
                    /*単体　お問い合わせ　背景画像*/
                    .navBackImgContact {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/ja/images/top/link_business_callcenter.png) center / cover no-repeat;
                    }
                    /*単体　プライバシーポリシー　背景画像*/
                    .navBackImgPrivacy {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/ja/images/sustainability/pic_social_01.jpg) center / cover no-repeat;
                    }
                    /*単体　免責事項　背景画像*/
                    .navBackImgDisclaimer {
                        background: url(https://willgroup.co.jp/willg-wp/wp-content/themes/corporate/src/ja/images/top/link_governance.png) center / cover no-repeat;
                    }

                    .navlistWrap_1 {
                        display: flex;
                    }
                    .navlistWrap_2 {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }
                    .navlistWrap_3 {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                    }
                    .navList {
                        padding: 16px;
                        dt {
                            font-size: 13px;
                            font-weight: 700;
                            margin-bottom: 16px;
                            font-family: sans-serif;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        dd {
                            padding-left: 1rem;
                            font-size: 12px;
                            font-weight: 400;
                            margin-bottom: 16px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        dt a, dd a {
                                display: block;
                                &:hover{
                                    color: #F00000;
                                    text-decoration: underline;
                                }
                        }
                    }
                }
                .languageBtnWrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    column-gap: 0.5rem;
                    margin-bottom: auto;
                    .languageBtn {
                        width: 100%;
                        a {
                            font-size: 12px;
                            font-weight: 700;
                            display: block;
                            padding: 16px;
                            background-color: #fff;
                            border-radius: 10px;
                            &:hover{
                                color: #F00000;
                                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                                transform: translateY(-2px);
                            }
                            &.active {
                                font-weight: 400;
                                pointer-events: none;
                                opacity: 0.4;
                            }
                        }
                    }
                }
                .globalNavText {
                    p {
                        font-size: 12px;
                        font-weight: 300;
                        color: #000;
                        margin-bottom: 8px;
                        &:first-child {
                            margin-bottom: 16px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

#headerSpOnly {
    display: none;
}

/* SP版メニュー
--------------------------------------*/
@media screen and (max-width: 1279px) {
    #headerPcOnly {
        display: none;
    }

    #headerSpOnly {
        display: block;

        #headerSp {
            position: fixed;
            z-index: 999;
            top: 0;
            width: 100%;
            height: 60px;
            background-color: #fff;
            color: #000;
            .inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 95%;
                height: 60px;
                margin: 0 auto;
                .logo {
                    width: 195px;
                    height: 30px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .slideL { 
            animation: slideLAnime;
            animation-duration:0.5s;
            animation-delay: 0.6s;
            animation-fill-mode:forwards;
        }
        @keyframes slideLAnime {
            from {
                opacity: 1;
                transform: translateX(0);
            }
            to {
                opacity: 1;
                transform: translateX(100%);
            }
        }
        .fadeOut {    
            animation-name:fadeOutAnime;
            animation-duration:0.5s;
            animation-fill-mode:forwards;
            opacity:1;
        }
        @keyframes fadeOutAnime {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }

        /*ハンバーガーメニューボタン
        -------------------------------*/
        .openBtn {
            position: fixed;
            z-index: 9999;
            top: 0;
            right: 0;
            background:#fff;
            cursor: pointer;
            width: 60px;
            height: 60px;
            .openBtnContent {
                position: relative;
                width: 60px;
                height: 60px;
                span {
                    display: inline-block;
                    transition: all .4s;
                    position: absolute;
                    left: 15px;
                    &:nth-of-type(1) {
                        top: 15px; 
                        width: 30px;
                        height: 2px;
                        background-color: #000;
                    }
                    &:nth-of-type(2) {
                        top: 23px; 
                        width: 30px;
                        height: 2px;
                        background-color: #000;
                    }
                    &:nth-of-type(3) {
                        top: 30px;
                        &::after {
                            content:"Menu";
                            position: absolute;
                            top: 5px;
                            left: -2px;
                            color: #000;
                            font-size: 10px;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        /*activeクラス付与*/ 
        .openBtn.active {
            .openBtnContent {
                span {
                    &:nth-of-type(1) {
                        top: 12px;
                        left: 17px;
                        transform: translateY(6px) rotate(-25deg);
                        width: 30px;
                    }
                    &:nth-of-type(2) {
                        top: 24px;
                        left: 17px;
                        transform: translateY(-6px) rotate(25deg);
                        width: 30px;
                    }
                    &:nth-of-type(3) {
                        top: 30px;
                        &::after {
                            content:"Close";
                            top: 5px;
                            left: -2px;
                        }
                    }
                }
            }   
        }

        /*パネル部分
        -------------------------------*/
        #globalNav {
            display: none;
            position: fixed;
            z-index: 999;
            top: 60px;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5);
            /*ナビゲーションの縦スクロールエリア*/
            #globalNavList {
                width: 100%;
                max-height: calc(100vh - 60px);
                background-color: #fff;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
    
                /*ナビゲーション
                -------------------------------*/
                /*最初は非表示*/
                ul ul {
                    display:none;
                }
                .globalNavMenu {
                    /*第一階層 子持ち +-付け替え共通*/
                    li .hasChild {
                        display: block;
                        background-color: #fff;
                        width: 90%;
                        margin: 0 auto;
                        padding: 16px 32px 16px 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 14px;
                            height: 2px;
                            background-color: #000;
                            transition: all .4s;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 14px;
                            height: 2px;
                            background-color: #000;
                            transition: all .4s;
                            transform: translateY(-1px) rotate(-90deg);
                        }
                        &.active {
                            &::after {
                                content: "";
                                transform: translateY(-1px) rotate(-180deg);
                            }
                            &::before {
                                content: "";
                                transform: translateY(-1px) rotate(-180deg);
                            }
                        }
                    }
                    /*第一階層 単独*/
                    li {
                        list-style: none;
                        border-top: 1px solid #dbdbdb;
                        a {
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                            text-decoration: none;
                            padding: 16px 32px 16px 0;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }
                    >li {
                        &:last-child {
                            border-bottom: 1px solid #dbdbdb;
                        }
                    }

                    /*第二階層 子持ち*/
                    li ul li .hasChild {
                        display: block;
                        background-color: #e6e6e6;
                        padding: 16px 32px 16px 1rem;
                        width: 90%;
                        margin: 0 auto;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }
                    /*第二階層 単独*/
                    li ul li {
                        list-style: none;
                        border-top: 1px solid #dbdbdb;
                        background-color: #e6e6e6;
                        a {
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                            text-decoration: none;
                            padding: 16px 32px 16px 1rem;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }

                    /*第三階層 子持ち*/
                    li ul li ul li .hasChild {
                        display: block;
                        background-color: #ccc;
                        padding: 16px 32px 16px 2rem;
                        width: 90%;
                        margin: 0 auto;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }
                    /*第三階層 単独*/
                    li ul li ul li {
                        list-style: none;
                        border-top: 1px solid #dbdbdb;
                        background-color: #ccc;
                        a {
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                            text-decoration: none;
                            padding: 16px 32px 16px 2rem;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }

                    /*第四階層 子持ち*/
                    li ul li ul li ul li.hasChild {
                        display: block;
                        background-color: #bcbcbc;
                        padding: 16px 32px 16px 3rem;
                        width: 90%;
                        margin: 0 auto;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }
                    /*第四階層 単独*/
                    li ul li ul li ul li {
                        list-style: none;
                        border-top: 1px solid #dbdbdb;
                        background-color: #bcbcbc;
                        a {
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                            text-decoration: none;
                            padding: 16px 32px 16px 3rem;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }
                }
                /*言語選択*/
                .language {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p {
                        padding: 0 20px;
                        margin: 20px 0;
                        &:first-child {
                            border-right: 1px solid #000;
                        }
                        a {
                            color: #808080;
                            font-size: 12px;
                        }
                        a.active {
                            font-weight: bold;
                            color: #000;
                        }
                    }
                }
            }
        }
    }/*headerSpOnly END*/
}/*メディアクエリ END*/