@charset "UTF-8";

//新しく作成したテーブル 20220526（奥田）
//色設定
$gray1: #f6f6f6;//薄め
$gray2: #CCC;//濃いめ
$gray3: #BBB;//濃いめ
$black: #333;//黒
$pink: #fdf1f3;//ピンク
$red: #de0716;//濃いめ赤、CIカラーではない
$gray-bg: #F4F4F4;//背景の薄いグレー
$white-bg: #FFF;//背景の薄いグレー
/*
日本語版と英語版のCSSは、一部共通していますが、
個々に内容が異なるため上書きしないようにご注意ください。
なお、既存のcommon.scssのtable表記は残し、お問い合わせページなどに利用しています。
*/
.table-group{
    width: 100%;
    margin-bottom: 30px;
    .cap_top{
      text-align: left;
      margin-bottom: 10px;
    }
    .cap_bottom{//テーブル下の注釈に活用する
      text-align: left;
      font-size: 0.71rem;//10px相当
      margin-top: 5px;
      line-break: normal;
      padding-left: 1.4rem;//インデント効果１
      letter-spacing: 0.0em;//日本語は0
      &::before {//コメ印をつける
        content: "\203B";
        margin-left: -1.4rem;//インデント効果２
      }
    }
}
.scroll_table_wrapper{
    -webkit-overflow-scrolling: touch;//スマホで滑らかにスクールする
    overflow-x: auto;//X方向にスクロール、autoにすると縦スクロールが消える
    /*
    @media screen and (max-width: 1020px) {//横幅が広い表組みは、1020以下でスクロールに切り替える
        overflow-x: auto;//X方向にスクロール、autoにすると縦スクロールが消える
    }*/
}
.table-base-design{
    width: 100%;//tableの横幅を固定
    height: auto;
    text-align: center;//横中央揃え
    border-collapse: collapse;//隣接する線を１pxに重ねる
    border-spacing: 0;
    margin: 0 auto;
    border-top: solid 2px $red;
    box-sizing: border-box;
    word-break:normal;
    &.table-base-design_btno{border-top: solid 1px #ccc;}
    thead{
        border-bottom: solid 2px $gray3; 
        th{
            color: $red;
            background-color: $gray1;
            border-left: solid 1px $gray2;
            font-weight: bold;
        }
/*        th:first-child{
            border-left: 0;
        }*/
    }
    tbody{
        tr:hover{
          background-color: $pink;
        }
        th{
          color: $black;
          background-color: $gray1;
        }
        td{
          border-left: solid 1px $gray2;
        }
    }
    th,td{
        font-size: 0.8m;//11pxと同等
        //padding: 5px;
        padding: 8px;
        border-bottom: solid 1px $gray2;
        border-left: solid 1px $gray2;
        border-right: solid 1px $gray2;
        vertical-align: middle;//縦中央揃え
        max-width: 200px;
        min-width: 81px;//iphone8用に調整
        @media screen and (max-width: 375px) {//スマホ用 iPhone8を基準の最大値
            font-size: 0.7m;//10pxと同等
            }
    }
    th:first-child,.scrole_sticky{//横スクロール１列目
        position: sticky;
        width: 250px;
        min-width: 90px;//横幅を固定する
        &::before{//1列めの縦線を表示させる
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 100%;
            height: 100%;
            border-right: solid 1px $gray2;
            box-sizing: border-box;
        }
        @media screen and (max-width: 414px) {//スマホ用 iPhone8を基準の最大値
            width: 30%;
        }
        }
    th:first-child{//1列目を固定にしたい場合
        left: 0px;
    }
    .scrole_sticky{//2列目を固定にしたい場合
        left: 90px;
        border-left: solid 1px $gray2;
    }
    .pc_br{
        @media screen and (min-width: 376px) {
            display: inline; //PCを改行する
        }
        @media screen and (max-width: 890px) {//タブレット以上 iPad768を基準の最大値
            display: none; //PCの改行を隠して
        }
    }
    .sp_br{
        display: none; //SPの改行を隠す
        @media screen and (max-width: 390px) {//スマホ用 iPhone12を基準の最大値
            display: block; //SPを改行する
        }
    }

}

//tableのパターンをクラスで作る
//固定ページのTableのテンプレート（非表示）を参照してください。
.table-typeA{//tbodyのthを左揃え、tdを左揃え、tableのクラス
    border-top: solid 1px $gray2;//table上の赤線をグレーに変更
    tbody{
        tr.border-top{//環境への取り組み　TDFCの表の敷居線の設定
            border-top: solid 2px $gray3;
        }
        tr.border-bottom{//環境への取り組み　TDFCの表の敷居線の設定
            border-bottom: solid 2px $gray3;
        }
        tr:hover{
            background-color: $white-bg;//hoverで背景を白にする＝無効化
        }
        th{
            text-align: left;
            width: 145px !important;//enでは削除
            @media screen and (max-width: 375px) {//スマホ用 iPhone8を基準の最大値
                width: 100px !important;
            }
        }
        td{
            text-align: left;
            //max-width: 0px;
        }
        tr td ul li{
            display: list-item;
            padding-left: 20px;//親要素にoverflow:hiddenが設定されており、飛び出ていたため設定
            text-indent:-1.3em;
            padding-left:1.3em;

        }
    }
}
.corporate{
    th,td{
        padding: 24px 16px;
        line-height: 1.3rem;
    }
}
.table-typeB{//tbodyのthを左揃え、tdを右揃え、tableのクラス
    thead th.black{
        color: #333 !important;
        border-top: solid 2px $black;
    }
    tbody{
        th{
            text-align: left;
            max-width: 90px; //typeBだけに適用する　横幅を揃えた方がきれいなので
        }
        td{
            text-align: right;
        }
    }
}
.table-typeC{//tbodyのthを左揃え、tdを右揃え、tableのクラス
    th:first-child{
        min-width: 450px !important;
        @media screen and (max-width: 820px) {//スマホ用 iPhone8を基準の最大値
            min-width: 333px !important;//改行位置で調整
        }
        @media screen and (max-width: 414px) {//スマホ用 iPhone8を基準の最大値
            min-width: 175px !important;//改行位置で調整
        }
    }
    tbody{
        th{
            text-align: left;
        }
        td{
            text-align: right;
            width: 140px;
            @media screen and (max-width: 768px) {//スマホ用 iPhone8を基準の最大値
                min-width: 104px !important;//改行位置で調整
            }
            @media screen and (max-width: 414px) {//スマホ用 iPhone8を基準の最大値
                min-width: 80px !important;//改行位置で調整
            }
        }
    }
}
//Cパターンあり、CSS設定なし
.table-typeD{//日本語版　取締役及び監査役のスキル・マトリックスに利用
    //font-size: 0.45rem !important;
    //max-width: 735px !important;
    thead th{
        overflow-wrap: break-word;//自動改行
        hyphens: auto;//自動改行のハイフン
    }
    tbody{
        th:first-child{
            text-align: left;
        }
    }
    thead tr th:first-child,tbody tr th:first-child{
        min-width: 120px;
    }
    /*
    thead th:nth-of-type(-n+2),tbody td:nth-of-type(-n+1){//１つ目から最後まで
        min-width: 61px !important;
        max-width: 61px !important;
    }
    */
    thead th:last-child,tbody td:last-child{
        min-width: 170px;
    }
}
//Eパターンあり、CSS設定なし
.table-typeF{
    tbody{
        tr.border-top{//環境への取り組み　表①の敷居線の設定
            border-top: solid 2px $gray3;
        }
        tr.border-bottom{//環境への取り組み　表①の敷居線の設定
            border-bottom: solid 2px $gray3;
        }
        th, td{
            text-align: left;
        }
        td:nth-child(3),td:nth-child(4){
            min-width: 141px;
        }
        td:nth-child(4){
            min-width: 105px;
        }
        td:nth-child(5),td:nth-child(6){
            text-align: center;
        }
    }
    th:first-child,.scrole_sticky{//横スクロール１列目
        width: 90px;
    }
    tbody span.bold {
        font-weight: bold;
    }
    tbody td ul{
        list-style-type:disc;
        padding-left: 15px;
    }
    thead th:nth-child(5),th:nth-child(6),tbody td:nth-child(5),td:nth-child(6){
        min-width: 50px;//サステナ環境への取り組み　表　の微調整
    }

}
.table-typeG{
    tbody{
        th:first-child,th:nth-child(2){
            text-align: left;
        }
        th{
            width: 90px;
        }
    }
}
.none{//１列目が統合されていて、２列目がthでスクロール固定にする場合、1列目を削除せずに非表示にする
    display: none;
}
span.bold{
    //font-size: 0.85rem;//12pxと同等
    font-weight: bold;
}
.text_left{
    text-align: left;
}
.text_center{
    text-align: center;
}
.text_right{
    text-align: right;
}
.text_light{
    text-align: light !important;
}
.margin_b30{
    margin-bottom: 30px;
}
.indent_one{
    padding-left: 1rem !important;
}
.indent_two{
    padding-left: 1.5rem !important;
}

// profile 役員一覧で使用
.profileTable {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
    box-sizing: border-box;
    word-break:normal;
    th.profileTableHead {
        background-color: #999;
        color: #fff ;
        text-align: left;
        font-size: 16px;
    }
    th, td {
        font-size: 14px;
        padding: 10px 8px;
        border: 1px solid #ccc;
        vertical-align: middle;
        text-align: left;
    }
    th{
        background-color: $gray1;
        width: 140px;
    }
}

// session 個人投資家向け説明会で使用
.sessionTable {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
    box-sizing: border-box;
    word-break:normal;
    th, td {
        font-size: 14px;
        padding: 8px;
        border: 1px solid #ccc;
        vertical-align: middle;
        text-align: left;
    }
    th{
        background-color: $gray1;
        width: 120px;
    }
}

// strategy　中期経営計画で使用
#ir {
    // ーー国内Wの再成長 表 追加指定 table-typeBを装飾
    @media screen and (min-width: 819px) {
        .table-typeB-arrange thead th {
            width: 25%;
        }
    }
    .table-typeB-arrange th.minW50 {
        min-width: 30px;
        width: 30px;
        max-width: 30px;
    }
    .table-typeB-arrange th.scrole_sticky {
        left: 30px;
    }
    @media screen and (max-width: 767px) {
        .table-typeB-arrange th.minW50 {
            min-width: 50px;
            width: 50px;
            max-width: 50px;
        }
        .table-typeB-arrange th.scrole_sticky {
            left: 50px;
        }
    }
    // ーー重点戦略 表
    .strategyTable {
        width: 100%;
        height: auto;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 24px auto 0;
        box-sizing: border-box;
        word-break:normal;
        th {
            font-size: 16px;
            padding: 8px;
            border: 1px solid #ccc;
            vertical-align: middle;
            background-color: $gray1;
            width: 140px;
            text-align: center;
            font-weight: bold;
        }
        td {
            font-size: 14px;
            padding: 8px;
            border: 1px solid #ccc;
            vertical-align: middle;
            text-align: left;
        }
    }
}

// sustainability　マテリアリティで使用
.materialityTable {
    @media screen and (min-width: 819px) {
        th:nth-child(1) {
            width: 20%;
        }
        th:nth-child(2) {
            width: 30%;
        }
        th:nth-child(3) {
            width: 50%;
        }
    }
    th:first-child {
        @media screen and (max-width: 820px) {
            max-width: 100px;
        }
    }
    tbody {
        text-align: left;
    }
    .materialityTableIcon {
        @media screen and (max-width: 767px) {
            text-align: center;
        }
        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            margin: 4px 2px;
        }
    }
}