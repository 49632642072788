.sidebar {
    width: 23.5%;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 0;
        float: none;
        ul {
            display: flex;
            flex-wrap: wrap;
        }
    }

    li {
        @media screen and (max-width: 767px) {
            width: 50%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        @media screen and (max-width: 500px) {
            font-size: 14px;
        }
        &:not(.active):not(.activePre) {
            border-bottom: 1px solid #ccc;
        }

/*        &:first-child {
            &:not(.active) {
                border-top: 1px solid #ccc;
            }
        }*/

        &:nth-child(2n + 1) {
            @media screen and (max-width: 767px) {
                border-right: 1px solid #ccc;
                clear: both;
            }
        }

        &:nth-child(2) {
            @media screen and (max-width: 767px) {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
            }
        }
        a {
            &:hover {
                color: #de0716;
            }
        }
    }

    .two01 {
        a {
            @media screen and (max-width: 767px) {
                padding: 5px 0 4px;
            }
        }
    }

    .active {
        background: #de0716;
        border-bottom: 1px solid #de0716;
        transition-duration: 0ms;
        a {
            color: #fff;
            background: url("../../src/ja/images/common/ico_common02.png") no-repeat 15px 50%;
            @media screen and (max-width: 767px) {
                background: url("../../src/ja/images/common/ico_common02.png") no-repeat 95% 50%;
            }
            &:hover {
                color: #fff;
            }
        }
    }

    .activePre {
        @media screen and (max-width: 767px) {
            border-bottom: 1px solid #ccc;
        }
    }

    .disclosureActive {
        border-bottom: 1px solid #fff;
    }
 
    a {
        padding: 12px 0px 12px 30px;
        background: url(../../src/ja/images/common/ico_common03.png) no-repeat 15px 50%;
        display: block;

          @media screen and (max-width: 767px) {
            padding: 15px 20px 15px 15px;
            background: url(../../src/ja/images/common/ico_common03.png) no-repeat 95% 50%;
          }
    }

    a.sidebar_img {
        padding: 0px;
        margin: 24px 0;
        background: none;
    }

    .btnArea01 {
        li {
            margin-top: 10px;
            background: #ebebeb;
            border: 0;
            text-align: left;
            width: 100%;
            display:inline-block;
            height: auto;
            
            
            @media screen and (max-width:767px) {
                margin-top: 0px;
                font-size: 13px;
                width: 50%;
                float: left;
                border-bottom: 1px solid #ccc;
                height: 80px;
            }

            @media screen and (max-width:360px) {
                text-align: center;
            }

            a{
                padding: 15px 35px 15px 58px;
                box-sizing: border-box;

                @media screen and (max-width: 767px) {
                    padding: 18px 20px 18px 38px;
                }
            }

            &:nth-child(1) {//アイコン差し替え
                a {
                    background: url("../../src/ja/images/common/ico_common10.png") no-repeat 15px 50%,
                    url("../../src/ja/images/common/ico_common09.png") no-repeat 95% 50% ;
                    background-size: 20px, 15px;
                    border: 5px solid #DCDCDC;//グレーの枠線
                    padding: 15px 35px 15px 54px;//グレーの枠線を追加したことによるテキスト幅の調整

                    @media screen and (max-width: 767px) {
                        background: url("../../src/ja/images/common/ico_common10.png") no-repeat 10px 50%,
                        url("../../src/ja/images/common/ico_common09.png") no-repeat 95% 50% ;
                        background-size: 15px, 10px;
                        padding: 15px 15px 15px 33px;
                    }
                    @media screen and (max-width: 500px) {//中間エリアのグレー枠線のズレ
                        padding: 16px 15px 17px 33px;
                    }
                    @media screen and (max-width: 428px) {//中間エリアのグレー枠線のズレ
                        padding: 9px 15px 9px 33px;
                    }
                }    
                &:not(.active) {
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                }
            }
            &:nth-child(2) {
                a {
                    background: url("../../src/ja/images/common/ico_common17.png") no-repeat 20px 50%,
                    url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                    background-size: 20px, auto;

                    
                    @media screen and (max-width: 767px){//タブレット
                        background: url("../../src/ja/images/common/ico_common17.png") no-repeat 10px 50%,
                        url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 15px, auto;
                    }

                    
                }
                &:not(.active):not(.activePre) {
                    border-bottom: 1px solid #fff;
                }   
            }
            &:nth-child(3),&:nth-child(4){
                a {
                    background: url("../../src/ja/images/common/ico_common17.png") no-repeat 20px 50%,
                    url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                    background-size: 20px, auto;

                    @media screen and (max-width: 767px) {
                        background: url("../../src/ja/images/common/ico_common17.png") no-repeat 10px 50%,
                        url("../../src/ja/images/common/ico_common03.png") no-repeat 95% 50%;
                        background-size: 15px, auto;
                    }
                }
                &:not(.active) {
                    border-top: 0;
                    border-bottom: 1px solid #fff;
                }
            }

            &:nth-child(2n) {//偶数列
                &:not(.active):not(.activePre) {
                    border-left: 1px solid #fff;
                }   
            }

            &:nth-child(2) {
                &:not(.active):not(.activePre) {
                    border-top: 1px solid #fff;
                }   
            }

            a span {//文字サイズ
                font-size: 12px;
                display: block;
                @media screen and (max-width: 500px) {
                    font-size: 10px;
                }
            }

        }

        
    }

}