#profile {
    @media screen and (max-width: 767px) {
        .mainContent {
            padding: 50px 0 0 !important;
        }
    }
    // index.html
    .title03 {
        padding: 48px 25px 46px;
        background: url("../../src/ja/images/profile/bg_index01.png") no-repeat 100% 0, #f6f6f6;
        background-size: contain;
    }

    .profile01 {
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
        .main01 {
            width: 100%;
            float: none;
            @media screen and (max-width: 767px) {
                width: 96%;
                margin: 0 auto;
            }
        }

        .idea01 {
            margin-top: 50px;
            @media screen and (max-width: 767px) {
                margin-top: 32px;
            }
            li {
                width: 23.5%;
                background: url("../../src/ja/images/profile/bg_index02.png");
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 20px;
                    float: none;
                }
                &:not(:last-child) {
                    margin-right: 2%;
                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                    }
                }
            }

            h4 {
                font-size: 22px;
                color: #de0716;
                background: #fff;
                border: 1px solid #de0716;
                @media screen and (max-width: 800px) {
                    font-size: 19px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .text01 {
                font-size: 46px;
                color: #fff;
                width: 60px;
                background: #de0716;
                line-height: 1.31;
                display: inline-block;
                @media screen and (max-width: 800px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 30px;
                    width: 42px;
                }
            }

            .text02 {
                padding-left: 8px;
                display: inline-block;
                @media screen and (max-width: 767px) {
                    padding-left: 5px;
                }
            }

            .textArea01 {
                padding: 10px 10px 55px 12px;
                line-height: 1.8;
            }

            h6 {
                font-size: 16px;
                margin: 0 0 10px -10px;
                padding-left: 10px;
                text-indent: -10px;
            }

            .text03 {
                font-size: 20px;
                color: #de0716;
            }
        }

        .pageNav01 {
            // margin-top: 30px;
            margin-top: 0;
            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
            @media screen and (max-width: 600px) {
                font-size: 14px;
            }

            @media screen and (max-width: 420px) {
                font-size: 13px;
            }
            @media screen and (max-width: 360px) {
                font-size: 11px;
            }
            li {
                width: 31.2%;
                margin-top: 30px;
                line-height: 0;
                position: relative;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    //
                    margin-top: 10px;
                    float: none;
                }
                @media screen and (max-width: 400px) {
                    // margin-top: 15px;
                    margin-top: 10px;
                }
                &:not(:nth-child(3n)) {
                    @media screen and (min-width: 768px) {
                        margin-right: 3.2%;
                    }
                }
                &:not(:nth-child(2n)) {
                    @media screen and (max-width: 767px) and (min-width: 500px) {
                        margin-right: 3.2%;
                    }
                }

                &:nth-child(2n + 1) {
                    @media screen and (max-width: 767px) {
                        clear: both;
                    }
                }
                &:nth-child(-n + 3) {
                    margin-top: 0;
                    @media screen and (max-width: 767px) {
                        // margin-top: 20px;
                        margin-top: 10px;
                    }
                }
            }

            p {
                border-radius: 0 0 3px 3px;
                @media screen and (max-width: 767px) {
                    border-radius: 0px 3px 3px 0px;
                    float: left;
                }
                &:first-child {
                    @media screen and (max-width: 767px) {
                        width: 30%;
                    }
                }

                &:last-child {
                    width: 100%;
                    padding: 20px 0;
                    background: url("../../src/ja/images/common/ico_common02.png") no-repeat 95% 50%, rgba(51, 51, 51, 0.8);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        width: 70%;
                        // padding: 11.5% 0 11.4% 20px;
                        padding: 8% 0 8.24% 20px;
                        background: url("../../src/ja/images/common/ico_common02.png") no-repeat 95% 50%, #333;
                        text-align: left;
                        position: static;
                    }
                    @media screen and (max-width: 420px) {
                        padding-left: 15px;
                    }
                    @media screen and (max-width: 360px) {
                        padding-left: 10px;
                    }
                }
            }
            a {
                color: #fff;
            }
            img {
                border-radius: 3px;
                @media screen and (max-width: 767px) {
                    border-radius: 3px 0px 0px 3px;
                }
            }
        }
    }

    //board_members.html
    .memberProfileWrap {
        display: flex;
        flex-direction: row;
        margin-bottom: 100px;
        @media screen and (max-width: 1079px) {
        flex-direction: column;
        }
        .memberImg {
            width: 300px;
            height: 300px;
            flex-shrink: 0;
            @media screen and (max-width: 1079px) {
                width: 100%;
                max-width: 300px;
                height: auto;
                flex-shrink: 0;
                margin: 0 auto 10px;
            }
        }
        .memberText {
            width: 100%;
            padding-left: 20px;
            @media screen and (max-width: 1079px) {
                padding-left: 0;
            }
            .panelWrap {
                position: relative;
            }
            .panelBtn {
                color: #000;
                font-size: 16px;
                text-align: center;
                &:hover {
                    color: #ff0000;
                }
            }
            .panel {
                position: relative;
                margin: 0 auto 1em;
                width: 100%;
                max-height: 300px;
                overflow: hidden;
                transition: max-height 0.5s ease;
                .memberName {
                    text-align: left;
                    font-size:34px;
                    margin-bottom:32px;
                    span {
                        font-size:20px;
                        display:block;
                    }
                    @media screen and (max-width: 1079px) {
                        text-align: center;
                        font-size:24px;
                        margin-bottom:20px;
                        span {
                            font-size:18px;
                            display:block;
                        }
                    }
                }
            }
            .fade {
                height: 40px;
                margin-top: -40px;
                position: relative;
                background-color: rgba(255, 255, 255, 0.7);
                box-shadow: 0 -5px 14px #fff;
                position: relative;
            }
            .show,
            .hide {
                position: absolute;
                bottom: 1em;
                left: 50%;
                transform: translateX(-50%);
                z-index: 100;
                text-align: center;
            }
            //
            .hide_1 {
                display: none;
            }
            .show_1:target {
                display: none;
            }
            .show_1:target ~ .hide_1 {
                display: block;
            }
            .show_1:target ~ .panel {
                max-height: fit-content;
            }
            .show_1:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_2 {
                display: none;
            }
            .show_2:target {
                display: none;
            }
            .show_2:target ~ .hide_2 {
                display: block;
            }
            .show_2:target ~ .panel {
                max-height: fit-content;
            }
            .show_2:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_3 {
                display: none;
            }
            .show_3:target {
                display: none;
            }
            .show_3:target ~ .hide_3 {
                display: block;
            }
            .show_3:target ~ .panel {
                max-height: fit-content;
            }
            .show_3:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_4 {
                display: none;
            }
            .show_4:target {
                display: none;
            }
            .show_4:target ~ .hide_4 {
                display: block;
            }
            .show_4:target ~ .panel {
                max-height: fit-content;
            }
            .show_4:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_5 {
                display: none;
            }
            .show_5:target {
                display: none;
            }
            .show_5:target ~ .hide_5 {
                display: block;
            }
            .show_5:target ~ .panel {
                max-height: fit-content;
            }
            .show_5:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_6 {
                display: none;
            }
            .show_6:target {
                display: none;
            }
            .show_6:target ~ .hide_6 {
                display: block;
            }
            .show_6:target ~ .panel {
                max-height: fit-content;
            }
            .show_6:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_7 {
                display: none;
            }
            .show_7:target {
                display: none;
            }
            .show_7:target ~ .hide_7 {
                display: block;
            }
            .show_7:target ~ .panel {
                max-height: fit-content;
            }
            .show_7:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_8 {
                display: none;
            }
            .show_8:target {
                display: none;
            }
            .show_8:target ~ .hide_8 {
                display: block;
            }
            .show_8:target ~ .panel {
                max-height: fit-content;
            }
            .show_8:target ~ .fade {
                margin-top: 0;
            }
            //
            .hide_9 {
                display: none;
            }
            .show_9:target {
                display: none;
            }
            .show_9:target ~ .hide_9 {
                display: block;
            }
            .show_9:target ~ .panel {
                max-height: fit-content;
            }
            .show_9:target ~ .fade {
                margin-top: 0;
            }
        }
    }

    #member03{
        .member03-ProfileWrap{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 20px;
            @media screen and (max-width: 1079px) {
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 0px;
            }

            .memberProfileWrap {
                display: flex;
                flex-direction: column;
                margin-bottom: 0px;
                .memberImg{
                    width: auto;
                    height: auto;
                }
                .memberText {
                    width: 100%;
                    padding: 0px;

                    .panelWrap {
                        position: relative;
                        padding-top: 20px;
                        @media screen and (max-width: 1079px) {
                            padding-top: 0px;
                        }
                    }
                    .memberName{
                        text-align: left;
                        font-size: 26px;
                        margin-bottom: 35px;
                        span {
                            font-size: 14px;
                            display: block;
                        }
                        @media screen and (max-width: 1079px) {
                            text-align: center;
                            font-size: 22px;
                            margin-bottom: 20px;

                            span {
                                font-size: 13px;
                            }
                        }
                    }
                }
            } 
        }
    }

    // group.html
    .group01 {
        // .title04 {
        //   @media screen and (max-width:767px) {
        //     margin-bottom: 0;
        // 	padding-bottom: 10px;
        //   }
        // }
        .title04 {
            @media screen and (max-width: 767px) {
                margin-bottom: 0;
                padding-bottom: 10px;
            }
            a {
                color: #ff0000;
                border: 0;
                font-weight: bold;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .title05 {
            color: #de0716;
            font-weight: bold;
            a {
                color: #de0716;
                border: 0;
                font-weight: bold;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .groupList01 {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px dotted #e1e1e1;
                @media screen and (max-width: 767px) {
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0;
                }

                &:first-child {
                    border-top: 1px dotted #e1e1e1;
                }
            }

            .logo01 {
                width: 32%;
                margin-right: 20px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-top: 20px;
                    margin-right: 0;
                    float: none;
                }
            }

            .text01 {
                width: 66%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-top: 0;
                    float: none;
                }
            }

            .title05 {
                color: #333;
            }

            .link01 {
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 700;
                a {
                    color: #333;
                    padding: 2px 24px 2px 0;
                    background: url("../../src/ja/images/common/ico_common05.png") no-repeat 100% 50%;
                    background-size: 15px auto;
                    &:hover {
                        text-decoration: none;
                        color: #de0716;
                        border-bottom: 1px solid #de0716;
                    }
                }
                @media screen and (max-width: 767px) {
                    text-align: center;
                    margin: 20px auto;
                }
            }
        }
    }

    //company.html
    .company01 {
        .map01 {
            margin-top: 10px;
            width: 100%;
            height: 300px;
        }
        .content {
            .table01 {
                th {
                    text-align: left;
                    @media screen and (min-width: 768px) {
                        width: 200px;
                    }
                    @media screen and (max-width: 767px) {
                        text-align: left;
                        padding-left: 10px;
                    }
                }
            }
        }
        .contentNav03 {
            a {
                &:hover {
                    color: #de0716;
                    // border-bottom: 1px solid #333;
                    text-decoration: underline #de0716;
                }
            }
        }
    }

    //business.html
    .businessContentWrap {
        display:flex;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: inherit;
        }
        .businessContentImg {
            width: 38%;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .businessContentText {
            width: 60%;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            .businessContentLink {
                width: 100%;
                background-color: #f5f5f5;
                padding: 20px;
                margin-top: 20px;
                ul.businessLink li a {
                    display: inline-block;
                    position: relative;
                    font-size: 14px;
                    color: #333;
                    padding:5px 25px 5px 15px;
                    text-decoration: underline;
                    &:hover {
                        color: #de0716;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 10px;
                        height: 10px;
                        background-image: url(../../src/ja/images/common/ico_common03.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 15px;
                        background-image: url(../../src/ja/images/common/ico_common09.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
                ul.businessLink li a.NoAfter {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    .business01 {
        .leftContent01 {
            width: 60%;
            @media screen and (max-width: 640px) {
                width: 100%;
                float: none;
            }
        }
        .rightContent01 {
            width: 38%;
            @media screen and (max-width: 640px) {
                width: 100%;
                margin-top: 15px;
                float: none;
            }
        }
        .detail01 {
            @media screen and (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
        .blankLink {
            a {
                // padding-right: 18px;
                // background: url("../../src/ja/images/common/ico_common09.png") no-repeat 100% 4px;
                // background-size: 15px;
                // border-bottom: 1px solid #333;
                border-bottom: none;
            }
        }
        .contentNav {
            display: flex;
            flex-wrap: wrap;
            li {
                margin-right: 30px;
                @media screen and (max-width: 767px) {
                    margin-right: 0;
                }
                a {
                    padding-left: 16px;
                    background-position: 1% 50%;
                    display: block;
                    @media screen and (max-width: 767px) {
                        margin: 0 !important;
                        padding: 12px 16px !important;
                    }
                }
                /*&:first-child {
                    width: 28%;
                    @media screen and (max-width: 767px) {
                        width: 50%;
                    }
                }
                &:nth-child(2) {
                    width: 33%;
                    @media screen and (max-width: 767px) {
                        width: 50%;
                    }
                }*/
            }
        }
        .leftContent01 {
            a {
                &:hover {
                    color: #de0716;
                    border-bottom: 1px solid #de0716;
                    transition-duration: 0ms;
                }
            }
        }
        .attention01 {
            li {
                a {
                    &:hover {
                        color: #de0716;
                        border-bottom: 1px solid #de0716;
                        transition-duration: 0ms;
                    }
                }
            }
        }
    }

    //message.html
    .message01 {
        .textArea01 {
            p {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
        .messageTitle {
            color: #de0716;
            font-weight: 700;
            font-size: 20px;
        }

        .title02 {
            @media screen and (max-width: 767px) {
                width: 452px;
                padding-top: 5%;
                float: left;
            }
            @media screen and (max-width: 700px) {
                font-size: 18px;
                width: 406px;
                padding-top: 5%;
                float: left;
            }
            @media screen and (max-width: 640px) {
                width: 68%;
                padding-top: 3%;
            }
            @media screen and (max-width: 420px) {
                font-size: 16px;
                padding-top: 1%;
            }
            @media screen and (max-width: 360px) {
                font-size: 14px;
                padding-top: 1%;
            }

            br {
                @media screen and (max-width: 640px) {
                    display: none;
                }
            }
        }

        .leftContent01 {
            width: 68%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        .rightContent01 {
            width: 28%;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        .presidentName01 {
            margin-top: 30px;
            text-align: right;
            @media screen and (max-width: 767px) {
                margin-bottom: 50px;
            }
            @media screen and (max-width: 350px) {
                font-size: 12px;
            }
            span {
                font-size: 20px;
                @media screen and (max-width: 1000px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 350px) {
                    font-size: 16px;
                }
            }
        }
    }

    //policy.html
    .policy01 {
        .textArea01 {
            p {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }

        .idea01 {
            li {
                width: 100%;
                background: none;
                border: 1px solid #ccc;
                border-top: 0;
                @media screen and (max-width: 500px) {
                    border: 0;
                }
                &:not(:last-child) {
                    margin: 0 0 20px;
                    @media screen and (max-width: 500px) {
                        margin-bottom: 35px;
                    }
                }
            }

            h5 {
                margin-bottom: 0;
            }

            .textArea01 {
                padding: 10px 10px 5px 12px;
            }

            h6 {
                margin-left: -12px;
                padding-left: 12px;
                text-indent: -12px;
            }
        }

        .leftContent01 {
            width: 40%;
            background: url("../../src/ja/images/profile/bg_index02.png");
            @media screen and (max-width: 500px) {
                width: 100%;
                float: none;
            }
        }

        .rightContent01 {
            width: 60%;
            padding: 40px 15px 40px 60px;
            border-top: 1px solid #ccc;
            @media screen and (max-width: 960px) {
                padding: 40px 15px 40px 30px;
            }
            @media screen and (max-width: 500px) {
                width: 100%;
                padding: 10px 0 0;
                border: 0;
                float: none;
            }
        }

        //  li {
        //    &:last-child {
        //      .rightContent01 {
        //        padding: 30px 15px;
        //        @media screen and (max-width:500px) {
        //          padding: 10px 0 0;
        //        }
        //      }
        //    }
        //  }
        .contentNav02 {
            li {
                &:nth-child(2n + 1) {
                    @media screen and (max-width: 767px) {
                        clear: none;
                    }
                }
            }
        }
    }

    //strength.html
    .strength01 {
        .image01 {
            margin-top: 30px;
        }
    }

    .indexStrength {
        .questionList {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            margin-top: 30px;
            margin-bottom: 13px;
            padding-bottom: 0;
            border-bottom: 0;
            @media screen and (max-width: 767px) {
                grid-template-columns: 1fr;
            }
            @media screen and(max-width: 370px) {
                grid-template-columns: 1fr;
            }

            &.modi-questionList {
                margin-top: 0;
            }

            .title10 {
                @media screen and (max-width: 1100px) {
                    font-size: 12px;
                    letter-spacing: -0.2em;
                }
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    margin-top: 20px;
                }
            }
            ul {
                margin-bottom: 0;
            }
            li {
                margin-bottom: 0;
                // width: 32.666%;
                // margin: 0 0 0 1%;
                //background: none;
                background: #333;
                border-radius: 5px;
                display: flex;
                align-items: center;
                @media screen and (max-width: 767px) {
                    //margin-bottom: 20px;
                    width: 100%;
                    margin-left: 0;
                }
                .questionContainer {
                    //background: #333;
                    //border-radius: 5px;
                    &:hover {
                        opacity: 0.8;
                    }
                    .text {
                        color: #fff;
                        font-size: 11px;
                        margin-left: 0;
                        //          @media screen and (max-width: 1200px) and  (min-width:768px) {
                        //            font-size: 10px;
                        //            line-height: 1.2;
                        //
                        //          }
                        @media screen and (max-width: 767px) {
                            font-size: 12px;
                        }
                    }
                    a {
                        padding: 10px 2px 10px 20px;
                        display: block;
                        background: url("../../src/ja/images/common/ico_common06.png") no-repeat 10px 50%;
                        @media screen and (max-width: 768px) {
                            padding: 10px 2px 10px 25px;
                            background-position: 5px center;
                        }
                        @media screen and (max-width: 767px) {
                            padding: 15px 2px 15px 25px;
                            background-position: 10px center;
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                    .linkIcon {
                        width: 10px;
                        margin-left: 5px;
                        margin-bottom: 5px;
                    }
                }
                .profileOnly {
                    display: none;
                    @media screen and (min-width: 768px) and(max-width:900px) {
                        display: block;
                    }
                }
                /*&:nth-child(6) {
                    a {
                        @media screen and (min-width: 768px) and (max-width: 900px) {
                            padding: 0px 0px 0 25px;
                        }
                        span {
                            @media screen and (max-width: 900px) {
                                padding: 6px 0 5px;
                                line-height: 1.2;
                                display: inline-block;
                            }
                            @media screen and (min-width: 768px) and (max-width: 900px) {
                                padding: 6px 0 5px;
                                line-height: 1.2;
                                display: inline-block;
                            }
                        }
                    }
                }*/
            }
            /*
            &:last-child {
                border-bottom: 0;
                ul {
                    // margin-top: 20px;
                }
            }*/
        }
    }
    .rightContent01 {
        font-size: 14px;
        @media screen and (max-width: 900px) {
            font-size: 14px;
        }
        .profilePresidentName {
            font-size: 14px;
            @media screen and (max-width: 900px) {
                font-size: 14px;
            }
        }
    }
    .policyTitle01 {
        @media screen and (max-width: 374px) {
            font-size: 20px;
        }
    }
    .message01TextWrap {
        text-align: right;
        display: block;
    }
    //history.html
    .history01 {
        .table03 {
            margin-top: 45px;
            &:first-child {
                margin-top: 0;
            }
            tr {
                @media screen and (max-width: 767px) {
                    border-top: 1px solid #ccc;
                }
                &:last-child {
                    @media screen and (max-width: 767px) {
                        border-bottom: 1px solid #ccc;
                    }
                }
            }
            th,
            td {
                @media screen and (max-width: 767px) {
                    display: table-cell;
                }
            }
            th {
                @media screen and (max-width: 767px) {
                    width: 20%;
                    border: 0;
                }
            }
            td {
                @media screen and (max-width: 767px) {
                    width: 80%;
                    border: 0;
                }
            }
            h3 {
                font-size: 16px;
                padding: 5px 0px;
                // @media screen and (max-width: 767px) {
                //   padding-top: 30px;
                // }
            }
        }
        .blankLink {
            a {
                border-bottom: 0;
                &:hover {
                    color: #de0716;
                    border-bottom: 1px solid #de0716;
                    transition-duration: 0ms;
                }
            }
        }
    }
    .boderBottom01 {
        @media screen and (max-width: 767px) {
            border-bottom: 1px solid #ccc;
        }
    }
    .indexIr {
        .questionListContent01 {
            margin-top: 5px;
            @media screen and (width: 767px) {
                margin-top: 5px;
            }
            h4 {
                padding-top: 12px;
                border-top: 5px solid #333;
            }
            ul {
                margin: 0 auto 0;
                &:last-child {
                    margin-top: 0;
                }
                li {
                    @media screen and (min-width: 767px) {
                        margin-bottom: 1%;
                    }
                    &:nth-child(6) {
                        a {
                            padding: 10px 0 10px 20px;
                            @media screen and (max-width: 767px) {
                                padding: 15px 0 15px 25px;
                            }
                            @media screen and (min-width: 768px) and (max-width: 900px) {
                                // padding: 10px 0 10px 20px;
                                padding: 6px 0 6px 20px;
                            }
                        }
                        span {
                            @media screen and (min-width: 768px) and (max-width: 900px) {
                                // padding: 6px 0 8px;
                                padding: 0;
                                line-height: 1.2;
                                display: inline-block;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            padding: 10px 0 10px 20px;
                            @media screen and (min-width: 768px) and (max-width: 900px) {
                                padding: 0 0 0 25px;
                            }
                            span {
                                @media screen and (max-width: 900px) {
                                    padding: 6px 0 5px;
                                    line-height: 1.2;
                                    display: inline-block;
                                }
                                @media screen and (min-width: 768px) and (max-width: 900px) {
                                    padding: 6px 0 8px;
                                    line-height: 1.2;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .indexIr {
        .questionList {
            li {
                .text {
                    float: none;
                }
                /*
                &:nth-child(6) {
                    .questionContainer {
                        .text {
                            // padding: 9px 0 8px;
                        }
                    }
                }*/
            }
        }
    }
    .questionList3Btn {
        a {
            padding-left: 15px;
            @media screen and (max-width: 767px) {
                padding-left: 25px;
            }
        }
        span {
            font-size: 11px !important;
            @media screen and (max-width: 767px) {
                font-size: 12px !important;
            }
        }
    }
    #first {
        padding-top: 83px;
        margin-top: -82.38px;
    }
    #second {
        padding-top: 127.38px;
        margin-top: -82.38px;
    }
    #third {
        padding-top: 127.38px;
        margin-top: -82.38px;
    }
    //business
    .businesscontentNav02 {
        li {
            font-size: 12px;
            width: 32%;
            margin-bottom: 0px;
            @media screen and (max-width: 767px) {
                // width: 100%;
                // float: none;
                text-align: left;
                width: 50%;
                text-align: center;
            }
            &:nth-child(4) {
                clear: both;
                @media screen and (max-width: 767px) {
                    clear: none;
                }
            }
            &:not(:nth-child(3n)) {
                @media screen and (max-width: 767px) {
                    margin-right: 0;
                }
            }
            &:nth-last-child(-n + 3) {
                a {
                    @media screen and (max-width: 767px) {
                        padding: 22px 0px 22px 8px;
                    }
                }
            }
            &:nth-child(-n + 3) {
                a {
                    @media screen and (max-width: 767px) {
                        padding: 12px 0px 12px 8px;
                    }
                }
            }
        }
    }
    
    #about {
   
        .table01 {
            th {
                @media screen and (max-width: 767px) {
                    width: auto;
                    white-space: nowrap;
                    display: table-cell;
                }
            }
            td {
                @media screen and (max-width: 767px) {
                    display: table-cell;
                }
            }
        }
    }
    #officer {
        .table01 {
            th {
                @media screen and (max-width: 767px) {
                    width: auto;
                    white-space: nowrap;
                    display: block;
                }
            }
            td {
                @media screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
    }

    #map_canvas01 {
        width: 100%;
        height: 300px;
        border: 1px solid #ccc;
        margin: 10px 0px;
    }
    .contentNav03 {
        a {
            font-size: 12px;
        }
    }
    .hoverBorder01 {
        text-decoration: underline;
        &:hover {
            color: #de0716;
            // border-bottom: 1px solid #333;
            text-decoration: underline #de0716;
            transition-duration: 0ms;
        }
    }

    .table_willheart {
        width: 100%;
        margin: 30px 0 0;

        th {
            width: 20%;
            background: #efefef;
            padding: 10px;
            border: 1px solid #ccc;
            text-align: left;
        }

        td {
            padding: 10px;
            border: 1px solid #ccc;
        }
    }
    //index cardlink
    .index_cardlink_wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        @media screen and (max-width: 767px){
            display: block;
        }
        .index_cardlink_list{
            width: calc(100% / 2 - 10px);
            margin-bottom: 20px;
            background-color: #fafafa;
            border: 1px solid #ccc;
            @media screen and (max-width: 767px){
                width: 100%;
            }
            a{
                display: block;
                padding: 20px;
                .index_cardlink_ttl{
                    margin-bottom: 10px;
                    font-size: 18px;
                }
                .index_cardlink_item{
                    display: flex;
                    justify-content: space-between;
                    .index_cardlink_img{
                        flex-shrink: 0;
                        width: 120px;
                    }
                    .index_cardlink_text{
                        width: 100%;
                        margin-left: 10px;
                    }
                }
            }
        }
    } /*/index cardlink end */
}

.officer-wrapper {
    .officer-list {
        border-top: 1px solid #ccc;
        padding: 3px 0 10px;
        display: flex;
        align-items: flex-start;
        gap: 15px;
        @media screen and (max-width: 768px) {
            display: block;
        }

        .officer-img {
            padding-top: 5px;
            width: 200px;
        }

        .officer-contents {
            .title04 {
                font-size: 22px;
                padding-top: 8px;
                background: url("../../src/ja/images/top/bg_common01.png") repeat-x 0 0;
                width: fit-content;
            }

            .name-main {
                width: fit-content;

                .sub-title {
                    font-size: 0.87rem;
                }
            }
            .sub-text {
                display: flex;
                flex-wrap: wrap;

                dt {
                    width: 18%;
                    @media screen and (max-width: 768px) {
                        width: 25%;
                    }
                }

                dd {
                    width: 82%;
                    @media screen and (max-width: 768px) {
                        width: 75%;
                    }
                }
            }
        }
    }
}
