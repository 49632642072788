#privacy {
    .privacy01 {
        @media screen and (max-width: 767px) {
            width: 96%;
            margin: 0 auto;
            padding: 0 10px;
        }

        cccc {
            margin-bottom: 30px;
        }

        .detail01 p {
            margin-top: 8px;
        }

        .date01 {
            margin-bottom: 0;
            text-align: right;
        }
    }

    @media screen and (max-width: 350px) {
        .privacy01 .date01 {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent .container {
            width: 96%;
        }
    }

    .detail01.lastDetail01 {
        margin-top: 30px;
    }

    h4 a {
        color: #de0716;

        &:hover {
            border-bottom: 1px solid #de0716;
            -webkit-transition-duration: 0ms;
            -o-transition-duration: 0ms;
            transition-duration: 0ms;
        }
    }
}
