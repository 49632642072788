@charset "UTF-8";
/*///////////////////////////////////////////////////////////
mixin
///////////////////////////////////////////////////////////*/
/*///////////////////////////////////////////////////////////
全体
///////////////////////////////////////////////////////////*/
#sustainability {
    .mainContent{
        @media screen and (max-width: 767px) {
            padding-bottom: 0;
        }
    }
    img {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    @media screen and (max-width: 767px) {
        main {
            overflow-x: hidden;
        }
    }

    .main a {
        text-decoration: underline;
    }

    .main a:hover {
        color: #de0716;
        -webkit-transition-duration: 0ms;
        transition-duration: 0ms;
    }

    .sidebar a {
        text-decoration: none;
    }
    .sidebar {
        .title01 {
            margin-bottom: 0 !important;
        }
    }

    /*///////////////////////////////////////////////////////////
見出し
///////////////////////////////////////////////////////////*/
    /*-- 見出し追加モジュール 見出し03 --*/
    .title03 {
        padding: 48px 25px 46px;
    }

    @media screen and (max-width: 767px) {
        .title03 {
            padding-top: 50px;
        }
    }

    @media screen and (max-width: 500px) {
        .title03 {
            padding: 50px 25px 42px;
            background-size: cover;
        }
    }

    @media screen and (max-width: 400px) {
        .title03 {
            background-size: 85% 100%;
        }
    }

    @media screen and (max-width: 350px) {
        .title03 {
            background-size: 270px 100%;
        }
    }

    .-sustainability .title03 {
        background: url("../../src/ja/images/sustainability/bg_index01.png") no-repeat 100% 0, #f6f6f6;
    }

    /*///////////////////////////////////////////////////////////
レイアウト
///////////////////////////////////////////////////////////*/
    @media screen and (max-width: 767px) {
        .-sustainability {
            //margin-bottom: 40px;
        }
    }

    .-sustainability .title01 + .l-section {
        padding-top: 0 !important;
    }

    .-sustainability .l-section:not(:last-of-type) {
    }

    @media screen and (max-width: 767px) {
        .-sustainability .l-section:not(:last-of-type) {
            padding-top: 0px;
            margin-bottom: 30px;
        }
    }

    .contentNav a {
        background-position: 5px 50%;
    }

    @media screen and (max-width: 767px) {
        .contentNav a {
            background-position: 0px 20px;
        }
    }

    .link_txt {
        padding: 0 0 0 1em;
        background: url("../../src/ja/images/common/ico_common03.png") no-repeat 0 50%;
        text-decoration: underline;
    }

    /*///////////////////////////////////////////////////////////
インデックススタイル
///////////////////////////////////////////////////////////*/
    @media screen and (max-width: 767px) {
        .c-index_grid01 {
            margin-top: 30px;
        }
    }

    .main01.-index_layout {
        width: 100%;
        float: none;
    }

    @media screen and (max-width: 767px) {
        .main01.-index_layout {
            width: 96%;
            margin: 0 auto;
        }
    }

    .c-index_grid01 .pageNav01 {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }

    .c-index_grid01 .pageNav01.row2 {
        margin-top: 30px;
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01.row2.last {
            margin-top: 10px;
        }
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 {
            font-size: 20px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
        }
    }

    @media screen and (max-width: 600px) {
        .c-index_grid01 .pageNav01 {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 420px) {
        .c-index_grid01 .pageNav01 {
            font-size: 13px;
        }
    }

    @media screen and (max-width: 360px) {
        .c-index_grid01 .pageNav01 {
            font-size: 11px;
        }
    }

    .c-index_grid01 .pageNav01 .pageNav01_item {
        width: 100%;
        line-height: 0;
        position: relative;
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 .pageNav01_item {
            background: #333;
            float: none;
            //border-radius: 3px;
            overflow: hidden;
        }
    }

    .c-index_grid01 .pageNav01 .pageNav01_item.only {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        .c-index_grid01 .pageNav01 .pageNav01_item:not(:nth-child(3n)) {
            //margin-right: 3.2%;
        }
    }

    @media screen and (max-width: 767px) and (min-width: 500px) {
        .c-index_grid01 .pageNav01 .pageNav01_item:not(:nth-child(2n)) {
            //margin-right: 3.2%;
        }
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 .pageNav01_item:nth-child(2n + 1) {
            clear: both;
        }
    }

    .c-index_grid01 .pageNav01 .pageNav01_item:nth-child(-n + 3) {
        //margin-top: 0;
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 .pageNav01_item:nth-child(-n + 3) {
            //margin-top: 10px;
        }
    }

    .c-index_grid01 .pageNav01 p {
        //border-radius: 0 0 3px 3px;
    }

    .c-index_grid01 .pageNav01 p.hoverImgHolder {
        position: relative;
        //border-radius: 3px;
        overflow: hidden;
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 p {
            border-radius: 0;
            float: left;
        }
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 p:first-child {
            background: #ffffff;
            width: 30%;
        }
    }

    .c-index_grid01 .pageNav01 p:last-child,
    .c-index_grid01 .pageNav01 span.label {
        width: 100%;
        padding: 20px 0;
        background: url("../../src/ja/images/common/ico_common02.png") no-repeat 95% 50%, rgba(51, 51, 51, 0.8);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 p:last-child {
            font-size: 14px;
            width: 70%;
            padding: 8% 0 8.24% 20px;
            background: url("../../src/ja/images/common/ico_common02.png") no-repeat 95% 50%, #333;
            text-align: left;
            position: static;
        }
    }

    @media screen and (max-width: 420px) {
        .c-index_grid01 .pageNav01 p:last-child {
            padding-left: 15px;
        }
    }

    @media screen and (max-width: 360px) {
        .c-index_grid01 .pageNav01 p:last-child {
            padding-left: 10px;
        }
    }

    .c-index_grid01 .pageNav01 p a {
        color: #fff;
    }

    .c-index_grid01 .pageNav01 img {
        //border-radius: 3px;
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        .c-index_grid01 .pageNav01 img {
            border-radius: 0;
        }
        .c-index_grid01 .pageNav01 img.spOnly {
            display: block;
        }
    }

    .pageNav02_item {
        width: 31.2%;
        margin-right: 3.2%;
    }

    .pageNav02_item:last-of-type {
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        .pageNav02_item {
            float: none;
            width: 100%;
        }
    }

    /* ---------- c-grid（ページの2カラムレイアウトで使用） ---------- */
    .c-grid .textArea01 p {
        margin-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
        .c-grid .title02 {
            width: 452px;
            padding-top: 5%;
            float: left;
        }
    }

    @media screen and (max-width: 700px) {
        .c-grid .title02 {
            font-size: 18px;
            width: 406px;
            padding-top: 5%;
            float: left;
        }
    }

    @media screen and (max-width: 640px) {
        .c-grid .title02 {
            width: 68%;
            padding-top: 3%;
        }
    }

    @media screen and (max-width: 420px) {
        .c-grid .title02 {
            font-size: 16px;
            padding-top: 1%;
        }
    }

    @media screen and (max-width: 360px) {
        .c-grid .title02 {
            font-size: 14px;
            padding-top: 1%;
        }
    }

    @media screen and (max-width: 640px) {
        .c-grid .title02 br {
            display: none;
        }
    }

    .c-grid .leftContent01 {
        width: 49%;
    }

    @media screen and (max-width: 767px) {
        .c-grid .leftContent01 {
            width: 100%;
        }
    }

    .c-grid .rightContent01 {
        width: 47%;
    }

    @media screen and (max-width: 767px) {
        .c-grid .rightContent01 {
            float: none;
            width: 80%;
            margin: 0 auto 30px;
        }
    }

    .c-grid .presidentName01 {
        margin-top: 30px;
        text-align: right;
    }

    @media screen and (max-width: 767px) {
        .c-grid .presidentName01 {
            margin-bottom: 50px;
        }
    }

    @media screen and (max-width: 350px) {
        .c-grid .presidentName01 {
            font-size: 12px;
        }
    }

    .c-grid .presidentName01 span {
        font-size: 20px;
    }

    @media screen and (max-width: 1000px) {
        .c-grid .presidentName01 span {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 350px) {
        .c-grid .presidentName01 span {
            font-size: 16px;
        }
    }

    /*-- .c-pageNav_list（サステナビリティTOPとsocialのページナビで使用） --*/
    .c-pageNav_list {
        margin-top: 10px;
        margin-left: 4px;
    }

    .c-pageNav_list li {
        text-align: left;
        line-height: 1.3;
        margin-bottom: 9px;
    }

    .c-pageNav_list li a {
        display: inline-block;
        background: url("../../src/ja/images/common/ico_common03.png") no-repeat 7px 9px;
        padding: 4px 24px 4px 24px;
        font-size: 14px;
    }

    .c-pageNav_list li:last-child {
        margin-bottom: 0;
    }

    .c-pageNav_list .Link {//20220818_奥田追加　社会への取り組みページの外部リンクアイコン
        background: url(../../src/ja/images/common/ico_common09.png) no-repeat 100% 4px;
        background-size: 15px;
        background-position: 14.5rem;
        border-bottom: none;
    }
    

    .c-pageNav_list_wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .c-pageNav_list_wrap.-social {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 767px) {
        .c-pageNav_list_wrap {
            display: block;
        }
    }

    .c-pageNav_list.-top_index {
        width: 31.2%;
        margin-left: 0;
    }

    @media screen and (max-width: 767px) {
        .c-pageNav_list.-top_index {
            width: auto;
        }
    }

    .c-pageNav_list.-top_index li {
        margin-bottom: 7px;
    }

    .c-pageNav_list.-top_index li a {
        background: url("../../src/ja/images/common/ico_common15.png") no-repeat 7px 11px;
        text-decoration: underline;
    }

    .c-pageNav_list.-top_index li a:hover {
        color: #de0716;
        -webkit-transition-duration: 0ms;
        transition-duration: 0ms;
    }

    .c-pageNav_list.-top_index.-governance {
        width: auto;
    }

    /*-- .c-dotlist01(人材・環境で使用) --*/
    .c-dotlist01 li {
        margin-bottom: 5px;
    }

    .c-dotlist01 li:before {
        content: "・";
    }

    /*-- .c-dotlist01(環境で使用) --*/
    .c-txtboldlist01 li {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    /*-- .c-dotlist01(人権で使用) --*/
    .c-linebox {
        padding: 12px;
        border: 1px solid #999999;
    }

    /* ---------- message ---------- */
    .main {
        margin-bottom: 50px;
    }

    .message01 .title01 {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
        .message01 .title01 {
            margin-bottom: 22px;
        }
    }

    .message01 .title02 {
        margin-bottom: 0px;
    }

    @media screen and (max-width: 767px) {
        .message01 .title02 {
            font-size: 18px;
        }
    }

    .message01 .title03 {
        margin: 60px 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
    }

    @media screen and (max-width: 767px) {
        .message01 .title03 {
            margin: 22px 0 30px;
            font-size: 14px;
        }
    }

    .message01 .title_mid {
        margin: 40px 0;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
    }

    @media screen and (max-width: 767px) {
        .message01 .title_mid {
            margin: 20px 0;
            font-size: 16px;
        }
    }

    .message01 .textArea01 p:not(:last-child) {
        margin-bottom: 20px;
    }

    .message01 .presidentName01 {
        margin-top: 5px;
        font-size: 18px;
    }

    @media screen and (max-width: 767px) {
        .message01 .presidentName01 {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 360px) {
        .message01 .title02 {
            font-size: 14px;
            padding-top: 1%;
        }
    }

    @media screen and (max-width: 640px) {
        .message01 .title02 br {
            display: none;
        }
    }

    .message01 .leftContent01 {
        width: 60%;
    }

    @media screen and (max-width: 767px) {
        .message01 .leftContent01 {
            width: 100%;
        }
    }

    .message01 .rightContent01 {
        width: 32%;
    }

    @media screen and (max-width: 767px) {
        .message01 .rightContent01 {
            float: none;
            margin: 0 auto;
        }
    }

    .message01TextWrap.pcOnly {
        display: block;
        text-align: right;
    }

    @media screen and (max-width: 767px) {
        .message01TextWrap {
            margin: 5px 0 20px;
            text-align: center;
        }
    }
    .message01 .topMessageTitle {
        font-size: 20px !important;
        font-weight: 700;
    }

    /* ---------- supplierpolicy ---------- */
    .supplierpolicy .main {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .supplierpolicy .main {
            margin-bottom: 50px;
        }
    }

    .supplierpolicy .c-linebox {
        margin: 100px 0;
        padding: 25px 20px;
    }

    @media screen and (max-width: 767px) {
        .supplierpolicy .c-linebox {
            margin: 50px 0;
        }
    }

    .supplierpolicy .c-linebox .c-basic-orderlist li:not(:first-child) {
        margin-top: 30px;
    }

    .supplierpolicy .c-linebox .c-basic-orderlist li:not(:first-child) .c-descriptionlist dt {
        margin-bottom: 5px;
    }

    .supplierpolicy .policylist .indent_wrap {
        padding-left: 0px; //元40px　奥田修正//
    }

    @media screen and (max-width: 767px) {
        .supplierpolicy .policylist .indent_wrap {
            padding-left: 0px;//元20px　奥田修正//
        }
    }

    .supplierpolicy .policylist .announcement_list {
        margin-top: 3px;
    }

    .supplierpolicy .policylist .announcement_list li {
        line-height: 1.8;
        padding-left: 1em;
        text-indent: -1em;
    }

    .supplierpolicy .policylist .announcement_list li::before {
        content: "・";
    }

    .supplierpolicy .supplier_title01 {
        margin: 70px 0 20px;
        padding: 2px 0 2px 5px;
        background: #f6f6f6;
        font-size: 22px;
    }

    @media screen and (max-width: 767px) {
        .supplierpolicy .supplier_title01 {
            margin: 50px 0 20px;
            font-size: 18px;
        }
    }

    .supplierpolicy .supplier_title02 {
        margin: 50px 0 20px;
        font-size: 18px;
        font-weight: bold;
    }

    @media screen and (max-width: 767px) {
        .supplierpolicy .supplier_title02 {
            font-size: 17px;
            font-feature-settings: "palt";
        }
    }

    .supplierpolicy .supplier_title02.first {
        margin-top: 0;
    }

    .supplierpolicy .notes {
        margin-top: 30px;
    }

    .supplierpolicy .mention {
        margin-top: 30px;
        padding-left: 1.3em;
        text-indent: -1.3em;
    }

    .supplierpolicy .mention_list {
        margin-top: 5px;
    }

    .supplierpolicy .mention_list li {
        margin-bottom: 3px;
        padding-left: 1em;
        text-indent: -1em;
    }

    .supplierpolicy .mention_list li::before {
        content: "・";
    }

    .supplierpolicy .mention_list.none li {
        margin-bottom: 3px;
        padding-left: 0em;
        text-indent: 0em;
    }

    .supplierpolicy .mention_list.none li::before {
        content: none;
    }

    .supplierpolicy .emphasis {
        font-weight: bold;
    }

    .supplierpolicy sup {
        vertical-align: top;
        font-size: 8px;
    }

    /* ---------- environment ---------- */
    .environment .main {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .environment .main {
            margin-bottom: 50px;
        }
    }

    .environment .mv {
        margin-bottom: 10px;
    }

    .environment .pageNav {
        margin-bottom: 30px;
    }

    .environment .contentNav {
        margin: 0;
    }

    .environment .l-section {
        margin-bottom: 70px;
    }

    .environment .l-section:last-of-type {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .environment .l-section {
            margin-bottom: 40px;
        }
    }

    .environment .title07 {
        margin-bottom: 30px;
    }

    .environment .title07.above_image {
        margin-top: 65px;
    }

    @media screen and (max-width: 767px) {
        .environment .title07.above_image {
            margin-top: 40px;
        }
    }

    .environment .txt_wrap {
        padding: 0 0 0 12px;
    }

    .environment p {
        margin-bottom: 24px;
    }

    .environment .img {
        margin: 20px 0 65px;
        padding: 0 0 0 12px;
    }

    .environment .img img {
        width: 100%;
        height: auto;
    }

    .environment sub {
        font-size: 10px;
    }

    /* ---------- social ---------- */
    /*-- mv(各ページのメイン画像に使用) --*/
    .mv {
        margin-bottom: 40px;
        img {
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        .mv {
            margin-bottom: 20px;
        }
    }

    /*-- linkArea01（social,governanceに使用している2カラムレイアウト。IRのTOPが元） --*/
    .linkArea01 {
        width: 48%;
    }

    @media screen and (min-width: 768px), print {
        .linkArea01:not(:nth-child(2n)) {
            margin-right: 4%;
        }
    }

    .linkArea01_wrapper {
        margin-bottom: 100px;
    }

    @media screen and (max-width: 767px) {
        .linkArea01_wrapper {
            margin-bottom: 0;
        }
    }

    @media print {
        .linkArea01 {
            width: 42%;
        }
    }

    @media screen and (max-width: 767px) {
        .linkArea01 {
            width: 100%;
            margin-bottom: 20px;
            float: none;
        }
        .linkArea01:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .linkArea01 .title02 {
        position: relative;
        margin-bottom: 20px;
    }

    .linkArea01 .title02 a {
        display: block;
        // padding-left: 30px;
    }

    // .linkArea01 .title02 a:before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 21px;
    //   left: 9px;
    //   width: 8px;
    //   height: 16px;
    //   background: url("/common/images/ico_common12.png") no-repeat;
    //   background-position: center bottom;
    //   background-size: cover;
    // }

    .linkArea01 .linkArea01_leadtxt {
        margin-bottom: 24px;
    }

    @media print {
        .governance .linkArea01_wrapper {
            margin-bottom: 0px;
        }
    }

    /* ---------- humanresources ---------- */
    .humanresources .main {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .humanresources .main {
            margin-bottom: 50px;
        }
    }

    .humanresources .mv {
        margin-bottom: 65px;
    }

    @media screen and (max-width: 767px) {
        .humanresources .mv {
            margin-bottom: 30px;
        }
    }

    .humanresources .l-section {
        margin-bottom: 70px;
    }

    @media screen and (max-width: 767px) {
        .humanresources .l-section {
            margin-bottom: 40px;
        }
    }

    .humanresources .l-section:last-of-type {
        margin-bottom: 0;
    }

    .humanresources .l-section .title07 {
        margin-top: 30px;
    }

    .humanresources .l-section .title07.above_image {
        margin-top: 65px;
    }

    @media screen and (max-width: 767px) {
        .humanresources .l-section .title07.above_image {
            margin-top: 40px;
        }
    }

    .humanresources .l-section .title_hr {
        margin: 25px 0 5px;
        font-size: 15px;
        font-weight: bold;
    }

    .humanresources p {
        margin-bottom: 24px;
    }

    .humanresources .img {
        margin-bottom: 65px;
    }

    .humanresources .part_traning {
        margin-bottom: 30px;
    }

    .humanresources .position_list {
        margin-top: -15px;
    }

    .humanresources .position_list dt:before {
        content: "・";
    }

    .humanresources .position_list dt:after {
        content: ":";
        padding-left: 0.5em;
    }

    /* ---------- humanrights ---------- */
    .humanrights .l-section {
        margin-bottom: 70px;
    }

    @media screen and (max-width: 767px) {
        .humanrights .l-section {
            margin-bottom: 40px;
        }
    }

    .humanrights .l-section:last-of-type {
        margin-bottom: 0;
    }

    .humanrights .main {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .humanrights .main {
            margin-bottom: 50px;
        }
    }

    .humanrights .mv {
        margin-bottom: 65px;
    }

    @media screen and (max-width: 767px) {
        .humanrights .mv {
            margin-bottom: 30px;
        }
    }

    .humanrights .title_humanrights {
        margin: 50px 0 10px;
        font-size: 18px;
        font-weight: bold;
    }

    .humanrights p {
        margin-bottom: 20px;
    }

    .humanrights .humanrights_notes {
        margin-top: 30px;
    }

    .humanrights sup {
        vertical-align: top;
        font-size: 8px;
    }

    .humanrights .c-linebox {
        max-width: 350px;
        margin: 30px 0 40px;
    }

    .humanrights .c-linebox_title {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
    }

    /* ---------- community ---------- */
    .community .main {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        .community .main {
            margin-bottom: 50px;
        }
    }

    .community .detail_section {
        margin: 100px 0;
    }

    @media screen and (max-width: 767px) {
        .community .detail_section {
            margin: 50px 0;
        }
    }

    .community .leftContent01 {
        width: 61%;
    }

    .community .leftContent01 .community_title {
        margin: 0 0 5px;
        font-size: 16px;
        font-weight: bold;
    }

    @media screen and (max-width: 767px) {
        .community .leftContent01 {
            width: 100%;
            float: none;
        }
    }

    .community .rightContent01 {
        width: 35%;
        padding-top: 5px;
    }

    .community .rightContent01 img {
        border: 1px solid #999999;
    }

    .community .rightContent01 figcaption {
        margin-top: 3px;
        font-size: 12px;
    }

    @media screen and (max-width: 767px) {
        .community .rightContent01 {
            display: table;
            width: auto;
            margin: 15px auto 0;
            float: none;
            text-align: center;
        }
        .community .rightContent01 figcaption {
            margin: 3px auto 0;
            text-align: left;
        }
    }
    .community  p.mgt30 {
        margin-top: 30px;
    }

    .community .Link {//20220818_奥田追加　社会への取り組みページの外部リンクアイコン
        background: url(../../src/ja/images/common/ico_common09.png) no-repeat 100% 4px;
        background-size: 15px;
        background-position: 20.5rem;
        border-bottom: none;
    }

    /* ---------- data ---------- */
    .data .title02 {
        margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
        .data .title02 {
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 767px) {
        .data .title07 {
            font-size: 16px;
        }
    }

    .data .img:not(:last-of-type) {
        margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
        .data .img:not(:last-of-type) {
            margin-bottom: 30px;
        }
    }

    .data .mv {
        margin-bottom: 100px;
    }

    @media screen and (max-width: 767px) {
        .data .mv {
            margin-bottom: 30px;
        }
    }

    sup {
        vertical-align: top;
        font-size: 8px;
    }

    sub {
        vertical-align: bottom;
        font-size: 8px;
    }

    .c-note {
        font-size: 10px;
    }

    .c-note_mark {
        margin-right: 0.5em;
    }

    /* ---------- willheart ---------- */
    .ttl_willheart01 {
        margin: 30px 0 10px;
        color: #de0716;
        font-size: 18px;
        font-weight: bold;
    }

    .ttl_willheart02 {
        position: relative;
        margin: 20px 0 10px;
        padding: 0 0 0 10px;
        font-size: 16px;
        font-weight: bold;
    }

    .ttl_willheart02::before {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 2px solid #de0716;
        content: "";
    }

    .dl_wrap01 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 30px;
        background: #fdf4f5;
        border: 1px solid #de0716;
    }

    .dl_block01 {
        width: 70%;
    }

    .dl_block01 dt {
        margin: 20px 0 10px;
        color: #de0716;
        font-size: 20px;
        font-weight: bold;
    }

    .dl_block01 dt:first-child {
        margin: 0 0 5px;
    }

    .dl_block01 dd {
        font-size: 16px;
        line-height: 1.8;
    }

    .dl_block01 + figure {
        width: 30%;
    }

    .dl_block02 {
        margin: 20px 0;
        border-bottom: 1px dotted #cccccc;
    }

    .dl_block02 dt {
        padding: 10px 10px 8px;
        border-top: 1px dotted #cccccc;
        font-weight: bold;
    }

    .dl_block02 dd {
        padding: 0 10px 10px;
    }

    .table_willheart {
        width: 100%;
        margin: 30px 0 0;
    }

    .table_willheart th {
        width: 20%;
        background: #efefef;
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
    }

    .table_willheart td {
        padding: 10px;
        border: 1px solid #ccc;
    }

    .willheart .fig_box {
        margin: 20px 0 0 0;
    }

    .willheart .fig_box figcaption {
        margin: 0 0 5px;
        font-size: 12px;
    }

    .willheart .detail_inner01 {
        margin: 30px 0 0;
        padding: 20px;
        background: #f6f6f6;
    }

    .willheart .detail_inner01 .ttl_willheart02 {
        margin: 0 0 10px;
    }

    .willheart .inner_box {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .willheart .inner_box .inner_box_article {
        width: 60%;
    }

    .willheart .article_txt {
        margin: 0 0 10px;
    }

    .willheart .article_ttl {
        margin: 10px 0 8px;
        font-weight: bold;
    }

    .willheart .graph_ttl {
        margin: 20px 0 -10px;
    }

    .willheart .graph_date {
        text-align: right;
    }

    .willheart .article_note {
        margin: 10px 0 0;
        font-size: 12px;
    }

    .willheart .article_list {
        margin: 8px 0;
    }

    .willheart .article_list_item {
        position: relative;
        margin: 5px 0;
        padding: 0 0 0 1em;
    }

    .willheart .article_list_item::before {
        position: absolute;
        left: 0;
        content: "・";
    }

    .willheart .inner_box .inner_box_img {
        width: 38%;
        margin: 0 0 0 auto;
    }

    .willheart .link_txt {
        padding: 0 0 0 1em;
        background: url(../../src/ja/images/common/ico_common03.png) no-repeat 0 50%;
        text-decoration: underline;
    }

    .logo_katariba {
        width: 40%;
    }

    @media screen and (max-width: 767px) {
        .dl_wrap01 {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }
        .dl_block01 {
            width: 100%;
        }
        .dl_block01 + figure {
            width: 100%;
        }
        .willheart .inner_box {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }
        .willheart .inner_box .inner_box_article {
            width: 100%;
        }
        .willheart .inner_box .inner_box_img {
            width: 100%;
            margin: 10px 0 0 0;
            text-align: center;
        }
    }

    /* ---------- compliancepolicy ---------- */
    .compliancepolicy .compliance_title {
        margin: 30px 0 10px;
        font-size: 16px;
        font-weight: bold;
    }

    /* ---------- health ---------- */
    .health h4 {
        color: #de0716;
        font-size: 16px;
    }

    .health .fig_box {
        margin: 20px 0 0 0;
    }

    .health .detail_inner01 {
        margin: 10px 0 20px;
    }

    .ttl_health01 {
        position: relative;
        padding: 0 0 0 10px;
        margin: 8px 0;
        font-weight: bold;
    }

    .ttl_health01::before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 0.5em;
        height: 2px;
        margin: -1px 0 0;
        background: #999;
        content: "";
    }

    .table_health_wrap {
        overflow: auto;
    }

    .table_health {
        width: 100%;
        margin: 8px 0;
    }

    .tbl_column_hd {
        background: #efefef;
        font-weight: bold;
    }

    .tbl_row_hd {
        font-weight: bold;
    }

    .table_health td {
        font-size: 11px;
        width: 20%;
        min-width: 100px;
        padding: 10px;
        border: 1px solid #ccc;
    }

    .tbl_annotation_txt {
        margin: 5px 0 0;
        font-size: 12px;
    }

    .tbl_hd_txt_small {
        display: block;
        font-size: 12px;
    }

    .list_number {
        counter-reset: number;
    }

    .list_number li {
        position: relative;
        padding: 0 0 0 18px;
    }

    .list_number li:not(:last-child) {
        margin: 0 0 10px;
    }

    .list_number li::before {
        position: absolute;
        left: 0;
        top: 4px;
        width: 12px;
        height: 12px;
        border: 1px solid #333;
        border-radius: 50%;
        content: "";
    }

    .list_number li::after {
        position: absolute;
        left: 3.5px;
        top: 6px;
        color: #333;
        font-size: 10px;
        line-height: 1;
        content: counter(number);
        counter-increment: number;
    }

    .list_nomal {
        margin: 10px 0;
    }

    .list_nomal li {
        position: relative;
        padding: 0 0 0 1em;
    }

    .list_nomal li:not(:last-child) {
        margin: 0 0 5px;
    }

    .list_nomal li::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "・";
    }

    @media screen and (max-width: 767px) {
        .table_health td {
            min-width: 20%;
            font-size: 11px;
        }
        .tbl_annotation_txt {
            font-size: 10px;
        }
        .list_number li:not(:last-child) {
            margin: 0 0 5px;
        }
        .tbl_hd_txt_small {
            font-size: 10px;
        }
    }

    /* ---------- governance01 ---------- */
    .governance01 .linktxt01 {
        margin-top: 20px;
        padding-bottom: 10px;
    }

    .governance01 .linktxt01 + .linktxt01 {
        margin-top: 0;
    }

    .governance01 .contentNav {
        margin-bottom: 0;
    }

    .governance01 .blankLink01 {
        margin-bottom: 40px;
        text-align: right;
    }

    .governance01 .blankLink01 a {
        padding-right: 18px;
        background: url("../../src/ja/images/common/ico_common07.png") no-repeat 100% 4px;
        background-size: 15px;
    }

    .governance01 .blankLink01 a:hover {
        color: #de0716;
        border-bottom: 1px solid #de0716;
        -webkit-transition-duration: 0ms;
        transition-duration: 0ms;
    }

    .governance01 .content02 li:not(:last-child) {
        margin-bottom: 25px;
    }

    .governance01 .content02 h4 {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .governance01 .image01 {
        margin-top: 30px;
    }

    //　追加パーツ
    .u-rsp-mt_10 {
        margin-top: 10px;
    }
    .u-rsp-mt_20 {
        margin-top: 20px;
    }
    .u-rsp-mt_30 {
        margin-top: 30px;
    }
    .u-rsp-mt_40 {
        margin-top: 40px;
    }
    .u-rsp-mt_50 {
        margin-top: 50px;
    }
    .u-rsp-mt_60 {
        margin-top: 60px;
    }
    .u-rsp-mt_65 {
        margin-top: 65px;
    }

    a {
        &[href$=".pdf"] {
            position: relative;
            display: inline !important;
            padding-right: 22px;
            text-decoration: underline;
            &:before {
                content: "";
                position: absolute;
                bottom: 2px;
                right: 0;
                width: 15px;
                height: 18px;
                background-image: url(../../src/ja/images/common/pdf.png);
                background-position: center 2px;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }
        }
    }

    h3.title02 {
        & + p,
        & + div {
            margin-top: 30px;
        }
    }

    .c-text_alignR {
        text-align: right;
    }
    .c-text_sign {
        font-size: 21px;
        & > span {
            font-size: 14px;
        }
    }
    .text-link_pdf {
        & + .text-link_pdf {
            margin-top: 15px;
        }
        a {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .btn01 {
        a {
            color: #fff;
            background: url(../../src/ja/images/common/ico_common06.png) no-repeat;
            background-position: calc(100% - 10px) 50%;
            display: block;
            padding: 15px 0 14px;
            border: 1px solid #646464;
            border-radius: 5px;
            text-decoration: none;
        }
        &:hover {
            opacity: 0.8;
            a {
                color: #fff !important;
            }
        }
    }
    .area-btn {
        .btn01 {
            max-width: 352px;
            margin-right: auto;
            margin-left: auto;
        }
        &.align_L {
            .btn01 {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .anchor_p {
        position: relative;
        @media screen and (max-width: 767px) {
            & > span {
                position: absolute;
                top: -90px;
            }
        }
    }

    .c-list {
        & > li {
            position: relative;
            padding-left: 1em;
            & + li {
                margin-top: 10px;
            }
            &:before {
                content: "・";
                position: absolute;
                top: 3px;
                left: -3px;
                display: block;
                width: 1em;
                height: 1em;
                line-height: 1em;
            }
        }
    }
    .c-image {
        img {
            width: 100%;
            vertical-align: bottom;
        }
    }

    .c-box {
        &__bg {
            background-color: #f6f6f6;
        }
        &.w-img_s {
            .c-box__inner {
                &:nth-child(odd) {
                    width: 45.8%;
                }
                &:nth-child(even) {
                    width: 54.2%;
                }
            }
        }

        display: table;
        width: 100%;
        .c-box__inner {
            box-sizing: border-box;
            display: table-cell;
            width: 50%;
            &:nth-child(odd) {
                padding-right: 20px;
                vertical-align: top;
            }
            &:nth-child(even) {
                padding: 20px 40px 20px 20px;
                vertical-align: middle;
            }
            img {
                width: 100%;
                vertical-align: bottom;
            }
            .c-text_sttl {
                font-size: 18px;
                font-weight: bold;
                & + p,
                & + div {
                    margin-top: 10px;
                }
            }
            a.c-text_sttl {
                position: relative;
                display: block;
                padding-left: 15px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 0;
                    display: block;
                    width: 7px;
                    height: 11px;
                    background-image: url(../../src/ja/images/sustainability/icon-arrow_r.png);
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-size: 100% auto;
                }
                &[target=_blank] p:after {
                    content: "";
                    margin-left: 8px;
                    vertical-align: middle;
                    display: inline-block;
                    background: url(../../src/ja/images/common/ico_common09.png) no-repeat;
                    background-size: contain;
                    width: 15px;
                    height: 15px;
                }
            }

            p {
                & + div {
                    margin-top: 30px;
                }
            }
            .area-btn {
                .btn01 {
                    max-width: 240px;
                }
            }
        }

        @media screen and (max-width: 767px) {
            display: block;
            .c-box__inner {
                display: block;
                width: 100%;
                &:nth-child(odd) {
                    padding-right: 0;
                }
                &:nth-child(even) {
                    padding: 20px 10px 30px;
                }
                .area-btn {
                    .btn01 {
                        max-width: 240px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .c-grid {
        // display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 14px;

        &.test-display {
            display: flex;
        }

        .c-grid__inner {
            width: 50%;
            &:nth-child(n + 3) {
                margin-top: 30px;
            }
            & > a {
                display: block;
                .c-text_sttl {
                    padding-left: 14px;
                    background-image: url(../../src/ja/images/common/ico_common04.png);
                    background-repeat: no-repeat;
                    background-position: 0 11px;
                    background-size: 6px auto;
                    font-size: 18px;
                    font-weight: bold;
                    & + p,
                    & + div {
                        margin-top: 10px;
                    }
                }
                & + p,
                & + div {
                    margin-top: 10px;
                }
            }
            p {
                a {
                    text-decoration: underline;
                    &:hover {
                        color: #de0716;
                        transition-duration: 0ms;
                    }
                }
                & + p {
                    margin-top: 10px;
                }
            }
        }

        .c-box .c-box__inner {
            &:nth-child(odd) {
                padding-right: 10px;
                vertical-align: top;
            }
            &:nth-child(even) {
                padding: 0px 20px;
                vertical-align: top;
            }
        }

        @media screen and (max-width: 767px) {
            display: block;
            .c-grid__inner {
                width: 100%;
                & + .c-grid__inner,
                &:nth-child(n + 3) {
                    margin-top: 36px;
                }
            }
            .c-box {
                display: block;
                .c-box__inner {
                    display: block;
                    width: 100%;
                    &:nth-child(odd) {
                        padding-right: 0;
                    }
                    &:nth-child(even) {
                        margin-top: 15px;
                        padding: 0 2px;
                    }
                }
                &.w-img_s {
                    .c-box__inner {
                        &:nth-child(odd),
                        &:nth-child(even) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .c-step {
        li {
            position: relative;
            display: table;
            width: 100%;
            background-color: #f6f6f6;
            & + li {
                margin-top: 35px;
                &:before {
                    content: "";
                    position: absolute;
                    top: -35px;
                    left: 20px;
                    display: block;
                    width: 0;
                    height: 0;
                    border-top: 15px solid #de0716;
                    border-right: 15px solid transparent;
                    border-bottom: 0;
                    border-left: 15px solid transparent;
                }
            }
            .c-step_no {
                display: table-cell;
                background-color: #de0716;
                vertical-align: middle;
                & > span {
                    display: block;
                    width: 70px;
                    color: #fff;
                    font-size: 18px;
                    line-height: 1.2;
                    text-align: center;
                    & > b {
                        display: block;
                        font-size: 28px;
                        text-align: center;
                    }
                }
            }
            .c-step_text {
                display: table-cell;
                width: 100%;
                padding: 30px 30px;
                vertical-align: middle;
                .c-step_text_ttl {
                    font-size: 16px;
                    font-weight: bold;
                    & + p {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .pagelink {
        .list-link {
            margin-bottom: 20px;
            &:after {
                content: "";
                display: block;
                clear: both;
            }
            & > li {
                float: left;
                width: auto;
                margin-right: 40px;
                font-size: 12px;
                a {
                    position: relative;
                    display: block;
                    padding-left: 20px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 7px;
                        left: 0;
                        display: block;
                        width: 10px;
                        height: 6px;
                        background-image: url(../../src/ja/images/common/ico_common15.png);
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        background-position: 0 0;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .list-link {
                padding-top: 10px;
                margin-bottom: 35px;
                & > li {
                    margin-right: 20px;
                    padding: 9px 0;
                }
            }
        }
    }
    .pagelink_sp {
        ul {
            & > li {
                font-size: 12px;
                & + li {
                    margin-top: 20px;
                }
                a {
                    position: relative;
                    display: block;
                    padding-left: 20px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 0;
                        display: block;
                        width: 10px;
                        height: 6px;
                        background-image: url(../../src/ja/images/common/ico_common15.png);
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        background-position: 0 0;
                    }
                }
            }
        }
    }
    .itemlink {
        .list-link {
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            border: 1px solid #e9e9e9;
            & > li {
                position: relative;
                width: 20%;
                & + li {
                    border-left: 1px solid #e9e9e9;
                }
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 8%;
                    left: 50%;
                    display: block;
                    width: 10px;
                    height: 6px;
                    background-image: url(../../src/ja/images/common/ico_common15.png);
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    background-position: 0 0;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    transform: translateX(-50%);
                }
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 13% 13% 30%;
                    font-size: 12px;
                    font-weight: bold;
                    text-decoration: none;
                }
                .solution_img {
                    img {
                        width: 100%;
                    }
                }
                .solution_txt {
                    margin-top: 6px;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .list-link {
                & > li {
                    position: relative;
                    width: 33.33%;
                    border-bottom: 1px solid #e9e9e9;
                    &:nth-child(3n + 1) {
                        width: 33.34%;
                        border-left: 0;
                    }
                    & + li {
                        border-left: 1px solid #e9e9e9;
                    }
                    &:nth-child(3n + 1):last-child,
                    &:nth-child(3n + 2):last-child {
                        border-right: 1px solid #e9e9e9;
                    }
                    &:nth-child(3n + 1):nth-last-child(-n + 3),
                    &:nth-child(3n + 2):nth-last-child(-n + 2),
                    &:nth-child(3n):nth-last-child(-n + 1):nth-child(n + 4) {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .c-policy_message {
        position: relative;
        padding: 40px 40px;
        z-index: 2;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background-color: #ef9081;
            z-index: -1;
            opacity: 0.1;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            width: 294px;
            height: 165px;
            background-image: url(../../src/ja/images/sustainability/bg_corner.png);
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100% auto;
            z-index: -1;
        }
        .text_sttl {
            margin-bottom: 30px;
            .text_sttl_sub {
                display: block;
                width: 100%;
                padding-bottom: 10px;
                border-bottom: 1px solid #de0717;
                color: #de0717;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                & + span {
                    margin-top: 10px;
                }
            }
            .text_sttl_main {
                display: block;
                width: 100%;
                font-size: 22px;
                font-weight: bold;
                text-align: center;
            }
        }
        .c-policy_text {
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        @media screen and (min-width: 768px) {
            .c-policy_text {
                .fl_r {
                    float: right;
                    width: 200px;
                    margin-left: 30px;
                    img {
                        width: 100%;
                    }
                }
                .fl_l {
                    padding-right: 230px;
                }
            }
        }
        @media screen and (max-width: 767px) {
            padding: 30px 0;
            &:before {
                left: 50%;
                width: 100vw;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            &:after {
                right: -15px;
                display: block;
                width: 222px;
                height: 124px;
            }
            .c-policy_text {
                .fl_r {
                    float: none;
                    width: 100%;
                    margin-top: 20px;
                    margin-left: 0;
                    text-align: center;
                    img {
                        width: 100%;
                        max-width: 200px;
                    }
                }
                .fl_l {
                    padding-right: 0;
                }
            }
            .c-policy_message_img {
                margin-top: 20px;
                text-align: center;
                img {
                    width: 100%;
                    max-width: 200px;
                }
                .c-text_sign {
                    width: 100%;
                    max-width: 200px;
                    margin-right: auto;
                    margin-left: auto;
                    text-align: left;
                }
            }
        }
    }

    .list-policy {
        & > li {
            padding: 30px 0;
            & + li {
                margin-top: 10px;
                border-top: 1px solid #d8d8d8;
            }
            .text-sttl {
                position: relative;
                display: inline-block;
                margin-bottom: 22px;
                padding-left: 11px;
                font-size: 16px;
                font-weight: bold;
                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    display: block;
                    width: 4px;
                    height: 17px;
                    background-color: #000;
                }
            }
            .area-btn {
                margin-top: 30px;
                .btn01 {
                    width: 100%;
                    max-width: 352px;
                    margin-right: auto;
                    margin-left: auto;
                    a {
                        text-decoration: none;
                    }
                }
                &.align_L {
                    .btn01 {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            & > li {
                .text-sttl:before {
                    top: 4px;
                }
                .area-btn {
                    .btn01 {
                        width: 100%;
                        max-width: none;
                    }
                }
            }
        }
    }

    .value_box {
        padding: 35px 20px;
        border: 1px solid #efefef;
        background-color: #fff;
        .ttl_value {
            box-sizing: border-box;
            display: table;
            width: 100%;
            height: 38px;
            margin-bottom: 36px;
            padding: 5px 0;
            border-radius: 38px;
            background-color: #485158;
            & > span {
                display: table-cell;
                color: #fff;
                font-size: 18px;
                text-align: center;
                line-height: 1.3;
                vertical-align: middle;
            }
        }
        .sttl_value {
            margin-bottom: 24px;
            font-size: 22px;
            font-weight: bold;
        }
        .sttl_value_2 {
            position: relative;
            margin-bottom: 10px;
            z-index: 2;
            &:before {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                width: 13px;
                height: 13px;
                background-color: #de0717;
            }
            &:after {
                content: "";
                position: absolute;
                top: 17px;
                left: 0;
                display: block;
                width: 100%;
                height: 1px;
                background-color: #e9e9e9;
                z-index: -1;
            }
            & > span {
                display: inline-block;
                padding-right: 15px;
                padding-left: 24px;
                background-color: #fff;
                font-size: 22px;
                font-weight: bold;
            }
        }
        .ssttl_value {
            margin-bottom: 6px;
            text-align: center;
            & > span {
                display: inline-block;
                padding-bottom: 2px;
                border-bottom: 1px solid;
                color: #de0717;
                font-size: 15px;
                font-weight: bold;
            }
        }
        .text_value_pr {
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            & > span {
                display: block;
                font-size: 15px;
            }
        }
        .text_value_img {
            position: relative;
            display: table;
            width: 100%;
            max-width: 242px;
            margin-right: auto;
            margin-left: auto;
            .text_value_img_inner {
                box-sizing: border-box;
                display: table;
                width: 100%;
                height: 62px;
                padding: 2px 2px;
                border-radius: 62px;
                border: 1px solid #e9e9e9;
                & + .text_value_img_inner {
                    position: relative;
                    margin-top: 45px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: -30px;
                        left: 50%;
                        display: block;
                        width: 15px;
                        height: 16px;
                        background-image: url(../../src/ja/images/sustainability/icon-multiply.png);
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: 100% auto;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                }
                & > span {
                    display: table-cell;
                    text-align: center;
                    vertical-align: middle;
                }
            }
        }
        dl {
            & + dl {
                margin-top: 25px;
            }
            dt {
                color: #de0717;
                border-bottom: 1px solid;
                & > span {
                    display: inline-block;
                    padding-bottom: 10px;
                    font-size: 18px;
                }
            }
            dd {
                margin-top: 10px;
                font-size: 16px;
            }
        }
        .list-target {
            dl {
                position: relative;
                display: table;
                width: 100%;
                max-width: 242px;
                margin-right: auto;
                margin-left: auto;
                border-bottom: 1px solid #efefef;
                font-size: 15px;
                & + dl {
                    margin-top: 10px;
                }
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 28px;
                    left: 0;
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: #000;
                }
                dt {
                    display: table-cell;
                    width: 40%;
                    margin: 0;
                    padding-left: 10px;
                    border-bottom: 0;
                    color: #000;
                    white-space: nowrap;
                    vertical-align: bottom;
                }
                dd {
                    display: table-cell;
                    width: 60%;
                    margin: 0;
                    text-align: right;
                    vertical-align: bottom;
                    b {
                        padding: 0 5px;
                        font-size: 28px;
                        font-weight: bold;
                    }
                }
            }
        }
        .list-value {
            & > li {
                position: relative;
                padding-left: 20px;
                font-size: 16px;
                font-weight: bold;
                & + li {
                    margin-top: 7px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 0;
                    display: block;
                    width: 11px;
                    height: 11px;
                    border-radius: 11px;
                    background-color: #de0717;
                }
            }
        }
        .list-solution {
            & > li {
                display: table;
                width: 100%;
                & + li {
                    margin-top: 20px;
                }
                .solution_img {
                    display: table-cell;
                    vertical-align: top;
                    & > span {
                        display: block;
                        width: 94px;
                    }
                    img {
                        width: 100%;
                        vertical-align: bottom;
                    }
                }
                .solution_txt {
                    display: table-cell;
                    width: 100%;
                    padding-left: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    vertical-align: middle;
                }
            }
        }
    }
    .arrow_btm {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            display: block;
            width: 0;
            height: 0;
            border-bottom: 0;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        &.arrow_btm_s {
            margin-bottom: 70px;
            &:before {
                bottom: -41px;
                border-top: 40px solid #de0717;
                border-right: 50px solid transparent;
                border-left: 50px solid transparent;
            }
        }
        &.arrow_btm_m {
            margin-bottom: 88px;
            &:before {
                bottom: -59px;
                border-top: 58px solid #de0717;
                border-right: 73px solid transparent;
                border-left: 73px solid transparent;
            }
        }
        &.arrow_btm_l {
            margin-bottom: 100px;
            &:before {
                bottom: -71px;
                border-top: 70px solid #de0717;
                border-right: 83px solid transparent;
                border-left: 83px solid transparent;
            }
        }
    }

    .value_future {
        position: relative;
        padding-top: 54.64%;
        background-image: url(../../src/ja/images/sustainability/bg_valuecreation01.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
        .value_future_inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 9% 20px 0;
            .ttl_future {
                display: table;
                width: 100%;
                height: 37px;
                padding: 3px 10px;
                border-radius: 37px;
                background-color: #fff;
                & > span {
                    display: table-cell;
                    color: #de0717;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    vertical-align: middle;
                }
            }
            .text_future {
                width: 100%;
                max-width: 360px;
                margin-right: auto;
                margin-left: auto;
                color: #fff;
                font-size: 23px;
                font-weight: bold;
            }
        }
    }

    .c-materiality_box {
        .c-materiality_ttlarea {
            display: table;
            width: 100%;
            margin-bottom: 30px;
            background-color: #f6f6f6;
            .ttlarea_img {
                display: table-cell;
                vertical-align: top;
                & > span {
                    display: block;
                    width: 80px;
                }
                img {
                    vertical-align: bottom;
                }
            }
            .ttlarea_txt {
                display: table-cell;
                width: 100%;
                padding-left: 20px;
                font-size: 18px;
                font-weight: bold;
                vertical-align: middle;
            }
        }
        .sttl_materiality {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
        }
        
        .ex_box_wrap {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 767px) {
                display: block;
                justify-content: inherit;
            }
            .ex_icon_img {
                flex-shrink: 0;
                width: 120px;
                margin-right: 20px;
                @media screen and (max-width: 767px) {
                    margin: 0 auto 20px;
                }
            }
        }
        .ex_box {
            box-sizing: border-box;
            //padding: 20px 20px;
            padding: 16px;
            border: 1px solid #e9e9e9;
            width: 100%;
            .title05 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .ttl_ex_box {
                position: relative;
                margin-bottom: 15px;
                padding-left: 22px;
                font-size: 16px;
                font-weight: bold;
                &:before {
                    content: "";
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 13px;
                    height: 13px;
                    background-color: #de0717;
                }
            }
            .ex_box_inner {
                .ex_box_img {
                    img {
                        width: 100%;
                        & + p {
                            margin-top: 8px;
                        }
                    }
                    .text-caption {
                        font-size: 12px;
                    }
                }
                .ex_box_text {
                }
            }
            @media screen and (min-width: 768px) {
                .ex_box_inner {
                    &:after {
                        content: "";
                        display: block;
                        clear: both;
                    }
                    .ex_box_img {
                        float: right;
                        width: 40%;
                    }
                    .ex_box_text {
                        float: left;
                        width: 55.55%;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .ex_box_inner {
                    .ex_box_img {
                    }
                    .ex_box_text {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    /* ---------- override ---------- */
    /*.sidebar {
        a {
            background: url(../../src/ja/images/common/ico_common03.png) no-repeat 15px 50%;
        }
        @media screen and (max-width: 767px) {
            a {
                background: url(../../src/ja/images/common/ico_common03.png) no-repeat 95% 50%;
            }
        }
    }*/

    .pageNav01 {
        .pageNav01_item {
            background-color: transparent !important;
            .pageNav01_item__inner {
                position: relative;
                background-color: #333;
                & + .c-pageNav_list {
                    clear: both;
                    width: 100%;
                    a {
                        display: block;
                    }
                }
                a {
                    display: block;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .pageNav01_item {
                .pageNav01_item__inner {
                    & + .c-pageNav_list {
                        padding-top: 10px;
                        padding-bottom: 15px;
                    }
                }
            }
            &.row2 {
                margin-top: 0 !important;
            }
        }
    }

    /* 代表メッセージ */
    @media screen and (min-width: 768px) {
        .message01 .leftContent01 {
            width: 55%;
        }
        .message01 .rightContent01 {
            width: 41.5%;
        }
    }
    .message01 .rightContent01.spOnly {
        width: 100%;
    }
}
