@charset "UTF-8";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
#news {
    // 【ここから】jquery.dataTablesのレイアウト調整
    table.dataTable thead th,
    table.dataTable thead td,
    table.dataTable.no-footer {
        border-bottom: none;
    }

    // 【ここまで】

    .newsArea01 .newsDetail01 {
        padding: 0;
    }

    .newsSelectArea {
        .selectArea01 {
            margin-left: 15px;
            @media screen and (max-width: 400px) {
                width: 100%;
                margin: 15px auto;
            }
        }

        .selectCompany {
            width: 300px;
            @media screen and (max-width: 400px) {
                width: 100%;
                margin: auto;
            }
        }
    }
    .news01 {
        .wysiwyg-wrapper {
            h1 {
                padding: 2px 5px;
                border-left: 4px solid #de0716;
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 30px;
            }

            h2 {
                padding: 2px 5px;
                border-left: 4px solid #de0716;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 20px;
                line-height: 1;
            }

            h3 {
                padding: 2px 5px;
                border-left: 4px solid #de0716;
                font-size: 14px;
                margin-bottom: 20px;
                line-height: 1;
            }

            ul {
                list-style: square;
                padding-left: 5%;
            }

            ol {
                list-style: decimal;
                padding-left: 5%;
            }

            strong {
                font-weight: bold;
            }

            em {
                font-style: italic;
            }

            img {
                height: auto;
            }

            p {
                margin-top: 1em;
                margin-bottom: 1em;
            }

            blockquote {
                border-left: 3px solid #b9b5b5;
                padding: 0em 1em 0em 1em;
                margin: 1em 3%;
            }

            a {
                padding-right: 10px;
                background-size: 15px;
                border-bottom: 1px solid #333;
                -webkit-transition-duration: 0ms;
                -o-transition-duration: 0ms;
                transition-duration: 0ms;
            }

            .webpage {
                padding-right: 18px;
                background: url(../../src/ja/images/common/ico_common09.png) no-repeat 100% 4px;
                background-size: 15px;
                border-bottom: 1px solid #333;
            }

            .pdfclass {
                padding-right: 10px;
                background: url(../../src/ja/images/common/ico_common07.png) no-repeat 100% 4px;
                background-size: 15px;
                border-bottom: 1px solid #333;
            }

            a:hover {
                color: #de0716;
                border-bottom: 1px solid #de0716;
                -webkit-transition-duration: 0ms;
                -o-transition-duration: 0ms;
                transition-duration: 0ms;
            }

            &.news-detail {
                line-height: 1.7rem;

                a[href^="https://"]
                {
                    background: url(../../src/ja/images/common/ico_common09.png) no-repeat 100% 4px;
                    background-size: 15px;
                    border-bottom: 1px solid #333;
                    padding: 4px 18px 0 0;
                }

                a[href$=".pdf"] {
                    background: url(../../src/ja/images/common/ico_common07.png) no-repeat 100% 4px;
                    padding: 4px 10px 4px 0;
                }
            }
        }
    }

    .newsdetailArea01 {
        .category01 {
            color: #fff;
            background: #de0716;
            padding: 5px 10px;
            text-align: left;
            font-size: 12px;
            margin-left: 10px;
        }

        .detail-nav {
            display: flex;
            gap: 15px;
            text-align: center;
        }
    }

    .news01 .extitleH4 {
        font-size: 18px;
        font-weight: bold;
        color: #333;
    }

    .newsArea01 {
        border: none;

        .newsDetail01 .companyName01 {
            font-size: 11px;
            font-weight: bold;
        }

        .text01 {
            width: 84%;
        }

        .cPname01 {
            color: #de0716;
            font-size: 10px;

            span {
                line-height: 1.2;
                display: block;
            }
        }
    }
    .newsTable.dataTable.no-footer {
        border: 1px solid #e1e1e1;
    }

    .extitleH4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 18px;
        font-weight: 400;
        color: #333;
        background: #fff;
        line-height: 1.5;
    }

    .newsdetailtittle01 {
        padding: 0 15px 15px 15px;
        border-bottom: 1px dotted #999;

        .news-details {
            display: flex;
            gap: 10px;
            @media screen and (max-width: 400px) {
                display: block;
            }

            .news-item {
                .category {
                    color: #fff;
                    text-align: center;
                    font-size: 10px;
                    width: 96px;
                    margin: 6px 10px 0 0;
                    padding: 2px 5px;
                    background: #de0716;
                    clear: both;
                }
            }

            .news-gallery {
                @media screen and (max-width: 400px) {
                    margin-top: 10px;
                }
                .company {
                    color: #de0716;
                    font-size: 11px;
                    font-weight: bold;
                }

                .link {
                    margin-top: 5px;

                    & > a {
                        border-bottom: 1px solid #333;

                        &:hover {
                            opacity: 0.8;
                            color: #de0716;
                            border-bottom: 1px solid #de0716;
                            transition-duration: 0ms;
                        }
                    }
                }
            }
        }
    }

    .news-button {
        background: #333;
        border-radius: 3px;
        width: 250px;
        margin: 30px auto 0;
        @media screen and (max-width: 400px) {
            margin-bottom: 30px;
        }

        & > a {
            text-align: center;
            color: #fff;
            padding: 15px 0 14px;
            display: block;
            background: url(../../src/ja/images/common/ico_common06.png) no-repeat 92% 50%;
        }

        & > .prev {
            text-align: center;
            color: #fff;
            padding: 15px 0 14px;
            display: block;
            background: url(../../src/ja/images/common/ico_common16.png) no-repeat 5% 50%, #333;
        }
    }

    // ページネーションデザイン
    .dataTables_wrapper .dataTables_paginate {
        margin: auto;
        text-align: center;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
    .dataTables_wrapper .dataTables_paginate .paginate_button.next,
    .dataTables_wrapper .dataTables_paginate .paginate_button.first,
    .dataTables_wrapper .dataTables_paginate .paginate_button.last,
    .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
        padding: 0 10px;
        color: #fff;
        background-color: #bcbcbc;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button.current {
        color: #de0716 !important;
        text-decoration: underline #de0716 !important;
        border: none;
        background: none;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 0.2rem 0.5rem;
    }
}
