@charset "utf-8";

/* CSS Document */
#ir-library-report {
    .selectArea01 {
        width: 245px;
    }

    .text01 span.pdf,
    .document td span.pdf,
    .comment span.pdf,
    .text01 span.win,
    .document td span.win,
    .comment span.win {
        margin-left: 0.5em;
    }

    .new {
        font-size: 12px;
        color: #de0716;
        vertical-align: top;
        margin: 0 5px;
    }

    /*業績・財務*/

    .detail01 p {
        margin-top: 0.5em;
        text-align: center;

        &.unit {
            text-align: right;
            font-size: 1em;
            line-height: 1.5em;
            margin: 0;
        }
    }

    .xj_chart_table {
        td {
            padding: 3px;
            border: 1px solid #ccc;
            text-align: right;
            width: 11%;
            font-size: 95%;
        }

        .xj_chart_header_first {
            text-align: center;
        }
    }

    .detail01 .xj_chart_table .xj_chart_header {
        text-align: center;
    }

    /*ライブラリ*/

    .newsList01 {
        .tab-content ul {
            display: block;
        }

        h3:nth-of-type(2) {
            margin-top: 35px;
        }
    }

    /*株式*/

    .stockInfo01 {
        .devidedTable01 .xj_chart_table td,
        .devidedTable02 .xj_chart_table td {
            width: 16.66%;
            padding: 10px;
            border-right: none;
            border-left: none;
            font-size: 100%;
            text-align: center;
        }

        .devidedTable01 .xj_chart_table td:first-child {
            text-align: left;
            background: #f4f4f4;
        }

        .devidedTable02 .xj_chart_table {
            td:first-child {
                text-align: left;
                background: #f4f4f4;
            }

            &:nth-of-type(2) {
                tr:first-child {
                    display: none;
                }

                td {
                    border-top: none;
                }
            }
        }
    }

    /*ここから追加*/
    @media screen and (max-width: 767px) {
        .stockInfo01 .devidedTable02 .xj_chart_table td {
            padding: 3px;
        }
    }

    /*ここからまで追加*/

    /*カレンダー*/

    #XJTxtDPDataTbl {
        width: 100%;

        td {
            padding: 10px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            vertical-align: middle;

            &.XJTxtDPDataTblTdDate {
                width: 160px;
                background: #f4f4f4;
                padding-left: 5px;
            }
        }

        .XJTxtDPDataTableTr.closingMonthLine {
            td {
                padding: 0;
                border: none;
            }

            &:not(:first-child) td {
                padding-top: 60px;
            }
        }
    }

    #XJTxtDPLowerNote {
        margin-top: 60px;
    }
}
