#recruit {
    
    .btn01 {
        width: auto;
        margin: 10px auto 0;
        transition: 0.2s;
        &:hover {
            opacity: 0.8;
        }
        @media screen and (max-width: 430px) {
            width: 100%;
        }
        &.blankLink {
            a {
                padding: 15px 32px 14px 15px;
                background-size: 15px;
                background-position: 95% center;
                border-radius: 3px;
            }
        }
    }
    .recruit01 {
        .title03 {
            padding: 48px 25px 46px;
            background: url("../../src/ja/images/recruit/bg_index01.png") no-repeat 100% 0, #f6f6f6;
        }
        .blankLink {
            a {
                background: url("../../src/ja/images/recruit/ico_recruit01.png") no-repeat 90% center;
                background-size: 15px;
            }
        }
    }
    .contentNav{
        display: flex;
        flex-wrap: wrap;

        li a{
            margin: 0;
            padding: 12px 16px;
        }
    }
    
    #new_graduate{/* 新卒採用 */
        margin-bottom: 20px;
        height: auto;
        width: 100%;

        .visual_new_graduate{
            position: relative;
            max-width: 100%;
            height: 400px;
            color: #ffff;
            background-image: url(../../src/ja/images/recruit/recruit_new_graduate.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @media screen and (max-width: 767px) {
                height: 350px;
            }
            @media screen and (max-width: 450px) {
                height: 320px;
            }
            
            .catchcopy{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                white-space: nowrap;
                /* margin: 0 auto;
                display:  inline-block; */

                p.afont_tsukumin{/* 世界を変える＋意思 */
                    font-family: "fot-tsukumin-pr6n", serif;
                    font-size: 3rem;
                    font-weight: 300;
                    line-height: 1.3;
                    font-style: normal;
                    @media screen and (max-width: 767px) {
                        font-size: 2.5rem;
                    }
                    @media screen and (max-width: 600px) {
                        font-size: 1.8rem;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 1.4rem;
                    }
                }
                
                .redtext{/* WILL */
                    color: #ff4552;
                    letter-spacing: .2em;
                    margin-right: 15px;
                    z-index: 1;
                }
                .backtext{/* 意思 */
                    -webkit-text-stroke: 2px rgba(255,255,255,.2);
                    -webkit-text-fill-color: rgba(255,255,255,0);
                    font-size: 90px;
                    position: absolute;
                    left: 63%;
                    top: 40%;
                    transform: translate(-50%,-50%);
                    z-index: -1;
                    @media screen and (max-width: 767px) {
                        font-size: 80px;
                    }
                    @media screen and (max-width: 600px) {
                        font-size: 60px;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 45px;
                    }
                }
            }
        }
    }

    #mid_career{/* 中途採用 */

        .visual_mid_career{
            max-width: 100%;
            height: 400px;
            background-image: url(../../src/ja/images/recruit/recruit_mid_career.png) ;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin: auto;

            @media screen and (max-width: 767px) {
                height: 350px;
            }
            @media screen and (max-width: 450px) {
                height: 320px;
            }
        }
    }

    .text_flex_w100{/* w100% */
        display: flex;
        align-items: baseline;
        width: 100%;
        gap: 20px;
        @media screen and (max-width: 720px) {
            flex-direction:column;
            max-width: 100%;
            gap: 0;
        }
        
    }

    .text_w60{/* w60% 新卒採用ボタン*/
        max-width: 60%;
        margin: 0 auto;
        line-height: 1.5;
        margin: 20px auto 40px;
        @media screen and (max-width: 767px) {
            flex-direction:column;
            max-width: 100%;
        }
    }

    .text_w50{/* w50% */
        max-width: 50%;
        margin: 0 auto;
        line-height: 1.5;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
            flex-direction:column;
            max-width: 100%;
        }
    }

    #group_career{/*  グループ採用 */
        .groupWrap{
            display: flex;
            justify-content: center;
            gap: 20px;
            margin: 20px 0;
    
            @media screen and (max-width: 720px) {
                flex-direction:column;
                max-width: 100%;
            }
        }
        ul{
            width: 100%;
        }
        li{
            width: 100%;
            cursor: pointer;/* common */
            border: 1px #e6e6e6 solid;
            padding: 20px;  
        }
        
        p{
            font-size: 16px;
            line-height: 1.5; 
            display: flex; /* 親要素 */
            flex-direction: column;

            .grow{/* 孫要素を揃える */
                flex-grow:1;
            }
        }
        .icoBlank {/* 外部リンクアイコン */
            width: fit-content;
            padding-right: 20px;
            background: url(../../src/ja/images/common/ico_common09.png) no-repeat 100% center;
            background-size: 16px auto;
        }
        .w50{
            width: 50%;
            height: auto;
            padding: 20px 0;
            margin: 0 auto;
            line-height: 1.5;
        }
        
        .summary{
            width: 100%;
        }
        .left{
            text-align: left;
        }
        .mb_10_20{
            margin: 10px auto 20px;
        }
    }
    
    

    #sns{
        .groupWrap{
            display: flex;
            justify-content: center;
            gap: 20px;
            margin: 20px 0;
    
            @media screen and (max-width: 766px) {
                flex-direction:row;
                max-width: 100%;
            }
            @media screen and (max-width: 735px) {
                flex-direction:column;
                max-width: 100%;
                gap: 10px;
            }
        }
        .groupinner{
            max-width: 100%;
            display: flex;
            gap: 20px;
            @media screen and (max-width: 735px) {
                width: 100%;
                gap: 10px;
            }
        }

        ul{
            width: 50%;
            display: flex;
            
        }
        li{
            /* display: flex; */
            width: 100%;
            cursor: pointer;/* common */
            border: 1px #e6e6e6 solid;
            padding: 15px;

            p{
                line-height: 1.5;
                @media screen and (max-width: 890px) {
                    font-size: 10px;
                }
            }
            span{
                font-size: 0.8rem;
                line-height: 1.3;
                &:nth-of-type(1){
                    font-size: 20px;
                }
            }
            img{
                width: 48px;
                height: 48px;
            }
        }
        .bm_col{/* bigsmile color */
            color: #808080;
        }
        .is_col{/* Instagram color */
            color: #F40D7C;
        }
        .yt_col{/* youtube color */
            color: #FF0000;
        }
    }
}

