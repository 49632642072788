@charset "UTF-8";

#top {
    .mainImage01 {
        padding-bottom: 50px;
        @media screen and (max-width: 767px) {
            padding-top: 28px;
            padding-bottom: 0px;
        }
        .mainVideo {
            width: 100%;
            video {
                width: 100%;
            }
        }
        .topImgFrame {
            position: relative;
            width: 100%;
            height: calc(100vh - 76px);
            overflow: hidden;
            margin: 0 auto;
        
            @media screen and (max-width: 767px) {
                height: 475px;
            }
        }
        
        .topImg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            transform: scale(1.0);
            animation: fade-zoom 22s infinite;
        }   

        /* 各画像の背景 */
        .pcImg01 { background-image: url(../../src/ja/images/top/hero_pc_01.jpg); animation-delay: 0s; }
        .pcImg02 { background-image: url(../../src/ja/images/top/hero_pc_02.jpg); animation-delay: 4.5s; }
        .pcImg03 { background-image: url(../../src/ja/images/top/hero_pc_03.jpg); animation-delay: 8.5s; }
        .pcImg04 { background-image: url(../../src/ja/images/top/hero_pc_04.jpg); animation-delay: 12.5s; }
        .pcImg05 { background-image: url(../../src/ja/images/top/hero_pc_05.jpg); animation-delay: 16.5s; }

        .spImg01 { background-image: url(../../src/ja/images/top/hero_sp_01.jpg); animation-delay: 0s; }
        .spImg02 { background-image: url(../../src/ja/images/top/hero_sp_02.jpg); animation-delay: 4.5s; }
        .spImg03 { background-image: url(../../src/ja/images/top/hero_sp_03.jpg); animation-delay: 8.5s; }
        .spImg04 { background-image: url(../../src/ja/images/top/hero_sp_04.jpg); animation-delay: 12.5s; }
        .spImg05 { background-image: url(../../src/ja/images/top/hero_sp_05.jpg); animation-delay: 16.5s; }

        /* フェード & ズームアニメーション */
        @keyframes fade-zoom {
            0% { opacity: 0; transform: scale(1.0); }
            20% { opacity: 1; transform: scale(1.0); }
            40% { opacity: 0; transform: scale(1.1); } 
        }
        
        /* 透過文字画像を常に表示 */
        .overlayImg{
            position: absolute;
            width: 58%;
            height: 60%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: 2;
            @media screen and (max-width: 767px) {
                width: 63%;
            }
        }
        .pcOverlayText {
            transform: translateX(10%) translateY(50%);
            background-image: url(../../src/ja/images/top/hero_pc_ttl_jp.png);
        }
        .spOverlayText {
            transform: translateX(11%) translateY(51%);
            background-image: url(../../src/ja/images/top/hero_sp_ttl_jp.png);
        }
        
    }
    /*.mainImage01 .topTitle01 {
        font-size: 48px;
        font-weight: 200;
        color: #fff;
        padding: 184px 0;
        background: url("../../src/ja/images/top/main_visual_1.png") no-repeat center bottom;
        background-size: cover;
        text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    }

    @media screen and (max-width: 1200px) {
        .mainImage01 .topTitle01 {
            padding: 148px 0;
        }
    }

    @media screen and (max-width: 1000px) {
        .mainImage01 .topTitle01 {
            font-size: 40px;
            padding: 124px 0;
        }
    }

    @media screen and (max-width: 900px) {
        .mainImage01 .topTitle01 {
            padding: 108px 0;
        }
    }

    @media screen and (max-width: 767px) {
        .mainImage01 .topTitle01 {
            padding: 140px 0;
            background: url("../../src/ja/images/top/main_visual_1.png") no-repeat 32% 100%;
            background-size: cover;
        }
    }

    @media screen and (max-width: 640px) {
        .mainImage01 .topTitle01 {
            font-size: 24px;
            padding: 114px 0;
            background: url("../../src/ja/images/top/main_visual_1.png") no-repeat 40% 100%;
            background-size: cover;
        }
    }

    @media screen and (max-width: 500px) {
        .mainImage01 .topTitle01 {
            padding: 70px 0;
            background: url("../../src/ja/images/top/main_visual_1.png") no-repeat 50% 100%;
            background-size: cover;
        }
    }

    @media screen and (max-width: 400px) {
        .mainImage01 .topTitle01 {
            padding: 62px 0;
            font-size: 20px;
        }
    }

    @media screen and (max-width: 360px) {
        .mainImage01 .topTitle01 {
            font-size: 18px;
            padding: 54px 0;
        }
    }*/

    .content {
        padding: 50px 0px;
        margin: 0 auto;
        h2 {
            font-size: 34px;
            margin-bottom: 36px;

            &:before {
                content: "";
                width: 170px;
                margin: 0 auto;
                padding-top: 30px;
                border-top: 2px solid #ff1515;
                display: block;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .content h2 {
            font-size: 20px;
            margin-bottom: 16px;
        }
    }

    @media screen and (max-width: 767px) {
        .content h2:before {
            width: 100px;
            padding-top: 14px;
        }
    }

    @media screen and (min-width: 768px) {
        .content01 li {
            width: 31.4%;
        }
    }

    @media screen and (max-width: 767px) {
        .content01 li {
            width: 75%;
            margin: 0 auto 25px;
            float: none;
        }
    }

    @media screen and (max-width: 640px) {
        .content01 li {
            width: 90%;
            margin: 0 auto 20px;
        }
    }

    .content01 li {
        a {
            font-size: 0;
            line-height: 0;
            display: block;
        }

        img {
            font-size: 0;
            line-height: 0;
            border-bottom: 1px solid #fff;

            &:hover {
                font-size: 0;
                line-height: 0;
            }
        }

        &:nth-child(-n + 2) {
            margin-bottom: 25px;
        }
    }

    @media screen and (min-width: 768px) {
        .content01 li {
            &:first-child,
            &:nth-child(2) {
                width: 48.5%;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .content01 li:not(:nth-child(2)):not(:last-child) {
            margin-right: 2.9%;
        }
    }

    .content02 {
        // background: url("../../src/ja/images/top/bg_index02.jpg");

        .titleArea01 {
            width: 1000px;
            margin: 80px auto;

            h4 {
                font-size: 34px;
            }

            p {
                font-size: 18px;
                margin-top: 20px;
            }
        }

        ul {
            margin-bottom: 20px;
        }

        li {
            width: 32%;
            margin-bottom: 20px;
            border-radius: 3px;
            overflow: hidden;

            img.pcOnly,
            a {
                display: block;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .content02 .titleArea01 {
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 .titleArea01 {
            width: 94%;
            margin: 30px auto 35px;
        }
    }

    @media screen and (max-width: 900px) {
        .content02 .titleArea01 h4 {
            width: 41%;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 .titleArea01 h4 {
            font-size: 15px;
            font-weight: 500;
            width: 100%;
            text-align: center;
            float: none;
        }
    }

    @media screen and (max-width: 900px) {
        .content02 .titleArea01 p {
            width: 56%;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 .titleArea01 p {
            font-size: 15px;
            width: 100%;
            float: none;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 ul {
            width: 552px;
            margin: 0 auto 20px;
        }
    }

    @media screen and (max-width: 600px) {
        .content02 ul {
            width: 94%;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 li {
            width: 49%;
        }
    }

    @media screen and (max-width: 600px) {
        .content02 li {
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width: 768px) {
        .content02 li:not(:nth-child(3n)) {
            margin-right: 2%;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 li:nth-child(2n + 1) {
            clear: both;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 li:not(:nth-child(2n)) {
            margin-right: 2%;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 li img.pcOnly {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        .content02 li img.spOnly {
            display: block;
            width: 100%;
        }
    }

    .btn01 {
        width: 250px;
        margin: 0 auto 25px;

        &:hover {
            opacity: 0.8;
        }

        a {
            background: url("../../src/ja/images/common/ico_common06.png") no-repeat 95% 50%;

            &:hover {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .btn01 {
            width: 80%;
        }
    }

    .content03 {
        padding: 50px 0px;
        //padding: 50px 0 100px;
        //background: url("../../src/ja/images/top/bg_index03.jpg") no-repeat 50% 100%;
        //background-size: cover;

        .top-news{
            .top-news-tab{
                @media screen and (max-width: 767px) {
                    width: 94%;
                    margin: 0 auto;
    
                    li{
                        width: 35%;
                        border-left: 1px solid #e1e1e1;
                    }
                }
                .top-news-release,
                .top-news-ir {
                    border-left: 1px solid #e1e1e1;
                    border-right: 1px solid #e1e1e1;
                }
            }
        }

        .tab02 .active {
            background: rgba(238, 238, 238, 0.4);
            border: 0px;
            border-top: 6px solid #de0716;
        }

        .newsArea01 {
            display: none;
            padding: 40px 50px;
            background: rgba(238, 238, 238, 0.4);
            position: relative;

            .company-color {
                color: #de0716;
            }

            h2 {
                font-size: 26px;
                margin-bottom: 30px;
            }

            ul {
                height: auto;
                margin-bottom: 25px;
            }

            li {
                padding: 15px 0;
                border-bottom: 1px dotted #999;

                &:last-child{
                    border-bottom: 0px;
                }
            }

            a {
                border-bottom: 1px solid #333;
            }

            .text01 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                width: 100%;
                margin-top: 5px;
                padding-bottom: 1px;
                
                @media screen and (max-width: 767px) {
                    margin: 12px 0px !important;
                }

                a {
                    border-bottom: 1px solid #333;

                    &:hover {
                        color: #de0716;
                        opacity: 0.7;
                        border-bottom: 1px solid #de0716;
                        -webkit-transition-duration: 0ms;
                        -o-transition-duration: 0ms;
                        transition-duration: 0ms;
                    }
                }
            }

            .btn01 {
                @media screen and (max-width: 767px) {
                    margin: 0 auto 20px;
                }
            }
        }
        
        .is_show{
            display: block;
        }
    }

    @media screen and (max-width: 767px) {
        .content03 {
            padding: 50px 0 30px;
            background: none;
        }
    }

    @media screen and (max-width: 500px) {
        .content03 {
            padding-bottom: 15px;
        }
    }

    @media screen and (max-width: 767px) {
        .content03 .newsArea01 {
            width: 94%;
            margin: 0 auto;
            padding: 20px 20px 10px;
            float: none;
        }
    }

    @media screen and (max-width: 767px) {
        .content03 .newsArea01 h2 {
            font-size: 17px;
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 767px) {
        .content03 .newsArea01 ul {
            height: auto;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        .content03 .newsArea01:first-child {
            margin-bottom: 50px;
        }
    }

    @media screen and (max-width: 500px) {
        .content03 .newsArea01 .category01 {
            margin: 0 1.5%;
        }
    }

    @media screen and (min-width: 768px) {
        .content03 .newsArea01 .btn01 {
            bottom: 20px;
            left: 0;
            right: 0;
        }
    }
    .eirItem {
        &:hover {
            opacity: 0.8;
        }
        @media screen and (max-width: 767px) {
            &::before{
                height: 0px;
            }
        }

        .eirItem_title {
            display: flex;

            @media screen and (max-width: 767px) {
                display: flex;
                margin: 12px 0px;
            }

            .s_eirModule_title {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                padding-bottom: 1px;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                a {
                    border-bottom: 1px solid #333;

                    &:hover {
                        color: #de0716;
                        opacity: 0.7;
                        border-bottom: 1px solid #de0716;
                        -webkit-transition-duration: 0ms;
                        -o-transition-duration: 0ms;
                        transition-duration: 0ms;
                    }
                }
            }
            .eirItem_type {
                img {
                    max-width: none;
                }
            }
        }
    }

    // トップページレイアウト変更 新規追加部分 2023
    // ーータイトル下 テキストエリア
    .topTitleArea {
        width: 100%;
        max-width: 1000px;
        margin: 34px auto 50px;
        @media screen and (max-width: 767px) {
            width: 94%;
            margin: 0 auto 16px;
        }
        h3 {
            font-size: 32px;
            @media screen and (max-width: 820px) {
                font-size: 16px;
            }
        }
        .textLR {
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                flex-shrink: 0;
                font-size: 32px;
                padding-right: 20px;
            }
            p {
                font-size: 18px;
            }
            @media screen and (max-width: 820px) {
                display: flex;
                flex-direction: column;
                h3 {
                    flex-shrink: 0;
                    font-size: 16px;
                    padding-right: 0;
                    margin-bottom: 16px;
                }
                p {
                    font-size: 15px;
                }
            }
        }
    }
    // ーーカラムレイアウト 外枠
    .contentColumnWrap {
        display: grid;
        gap: 16px;
        @media screen and (max-width: 767px) {
            width: 94%;
            margin: 0 auto;
            gap: 8px;
        }
    }
    // ーーカラムレイアウト カラム数別 中枠
    .contentColumn_1 {
        display: grid;
        grid-template-columns: 1fr;
    }
    .contentColumn_2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
        }
    }
    .contentColumn_3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
        }
    }
    // ーーカラムレイアウト 共通中身
    .contentColumnItem {
        display: block;
        position: relative;
        transition-duration: 400ms;
        &:hover {
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            transform: translateY(-2px);
        }
        .topLinkImg {
            display: block;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba(234,235,240,1) 58%, rgba(234,235,240,0) 100%);
                width: 60%;
                min-width: 200px;
                max-width: 460px;
                height: 100%;
                z-index: 1;
                @media screen and (max-width: 767px) {
                    max-width: 240px;
                }
            }
            img {
                width: 100%;
                transition: all .5s;
            }   
        }
        .topLinkText {
            position: absolute;
            top: 24px;
            left: 24px;
            color: #333;
            font-size: 22px;
            font-weight: 600;
            z-index: 2;
            span {
                display: block;
                font-size: 14px;
                font-weight: 400;
            }
            @media screen and (max-width: 820px) {
                position: absolute;
                top: 8px;
                left: 10px;
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
        }
        .top_integrated_report {
            color: #808080;
            font-weight: 400;
            span {
                color:#333;
                font-size: 48px;
                font-weight: 600;
            }
            @media screen and (max-width: 820px) {
                font-size: 12px;
                span {
                    font-size: 16px;
                }
            }
        }
    }
    // ーーカラムレイアウト 共通中身 レスポンシブ時画像カット
    .contentColumn_1 {
        .contentColumnItem {
            .topLinkImg img {
                @media screen and (max-width: 767px) {
                    width: 100%;
                    height: 240px;
                    object-fit: cover;
                    object-position: 50% 0;
                }
            }
        }
    }
    .contentColumn_2,
    .contentColumn_3 {
        .contentColumnItem {
            .topLinkImg img {
                @media screen and (max-width: 767px) {
                    width: 100%;
                    height: 120px;
                    object-fit: cover;
                }
            }
        }
    }
    // ーーカラムレイアウト グラデーションを表示しない
    .noGradation {
        &::before {
            display: none !important;
        }
    }
    // ーーカラムレイアウト 共通 hover時の動き
    .contentColumnItem a:hover .topLinkImg img {
        opacity: 100%;
        transition: all .5s;
    }

    /*===========================================================================
        404ページ
    ==============================================================================*/
    .footer404 {
        width: 100%;
        background: none;
        position: fixed;
        bottom: 0;

        .subArea01_404 {
            padding: 10px 0;
        }

        .copyright01_404 {
            font-size: 12px;
            color: #818181;
            margin-top: 0;
        }
    }

    .mainContent404 {
        margin: 10px;
        padding-bottom: 80px;

        .main404 {
            width: 100%;
            margin: 0 auto;

            .content404 {
                padding: 10px 0 0;

                .title02 {
                    margin-bottom: 20px;
                }

                .lookForSite {
                    width: 100%;
                    margin: 0 auto;

                    .ul404 {
                        width: 100%;
                        margin: 0 auto;

                        li {
                            margin-bottom: 15px;
                        }

                        p {
                            &:first-child {
                                padding-top: 17px;
                                width: 30%;
                            }

                            &:last-child {
                                width: 100%;
                                max-width: 250px;
                                padding-top: 0;
                            }

                            .btn404 {
                                width: 100%;
                                border-radius: 3;
                            }

                            .linkExplain {
                                font-size: 14px;
                                padding: 14px 0 13px;
                            }
                        }
                    }
                }
            }

            a.btn01 {
                color: #fff;
                display: block;
                padding: 15px 0 14px;
                background: url("../common/images/ico_common06.png") no-repeat 95% 50% #333;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent404 {
            padding: 82px 0 40px;
        }
    }

    @media screen and (max-width: 426px) {
        .mainContent404 .main404 .content404 {
            width: 100%;
        }
    }

    @media screen and (max-width: 600px) {
        .mainContent404 .main404 .content404 .lookForSite {
            display: block;
        }
    }

    @media screen and (max-width: 900px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 {
            width: 100%;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 600px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 p {
            float: none;
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 p:first-child {
            font-size: 16px;
            width: 60%;
            margin-right: 2%;
        }
    }

    @media screen and (max-width: 600px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 p:first-child {
            width: 100%;
            margin: 0 0 20px 0;
            text-align: center;
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 p:last-child {
            max-width: 180px;
        }
    }

    @media screen and (max-width: 600px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 p:last-child {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent404 .main404 .content404 .lookForSite .ul404 p .btn404 {
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent404 .main404 .backToppage {
            width: 450px;
        }
    }

    @media screen and (max-width: 500px) {
        .mainContent404 .main404 .backToppage {
            width: 300px;
        }
    }

    @media screen and (max-width: 400px) {
        .mainContent404 .main404 .backToppage {
            width: 250px;
        }
    }

    @media screen and (max-width: 767px) {
        .mainContent404 .main404 a.btn01 {
            width: 250px;
        }
    }

    .btn01 {
        margin: 0 auto;
    }

    .backToppage {
        margin: 60px auto 0;
    }

    .indexLink01 li a:first-child {
        background: url("../../src/ja/images/top/bg_index01.jpg") no-repeat center center;
    }
}
