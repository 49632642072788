.header {
  padding-top: 30px;
  position: relative;
  @media screen and (max-width:767px) {
    padding: 20px 0;
    background: #fff;
    border-bottom: 1px solid #f4f4f4;
    z-index: 10;
    position: fixed;
    left: 0;
    right: 0;
  }
  @media screen and (max-width:420px) {
    padding: 28px 0 24px;
  }
  h1 {
    @media screen and (max-width:767px) {
      float: none;
    }
    @media screen and (max-width:420px) {
      width: 195px;
    }
    img {
      max-width: 288px;
      width: 100%;
    }
  }

  .box01 {
    width: 88px;
    overflow: hidden;
    background: #f5f5f5;
    border: 1px solid #999;
    border-radius: 3px;
    @media screen and (max-width:767px) {
      width: 90%;
      margin: 30px auto 0;
      background: #fff;
    }
    select {
      font-family: "Noto Sans Japanese","ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",Meiryo,"ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
      font-size: 13px;
      width: 100%;
      height: 26px;
      background: none;
      padding: 0 0 1px 22px;
      border: none;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      cursor: pointer;
      @media screen and (max-width:767px) {
        font-size: 16px;
        height: 55px;
        padding-left: 40%;
      }
      @media screen and (max-width:400px) {
        padding-left: 38%;
      }

      @media all and (-ms-high-contrast: none) {
        width: 100% !important;
        &::-ms-expand {
          display: none;
        }
      }

      &:not(:target) {
        width: 130%\9;
      }
    }
  }

  .logoArea01 {
    margin-bottom: 50px;
    @media screen and (max-width:767px) {
      margin-bottom: 0;
    }
  }

  .mainMenu01 {
    padding-bottom: 22px;
    @media screen and (min-width:768px) {
      display: block !important;
    }
    @media screen and (max-width:767px) {
      padding: 20px 0 35px;
      background: #eee;
      display: none;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
    }

    li {
      @media screen and (max-width:767px) {
        width: 100%;
        border-left: 0;
        border-bottom: 1px dotted #d2d2d2;
        text-align: left;
        float: none;
      }

      &:last-child {
        @media screen and (max-width:767px) {
          border-right: 0;
        }
      }

      &:not(.active) {
        a {
          &:hover {
            color: #de0716;
            position: relative;
            @media screen and (max-width:767px) {
              color: #333;
            }
            &:before,
            &:after {
              content: "";
              margin: 0 auto;
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              @media screen and (max-width:767px) {
                display: none;
              }
            }

            &:before {
              width: calc(100% - 32px);
              height: 3px;
              background: #cc0000;
              bottom: -8px;
            }

            &:after {
              width: 7px;
              height: 4px;
              background: url("../../src/ja/images/common/ico_header01.png") no-repeat 50% 100%;
              bottom: -12px;
            }
          }
        }
      }
    }

    a {
      @media screen and (max-width:767px) {
        background: url("../../src/ja/images/common/ico_common03.png") no-repeat 98% 50%;
      }
    }

    .active {
      a {
        color: #de0716;
        position: relative;
        @media screen and (max-width:767px) {
          color: #333;
        }
        &:before,
        &:after {
          content: "";
          margin: 0 auto;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          @media screen and (max-width:767px) {
            display: none;
          }
        }

        &:before {
          width: calc(100% - 32px);
          height: 3px;
          background: #cc0000;
          bottom: -8px;
        }

        &:after {
          width: 7px;
          height: 4px;
          background: url("../../src/ja/images/common/ico_header01.png") no-repeat 50% 100%;
          bottom: -12px;
        }
      }
    }
  }
}

.spBtn01 {
  font-weight: bold;
  color: #c00;
  // width: 56px;
  padding: 14px 18px 10px;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 0;
  &:hover {
    opacity: 0.8;
  }

  p {
    transition: all .4s;
    position: relative;
    &:first-child {
      width: 40px;
      // width: 50px;
      height: 33px;
      margin: 0 auto 5px;
      @media screen and (max-width:420px) {
        width: 40px;
      }
    }
  }

  &.active {
    background: #eee;
    span {
      &:first-child {
        -webkit-transform: translateY(14px) rotate(-45deg);
        transform: translateY(14px) rotate(-45deg);
      }

      &:nth-child(2) {
        left: 50%;
        opacity: 0;
        -webkit-animation: active-menu-bar02 .8s forwards;
        animation: active-menu-bar02 .8s forwards;
      }

      &:last-child {
        -webkit-transform: translateY(-14px) rotate(45deg);
        transform: translateY(-14px) rotate(45deg);
      }
    }
  }

  span {
    width: 100%;
    height: 5px;
    background: #c00;
    transition: all .4s;
    display: block;
    position: absolute;
    &:first-child {
      top: 0;
    }

    &:nth-child(2) {
      top: 14px;
      // top: 12px;
    }

    &:last-child {
      bottom: 0;
    }
  }

  @-webkit-keyframes active-menu-bar02 {
      100% {
        height: 0;
      }
    }
    @keyframes active-menu-bar02 {
      100% {
        height: 0;
      }
    }
}
.EnglishBtn {
      width:100px;
      text-align: center;
      a {
        font-size: 13px;
        font-family: "Noto Sans Japanese","ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",Meiryo,"ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
        border: 1px solid #e6e6e6;
        border-radius: 3px;
        padding: 8px 0;
        background: #f6f6f6;
        display: block;
        &:hover {
          color: #de0716;
        }
      }
    }