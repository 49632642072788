@charset "UTF-8";

#contact,
#compliance,
#jinken {
    @media screen and (max-width: 767px) {
        .mainContent {
            margin-bottom: 40px;
        }
    }

    .title03 {
        padding: 48px 25px 46px;
        background: url("../../src/ja/images/mail/bg_index01.png") no-repeat 100% 0, #f6f6f6;
    }

    @media screen and (max-width: 767px) {
        .title03 {
            background: url("../../src/ja/images/mail/bg_index01.png") no-repeat 100% 0, #f6f6f6;
        }
    }

    @media screen and (max-width: 400px) {
        .title03 {
            padding: 30px 15px 22px;
            background: url("../../src/ja/images/mail/bg_index01.png") no-repeat 0 0, #f6f6f6;
            background-size: cover;
        }
    }

    @media screen and (max-width: 767px) {
        .inquiry01 {
            margin-top: 30px;
        }
    }

    .inquiry01 .main01 {
        width: 100%;
        float: none;
    }

    @media screen and (max-width: 767px) {
        .inquiry01 .main01 {
            width: 96%;
            margin: 0 auto;
        }
    }

    input[type="text"],
    input[type="email"] {
        width: 100%;
        height: 40px;
        border: 1px solid #ccc;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .wpcf7-radio {
        .wpcf7-list-item {
            label {
                padding-left: 25px;
                vertical-align: middle;
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                position: relative;

                @media screen and (max-width: 400px) {
                    font-size: 13px;
                    width: 100%;
                    letter-spacing: -0.5px;
                    padding: 3px 0 3px 32px;
                }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                }

                &::before {
                    width: 16px;
                    height: 16px;
                    border: 1px solid #c00;
                    border-radius: 50%;
                    z-index: 3;
                    top: 1px;
                    left: 0;

                    @media screen and (max-width: 400px) {
                        width: 20px;
                        height: 20px;
                        top: 2px;
                    }
                }
                &::after {
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background: #c00;
                    z-index: 1;
                    top: 5px;
                    left: 4px;

                    @media screen and (max-width: 400px) {
                        width: 14px;
                        height: 14px;
                        top: 6px;
                        left: 4px;
                    }
                }
            }

            input {
                &[type="radio"] {
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    padding: 0;
                    box-shadow: 41px 0px #fff;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    z-index: 2;
                    display: block;
                    position: absolute;
                    left: -40px;
                    @media screen and (max-width: 400px) {
                        width: 23px;
                        height: 23px;
                    }
                    &:checked {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .wpcf7-acceptance {
        .wpcf7-list-item {
            label {
                padding-left: 22px;
                box-sizing: border-box;
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                position: relative;
                @media screen and (max-width: 400px) {
                    font-size: 13px;
                    letter-spacing: -0.5px;
                    padding: 3px 0 3px 32px;
                }
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                }
                &::before {
                    width: 16px;
                    height: 16px;
                    border: 2px solid #de0716;
                    border-radius: 3px;
                    z-index: 3;
                    top: 1px;
                    left: 0;
                    @media screen and (max-width: 400px) {
                        width: 20px;
                        height: 20px;
                    }
                }
                &::after {
                    width: 4px;
                    height: 9px;
                    margin-top: -6px;
                    border-right: 3px solid #de0716;
                    border-bottom: 3px solid #de0716;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    z-index: 1;
                    display: block;
                    top: 10px;
                    left: 7px;
                    @media screen and (max-width: 400px) {
                        width: 6px;
                        height: 14px;
                        top: 9px;
                        left: 8px;
                    }
                }
                input {
                    &[type="checkbox"] {
                        width: 20px;
                        height: 20px;
                        margin: 0;
                        padding: 0;
                        box-shadow: 41px 0px #fff;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        z-index: 2;
                        display: block;
                        position: absolute;
                        left: -40px;
                        @media screen and (max-width: 400px) {
                            width: 22px;
                            height: 22px;
                        }
                        &:checked {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    .title04 {
        margin-top: 40px;
    }

    .table01 {
        th {
            font-size: 16px;
            font-weight: 500;
            padding-left: 15px;
            text-align: left;

            p {
                font-size: 14px;
                font-weight: 400;
                margin-top: 5px;
                line-height: 1.4;
            }
        }

        td {
            padding: 15px;
        }
    }

    @media screen and (min-width: 768px) {
        .table01 th {
            width: 198px;
        }
    }

    @media screen and (max-width: 500px) {
        .radioArea01 p {
            width: 100%;
            float: none;
        }
    }

    .radioArea01 p:not(:last-child) {
        margin-right: 4%;
    }

    @media screen and (max-width: 500px) {
        .radioArea01 p:not(:last-child) {
            margin: 0 0 8px;
        }
    }

    .name01 {
        p {
            width: 300px;

            &:first-child {
                margin-right: 4%;
            }
        }

        span {
            display: inline-block;
        }

        input {
            padding-left: 5px;
        }
    }

    @media screen and (max-width: 640px) {
        .name01 p {
            width: 100%;
            float: none;
        }
    }

    @media screen and (max-width: 640px) {
        .name01 p:first-child {
            margin: 0 0 10px;
        }
    }

    @media screen and (max-width: 640px) {
        .name01 input {
            width: 84%;
        }
    }

    .content {
        margin-top: 25px;
    }

    @media screen and (max-width: 767px) {
        .content {
            margin-bottom: 30px;
        }
    }

    .emailArea01 {
        .text01 {
            margin: 20px 0 5px;
        }

        .email01 p,
        .email02 p {
            width: 77%;
        }

        .email01 p input,
        .email02 p input {
            padding-left: 5px;
        }

        .email01 p:not(:last-child),
        .email02 p:not(:last-child) {
            margin-right: 1.5%;
        }

        .email01 p:nth-child(2),
        .email02 p:nth-child(2) {
            margin-top: 8px;
        }

        .email01 .email01Text,
        .email02 .email01Text {
            width: 21%;
        }
    }

    @media screen and (min-width: 768px) {
        .long01 p {
            width: 80%;
        }
    }

    .zip01 input {
        &:first-child {
            width: 125px;
            margin-right: 10px;
        }

        &:last-child {
            width: 175px;
            margin-left: 10px;
        }
    }

    @media screen and (max-width: 400px) {
        .zip01 input:first-child {
            width: 110px;
        }
    }

    @media screen and (max-width: 360px) {
        .zip01 input:first-child {
            width: 90px;
        }
    }

    @media screen and (max-width: 400px) {
        .zip01 input:last-child {
            width: 155px;
        }
    }

    @media screen and (max-width: 360px) {
        .zip01 input:last-child {
            width: 135px;
        }
    }

    .pref01 .selectArea01 {
        width: 186px;
        margin: 0;
        background: url("../images/ico_index01.png") no-repeat 100% 0, #fff;
        border: 1px solid #ccc;

        select {
            height: 40px;
        }
    }

    .tel01 {
        input {
            margin-right: 1.5%;

            &:first-child {
                width: 77%;
            }
        }

        span {
            width: 21%;
        }
    }

    @media screen and (max-width: 540px) {
        .tel01 span {
            margin-top: 8px;
        }
    }

    .comment01 textarea {
        font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic",
            sans-serif;
        color: #999;
        width: 80%;
        height: 300px;
        padding: 10px;
        border: 1px solid #ccc;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        resize: none;

        &:focus {
            outline: none;
        }
    }

    @media screen and (max-width: 767px) {
        .comment01 textarea {
            width: 100%;
            height: 250px;
        }
    }

    .privacy01 {
        .required01 {
            color: #de0716;
            padding-right: 10px;
            background: url("../../src/ja/images/common/ico_common05.png") no-repeat 100% 50%;
            background-size: 15px auto;

            &:hover {
                border-bottom: 1px solid #de0716;
                -webkit-transition-duration: 0ms;
                -o-transition-duration: 0ms;
                transition-duration: 0ms;
            }
        }

        .privacy-detail {
            width: 80%;
            height: 100px;
            overflow: scroll;
            margin: 50px 0 50px 0;
        }

        p:first-child {
            margin-bottom: 25px;
        }

        .error01 p {
            margin-bottom: 0;
        }
    }

    
    .btn01 {
        width: 250px;
        margin: 0 auto;
        background: none;

        &.backBtn01 {
            margin: 0 auto;
            margin-bottom: 10px;
        }

        input {
            font-size: 16px;
            background: url("../../src/ja/images/common/ico_common06.png") no-repeat 95% 50%, #de0716;
        }

        a {
            border: 0;
            cursor: pointer;
            text-align: center;
            background: url("../../src/ja/images/top/ico_common16.png") no-repeat 5% 50%, #333;
        }
    }

    .contactTxt01 {
        margin-top: 20px;
    }

    .submitBtnArea {
        width: 600px;
        margin: auto;

        p {
            width: 48%;

            &:first-child {
                margin-right: 4%;

                &:hover {
                    opacity: 0.8;
                }

                input {
                    border: 0;
                    background: url(../../src/ja/images/common/ico_common16.png) no-repeat 5% 50%, #333;

                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .submitBtnArea {
            width: 100%;
        }
    }

    .error01 {
        font-size: 12px;
        color: #de0716;
        margin-top: 5px;
    }
}
