@charset "UTF-8";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}

blockquote {
    &:before,
    &:after {
        content: "";
        content: none;
    }
}

q {
    &:before,
    &:after {
        content: "";
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic",
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    background: #fff;
    line-height: 1.5;
}

a {
    color: #333;
    text-decoration: none;
    transition-duration: 400ms;

    &[href$=".pdf"] {
        &:hover {
            color: #de0716;
            text-decoration: 1px solid #de0716;
            transition-duration: 0ms;
        }
    }
}

img {
    max-width: 100%;
    vertical-align: bottom;

    &.spOnly {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    img.spOnly {
        display: inline;
    }
}

.container {
    width: 95%;
    margin: 0 auto;
}

@media screen and (max-width: 1060px) {
    .container {
        width: 96%;
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: 94%;
    }
}

@media screen and (max-width: 767px) {
    .container02 {
        width: 94%;
        margin: 0 auto;
    }
}

.left {
    float: left;
}

.right {
    float: right;
}

.clearfix:after {
    content: "";
    clear: both;
    display: block;
}

.clear {
    clear: both;
}

.center {
    text-align: center;
}

.textRight {
    text-align: right;
}

@media screen and (max-width: 767px) {
    .pcOnly {
        display: none;
    }
}

.spOnly {
    display: none;
}

@media screen and (max-width: 767px) {
    .spOnly {
        display: block;
    }
}

@media screen and (min-width: 769px) {
    .allLink,
    .allLinkBlank {
        cursor: pointer;
    }

    .allLink:hover,
    .allLinkBlank:hover {
        opacity: 0.8;
    }

    .allLink a:hover,
    .allLinkBlank a:hover {
        opacity: 1;
    }
}

.allLinkNone {
    cursor: default;

    &:hover {
        opacity: 1;
    }
}

input {
    font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic",
        sans-serif;

    &[type="submit"] {
        border: 0;
        cursor: pointer;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;

        &:hover {
            opacity: 0.8;
        }
    }
}

.breadcrumb {
    padding: 20px 0 30px;

    li {
        &:not(:last-child) {
            margin-right: 10px;
            padding-right: 16px;
            background: url("../../src/ja/images/common/ico_common04.png") no-repeat 100% 50%;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: #de0716;
                -webkit-text-decoration: underline #de0716;
                text-decoration: underline #de0716;
                -webkit-transition-duration: 0ms;
                transition-duration: 0ms;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .main {
        .headerTop {
            margin-top: 40px;
        }
    }
}

.mainMenu01 {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;

    li {
        width: 20%;
        border-left: 1px solid #ccc;

        &:last-child {
            border-right: 1px solid #ccc;
        }
    }

    a {
        padding: 5px 0;
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .mainMenu01 {
        font-size: 15px;
        border-bottom: 0;
    }
}

@media screen and (max-width: 1060px) {
    .mainMenu01 li {
        width: 20%;
    }
}

@media screen and (max-width: 767px) {
    .mainMenu01 a {
        padding: 14px 0;
    }
}

.mainContent {
    padding-bottom: 80px;
    padding-top: 76px;
}
@media screen and (max-width: 1279px) {
    .mainContent {
        padding: 60px 0 80px;
    }
}
@media screen and (max-width: 767px) {
    .mainContent {
        padding: 32px 0 30px;
    }
}

@media screen and (max-width: 767px) {
    .mainContent .container {
        //margin-top: 15px;
        margin-top: 28px;
        width: 100%;
    }
}

.title01 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    padding: 28px 25px 33px;
    background: #f6f6f6;
    border-top: 3px solid #999;
}
.title01.sidebar_ttl{
    font-size: 18px;
    margin-bottom: 0;
    padding: 8px 8px 8px 0;
    @media screen and (max-width: 767px){
        margin-top:30px;
    }
}
.sidebar .title01.sidebar_ttl a {
    padding: 12px 10px;
    background: url(../../src/ja/images/common/ico_common03.png) no-repeat 99% 50%;
    @media screen and (max-width: 767px){
        background: url(../../src/ja/images/common/ico_common03.png) no-repeat 99% 50%;
    }
}
.sidebar .title01.sidebar_ttl.active a{
    color: #fff;
    background: url(../../src/ja/images/common/ico_common02.png) no-repeat 99% 50%;
    @media screen and (max-width: 767px){
        background: url(../../src/ja/images/common/ico_common02.png) no-repeat 99% 50%;
    }
}

@media screen and (max-width: 767px) {
    .title01 {
        font-size: 20px;
        padding: 15px 15px 16px;
    }
}

@media screen and (max-width: 500px) {
    .title01 {
        font-size: 18px;
        margin-bottom: 22px;
    }
}

.title02 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .title02 {
        font-size: 20px;
    }
}

.title03 {
    border-top: 0;
}

.title04 {
    padding-top: 22px;
    background: url("../../src/ja/images/top/bg_common01.png") repeat-x 0 0;
}

@media screen and (max-width: 767px) {
    .title04 {
        margin-bottom: 20px;
    }
}

.title05 {
    font-size: 16px;
    //font-weight: 500;
    font-weight: 700;
    margin-bottom: 8px;
}

.title06 {
    font-weight: 600;
    margin-bottom: 8px;
}

.title07 {
    padding: 4px 10px;
    border-left: 4px solid #333333;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1;
}

.title08 {
    font-size: 18px;
    margin-bottom: 20px;
    padding-top: 5px;
    border-top: 1px dotted #333;
}

.title09:before {
    content: "";
    padding-left: 10px;
    border-left: 5px solid #de0716;
}

.title10 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
    .title10 {
        font-size: 15px;
    }
}

.title11 {
    font-size: 22px;
    color: #c00;
    margin-bottom: 30px;
}

.title12 {
    font-size: 22px;
    color: #de0716;
    background: #fff;
    border: 1px solid #de0716;

    .text01 {
        font-size: 46px;
        color: #fff;
        width: 60px;
        background: #de0716;
        line-height: 1.31;
        display: inline-block;
    }

    .text02 {
        padding-left: 8px;
        display: inline-block;
    }
}

@media screen and (max-width: 800px) {
    .title12 {
        font-size: 19px;
    }
}

@media screen and (max-width: 767px) {
    .title12 {
        font-size: 16px;
    }
}

@media screen and (max-width: 800px) {
    .title12 .text01 {
        font-size: 40px;
    }
}

@media screen and (max-width: 767px) {
    .title12 .text01 {
        font-size: 30px;
        width: 42px;
    }
}

@media screen and (max-width: 767px) {
    .title12 .text02 {
        padding-left: 5px;
    }
}

.title13 {
    color: #de0716;
    background: none;
    border-top: 1px solid #de0716;
}

.main {
    width: 73.5%;
}

@media screen and (max-width: 767px) {
    .main {
        width: 94%;
        margin: 0 auto;
        float: none;
    }
}

.contentNav {
    font-size: 12px;
    margin-bottom: 20px;

    a {
        text-decoration: underline;
        padding: 0 0 0 28px;
        margin: 12px 0;
        background: url("../../src/ja/images/common/ico_common15.png") no-repeat 5% 50%;
        display: block;

        &:hover {
            color: #de0716;
            -webkit-text-decoration: underline #de0716;
            text-decoration: underline #de0716;
            -webkit-transition-duration: 0ms;
            transition-duration: 0ms;
        }
    }
}

@media screen and (max-width: 767px) {
    .contentNav {
        margin-bottom: 30px;
        z-index: 1;
        position: relative;
    }
}

@media screen and (max-width: 767px) {
    .contentNav a {
        padding: 12px 0 12px 15px;
        background: url("../../src/ja/images/common/ico_common15.png") no-repeat 0 50%;
    }
}

@media screen and (min-width: 768px) {
    .contentNav .two01 a {
        padding: 0;
    }
}

.contentNav01 li {
    font-size: 12px;
    margin: 0 15px 15px 0;

    a {
        border-bottom: 1px solid #fff;
    }
}

.contentNav02 li {
    font-size: 12px;
    margin-right: 2%;

    &:not(:nth-child(3n)) {
        margin-right: 2%;
    }

    a {
        border-bottom: 1px solid #fff;
    }
}

@media screen and (max-width: 767px) {
    .contentNav02 li {
        margin-right: 5%;
    }
}

@media screen and (max-width: 767px) {
    .contentNav02 li {
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .contentNav02 li:not(:nth-child(3n)) {
        margin-right: 5%;
    }
}

@media screen and (max-width: 767px) {
    .contentNav02 li:nth-child(2n + 1) {
        clear: both;
    }
}

.contentNav03 {
    li {
        margin: 0 15px 15px 0;
        float: left;
    }

    a {
        padding-left: 24px;
        background: url("../../src/ja/images/common/ico_common15.png") no-repeat 10% 50%;
        display: block;

        &:hover {
            -webkit-transition-duration: 0ms;
            transition-duration: 0ms;
        }
    }
}

@media screen and (max-width: 767px) {
    .contentNav03 a {
        padding: 12px 14px 12px 24px;
        background: url("../../src/ja/images/common/ico_common15.png") no-repeat 0% 50%;
    }
}

.btn01 {
    background: #333;
    border-radius: 3px;

    a {
        color: #fff;
        padding: 15px 0 14px;
        display: block;
    }

    input {
        color: #fff;
        width: 100%;
        height: 50px;
        background: #333;
        border-radius: 3px;

        &:hover {
            opacity: 0.8;
        }
    }
}

.checkbox {
    padding-left: 22px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    &:before {
        width: 16px;
        height: 16px;
        border: 2px solid #de0716;
        border-radius: 3px;
        z-index: 3;
        top: 1px;
        left: 0;
    }

    &:after {
        width: 4px;
        height: 9px;
        margin-top: -6px;
        border-right: 3px solid #de0716;
        border-bottom: 3px solid #de0716;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        z-index: 1;
        display: block;
        top: 10px;
        left: 7px;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 41px 0px #fff;
        box-shadow: 41px 0px #fff;
        -moz-appearance: none;
        -webkit-appearance: none;
        z-index: 2;
        display: block;
        position: absolute;
        left: -40px;

        &:checked {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}

@media screen and (max-width: 400px) {
    .checkbox {
        font-size: 13px;
        letter-spacing: -0.5px;
        padding: 3px 0 3px 32px;
    }
}

@media screen and (max-width: 400px) {
    .checkbox:before {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .checkbox:after {
        width: 6px;
        height: 14px;
        top: 9px;
        left: 8px;
    }
}

@media screen and (max-width: 400px) {
    .checkbox input[type="checkbox"] {
        width: 22px;
        height: 22px;
    }
}

.radio {
    padding-left: 25px;
    vertical-align: middle;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    &:before {
        width: 16px;
        height: 16px;
        border: 1px solid #c00;
        border-radius: 50%;
        z-index: 3;
        top: 1px;
        left: 0;
    }

    &:after {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #c00;
        z-index: 1;
        top: 5px;
        left: 4px;
    }

    input[type="radio"] {
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 41px 0px #fff;
        box-shadow: 41px 0px #fff;
        -moz-appearance: none;
        -webkit-appearance: none;
        z-index: 2;
        display: block;
        position: absolute;
        left: -40px;

        &:checked {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}

@media screen and (max-width: 400px) {
    .radio {
        font-size: 13px;
        width: 100%;
        letter-spacing: -0.5px;
        padding: 3px 0 3px 32px;
    }
}

@media screen and (max-width: 400px) {
    .radio:before {
        width: 20px;
        height: 20px;
        top: 2px;
    }
}

@media screen and (max-width: 400px) {
    .radio:after {
        width: 14px;
        height: 14px;
        top: 6px;
        left: 4px;
    }
}

@media screen and (max-width: 400px) {
    .radio input[type="radio"] {
        width: 23px;
        height: 23px;
    }
}

.content {
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .content:last-child {
        margin-bottom: 0;
    }
}

.category01 {
    font-size: 12px;
    color: #fff;
    padding: 2px 10px;
    margin-left: 10px;
}

.tag01 {
    background: #de0716;
}

.tag02 {
    width: 65px;
    padding: 2px 0;
    background: #de0716;
}

.tag03,
.tag04,
.tag05,
.tag06,
.tag07,
.tag08 {
    background: #de0716;
}

@media screen and (max-width: 400px) {
    .table {
        font-size: 13px;
        border-collapse: collapse;
        border-spacing: 0;

    }
}

.table {
    table {
        width: 100%;

    }

    th,
    td {
        border-bottom: 1px solid #ccc;
        vertical-align: middle;
    }

    th:first-child,
    td:first-child,
    th:nth-child(2),
    td:nth-child(2) {
        border-top: 1px solid #ccc;
    }

    th {
        padding: 10px 0;
    }

    td {
        padding: 10px;
    }
}

.table01 th {
    width: 160px;
}

@media screen and (max-width: 767px) {
    .table01 th {
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .table01 td {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .table02 th {
        width: 44%;
    }
}

.table03 {
    th {
        width: 10%;
    }

    td {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {
    .table03 .spCol01 {
        width: 100%;
        display: block;
    }
}

.table04 th {
    background: #fff;
}

@media screen and (min-width: 768px) {
    .table04 th {
        width: 11%;
        padding: 2px 0;
    }
}

.table05 th {
    width: 160px;
}

@media screen and (max-width: 767px) {
    .table05 th {
        width: 20%;
    }
}

@media screen and (max-width: 500px) {
    .table05 th {
        width: 22%;
        padding: 10px 5px;
    }
}

@media screen and (max-width: 767px) {
    .spTable01 {
        margin-bottom: 10px;
    }
}


.detail01 {
    margin-bottom: 30px;
}

.attention01 {
    padding: 10px;
    background: #efefef;
    border: 1px solid #ccc;

    li:not(:last-child) {
        margin-bottom: 5px;
    }
}

.blankLink a {
    background: url("../../src/ja/images/common/ico_common09.png") no-repeat 100% 4px;
    background-size: 15px;
    border-bottom: 1px solid #333;
    padding: 4px 18px 0 0;
}

.tab01 {
    li {
        font-size: 16px;
        width: 25%;
        padding: 8px 0 10px;
        background: #dcdcdc;
        border: 1px solid #fff;
        border-top: 6px solid #bcbcbc;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            border-top: 6px solid #de0716;
        }
    }

    a {
        display: block;
    }

    .spOnly02 {
        display: none;
        @media screen and (max-width: 400px) {
            display: block;
        }
    }

    .active {
        background: #fff;
        border-top: 6px solid #de0716;
        transition-duration: 0ms;

        // a {
        //     border-top: 6px solid #de0716;
        // }
    }

    // a {
    //     padding: 8px 0 10px;
    //     border: 1px solid #fff;
    //     border-top: 6px solid #bcbcbc;
    //     display: block;

    //     &:hover {
    //         border-top: 6px solid #de0716;
    //     }
    // }
}

@media screen and (max-width: 768px) {
    .tab01 li {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .tab02 .active {
        background: #f7d8da;
        border: 1px solid #f7d8da;
        border-top: 6px solid #de0716;
    }
}

.newsArea01 {
    border: 1px solid #e1e1e1;

    .category01 {
        margin: 0 10px;
    }

    .text01 {
        width: 74%;
    }

    .news01:last-child {
        border: 1px solid #e1e1e1;

        &:before {
            content: "";
            display: block;
        }
    }

    .newsContent01 {
        padding: 0 15px;
    }

    .newsDetail01 {
        padding: 15px;

        &:not(:last-child) {
            border-bottom: 1px dotted #999;
        }
    }

    .newsList01:not(.active) {
        display: none;
    }
}

@media screen and (max-width: 1060px) and (min-width: 768px) {
    .newsArea01 {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .newsArea01 {
        border: 0;
    }
}

@media screen and (max-width: 1060px) {
    .newsArea01 .category01 {
        margin: 0 1.5%;
    }
}

@media screen and (max-width: 500px) {
    .newsArea01 .category01 {
        margin: 0 auto;
    }
}

@media screen and (max-width: 1060px) and (min-width: 768px) {
    .newsArea01 .text01 {
        width: 68%;
    }
}

@media screen and (max-width: 767px) {
    .newsArea01 .text01 {
        width: 100%;
        margin-top: 5px;
        clear: both;
    }
}

@media screen and (max-width: 767px) {
    .newsArea01 .newsContent01 {
        margin-top: 0;
        border: 1px solid #e1e1e1;
    }
}

@media screen and (max-width: 500px) {
    .newsArea01 .newsDetail01 div p {
        float: none;
    }
}

.readerArea01 {
    font-size: 12px;
    margin-top: 25px;
    line-height: 1.4;

    p {
        &:first-child {
            width: 22%;
        }

        &:last-child {
            width: 76%;
            margin: 3px 0 0 2%;
        }
    }
}

@media screen and (max-width: 640px) {
    .readerArea01 p:first-child {
        width: 100%;
        float: none;
    }
}

@media screen and (max-width: 640px) {
    .readerArea01 p:last-child {
        width: 100%;
        margin: 5px 0 0 0;
        float: none;
    }
}

.selectArea01 {
    width: 300px;
    margin: 20px 0 10px;
    background: url("../../src/ja/images/common/ico_ir01.png") no-repeat 100% 0, #f1f1f1;
    border: 1px solid #bcbcbc;
    overflow: hidden;

    select {
        font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic",
            sans-serif;
        width: 100%;
        height: 43px;
        padding-left: 10px;
        background: none;
        border: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        cursor: pointer;

        &:not(:target) {
            width: 130% \9;
        }

        &:focus {
            outline: none;
        }
    }
}

@media screen and (max-width: 640px) {
    .selectArea01 {
        width: 100%;
        margin: 20px 0;
    }
}

@media all and (-ms-high-contrast: none) {
    .selectArea01 select {
        width: 100% !important;

        &::-ms-expand {
            display: none;
        }
    }
}

.required01 {
    color: #c00;
}

.required02 {
    font-size: 10px;
    vertical-align: super;
}

.newsList01 ul:not(:nth-child(2)) {
    display: none;
}

.selectArea01 {
    width: 224px;
    height: 35px;
    background-position: 100% -6px;

    select {
        height: 35px;
    }
}

.newsDetail01 p:nth-child(2) {
    width: 250px;
}

@media screen and (max-width: 767px) {
    .newsDetail01 p:nth-child(2) {
        width: 141px;
    }
}

@media screen and (max-width: 500px) {
    .newsDetail01 p:nth-child(2) {
        width: 100%;
    }
}

.newsArea01 .text01 {
    width: 57%;
}

@media screen and (max-width: 1000px) {
    .newsArea01 .text01 {
        width: 54%;
    }
}

@media screen and (max-width: 767px) {
    .newsArea01 .text01 {
        width: 100%;
    }
}

.disclosureActive {
    border-bottom: 1px solid #fff;
}

.pointer:hover {
    cursor: pointer;
}

.pageBtnArea {
    font-size: 14px;
    width: 351px;
    margin: 20px auto 40px;
    display: block;

    li {
        margin-right: 2%;

        &:nth-child(2) {
            margin-right: 5%;
        }

        &:nth-child(n + 3):nth-child(-n + 5) {
            margin-right: 3%;
        }

        &:nth-child(6) {
            margin-right: 5%;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .pageNumber {
        font-size: 16px;

        &.active a {
            color: #de0716;
            -webkit-text-decoration: underline #de0716;
            text-decoration: underline #de0716;
        }
    }

    .pageBtn01 a {
        color: #fff;
        padding: 0 10px 4px;
        background: #bcbcbc;
        display: block;
    }

    .pageBtn02 a {
        color: #fff;
        background: #bcbcbc;
        padding: 0 10px 3px;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .pageBtnArea {
        font-size: 12px;
        width: 300px;
    }
}

@media screen and (max-width: 400px) {
    .pageBtnArea .pageNumber {
        font-size: 12px;
    }
}

.header {
    padding-top: 30px;
    position: relative;

    h1 img {
        max-width: 288px;
        width: 100%;
    }

    .box01 {
        width: 88px;
        overflow: hidden;
        background: #f5f5f5;
        border: 1px solid #999;
        border-radius: 3px;

        select {
            font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic",
                sans-serif;
            font-size: 13px;
            width: 100%;
            height: 26px;
            background: none;
            padding: 0 0 1px 22px;
            border: none;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            cursor: pointer;

            &:not(:target) {
                width: 130% \9;
            }
        }
    }

    .logoArea01 {
        margin-bottom: 50px;
    }

    .mainMenu01 {
        padding-bottom: 22px;

        li:not(.active) a:hover {
            color: #de0716;
            position: relative;

            &:before,
            &:after {
                content: "";
                margin: 0 auto;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
            }

            &:before {
                width: 142px;
                height: 3px;
                background: #cc0000;
                bottom: -8px;
            }

            &:after {
                width: 7px;
                height: 4px;
                background: url("../../src/ja/images/common/ico_header01.png") no-repeat 50% 100%;
                bottom: -12px;
            }
        }

        .active a {
            color: #de0716;
            position: relative;

            &:before,
            &:after {
                content: "";
                margin: 0 auto;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
            }

            &:before {
                width: 142px;
                height: 3px;
                background: #cc0000;
                bottom: -8px;
            }

            &:after {
                width: 7px;
                height: 4px;
                background: url("../../src/ja/images/common/ico_header01.png") no-repeat 50% 100%;
                bottom: -12px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .header {
        padding: 20px 0;
        background: #fff;
        border-bottom: 1px solid #f4f4f4;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}

@media screen and (max-width: 420px) {
    .header {
        padding: 28px 0 24px;
    }
}

@media screen and (max-width: 767px) {
    .header h1 {
        float: none;
    }
}

@media screen and (max-width: 420px) {
    .header h1 {
        width: 195px;
    }
}

@media screen and (max-width: 767px) {
    .header .box01 {
        width: 90%;
        margin: 30px auto 0;
        background: #fff;
    }
}

@media screen and (max-width: 767px) {
    .header .box01 select {
        font-size: 16px;
        height: 55px;
        padding-left: 40%;
    }
}

@media screen and (max-width: 400px) {
    .header .box01 select {
        padding-left: 38%;
    }
}

@media all and (-ms-high-contrast: none) {
    .header .box01 select {
        width: 100% !important;

        &::-ms-expand {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .header .logoArea01 {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 768px) {
    .header .mainMenu01 {
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 {
        padding: 20px 0 35px;
        background: #eee;
        display: none;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 li {
        width: 100%;
        border-left: 0;
        border-bottom: 1px dotted #d2d2d2;
        text-align: left;
        float: none;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 li:last-child {
        border-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 li:not(.active) a:hover {
        color: #333;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 li:not(.active) a:hover {
        &:before,
        &:after {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 a {
        background: url("../../src/ja/images/common/ico_common03.png") no-repeat 98% 50%;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 .active a {
        color: #333;
    }
}

@media screen and (max-width: 767px) {
    .header .mainMenu01 .active a {
        &:before,
        &:after {
            display: none;
        }
    }
}

.spBtn01 {
    font-weight: bold;
    color: #c00;
    padding: 14px 18px 10px;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 0;

    &:hover {
        opacity: 0.8;
    }

    p {
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        position: relative;

        &:first-child {
            width: 40px;
            height: 33px;
            margin: 0 auto 5px;
        }
    }

    &.active {
        background: #eee;

        span {
            &:first-child {
                -webkit-transform: translateY(14px) rotate(-45deg);
                -ms-transform: translateY(14px) rotate(-45deg);
                transform: translateY(14px) rotate(-45deg);
            }

            &:nth-child(2) {
                left: 50%;
                opacity: 0;
                -webkit-animation: active-menu-bar02 0.8s forwards;
                animation: active-menu-bar02 0.8s forwards;
            }

            &:last-child {
                -webkit-transform: translateY(-14px) rotate(45deg);
                -ms-transform: translateY(-14px) rotate(45deg);
                transform: translateY(-14px) rotate(45deg);
            }
        }
    }

    span {
        width: 100%;
        height: 5px;
        background: #c00;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        display: block;
        position: absolute;

        &:first-child {
            top: 0;
        }

        &:nth-child(2) {
            top: 14px;
        }

        &:last-child {
            bottom: 0;
        }
    }
}

@media screen and (max-width: 420px) {
    .spBtn01 p:first-child {
        width: 40px;
    }
}

@-webkit-keyframes active-menu-bar02 {
    100% {
        height: 0;
    }
}

@keyframes active-menu-bar02 {
    100% {
        height: 0;
    }
}

.EnglishBtn {
    width: 100px;
    text-align: center;

    a {
        font-size: 13px;
        font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic",
            sans-serif;
        border: 1px solid #e6e6e6;
        border-radius: 3px;
        padding: 8px 0;
        background: #f6f6f6;
        display: block;

        &:hover {
            color: #de0716;
        }
    }
}

/*--- /footer ---*/
.footer {
  background-color: #000;
  color: #fff;
  border-top: 8px solid #de0716;
/*    padding-top: 22px;
    background: url("../../src/ja/images/common/bg_footer01.png") repeat-x 0 0;*/
    .footer_pc_wrap{
      display: flex;
      justify-content: space-between;;
      padding: 30px 0;
      .footer_corp_info{
        width: 28%;
        position: relative;
        .footer_corp_address{
          position: absolute;
          bottom: 0;
          img{
            margin-bottom: 12px;
          }
          p{
            margin-bottom: 12px;
            font-size: 12px;
            &:last-child{margin-bottom: 0;}
            &.footer_copy{font-size: 10px;}
          }
        }
      }
      .footer_link_wrap {
        width: 72%;
        .footer_link_inner{
          display: grid;
          grid-template-columns: auto;
          justify-content: space-between;
          grid-template-rows: repeat(11, 24px);
          grid-column-gap: 20px;
          grid-row-gap: 0px;
          font-size: 12px;
          color: #fff;
          .footer_link_area01 { grid-area: 1 / 1 / 7 / 2; }
          .footer_link_area02 { grid-area: 1 / 2 / 7 / 3; }
          .footer_link_area03 { grid-area: 1 / 3 / 7 / 4; }
          .footer_link_area04 { grid-area: 1 / 4 / 2 / 5; }
          .footer_link_area05 { grid-area: 2 / 4 / 3 / 5; }
          .footer_link_area06 { grid-area: 3 / 4 / 4 / 5; }
          .footer_link_area07 { grid-area: 4 / 4 / 5 / 5; }
          .footer_link_area08 { grid-area: 5 / 4 / 6 / 5; }
          .footer_link_area09 { grid-area: 6 / 4 / 7 / 5; }
          li{
            a{
              font-weight: bold;
              color: #fff;
            }
            .footer_area_inner_link{
              margin-top: 16px;
              li{
                margin-bottom: 6px;
                a{
                  font-size: 12px;
                  font-weight: 400;
                  color: #ccc;
                }
              }
            }
          }
        }
      }
    }

    /*.mainMenu01 {
        li {
            a {
                padding: 5px 0;
            }

            &:not(:last-child) {
                position: relative;
            }
        }

        &:not(.active) a:hover {
            color: #de0716;
            position: relative;

            &:before,
            &:after {
                content: "";
                margin: 0 auto;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
            }

            &:before {
                width: 142px;
                height: 3px;
                background: #cc0000;
                bottom: -8px;
            }

            &:after {
                width: 7px;
                height: 4px;
                background: url("../../src/ja/images/common/ico_header01.png") no-repeat 50% 100%;
                bottom: -12px;
            }
        }

        .active a {
            color: #de0716;
            position: relative;

            &:before,
            &:after {
                content: "";
                margin: 0 auto;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
            }

            &:before {
                width: 142px;
                height: 3px;
                background: #cc0000;
                bottom: -8px;
            }

            &:after {
                width: 7px;
                height: 4px;
                background: url("../../src/ja/images/common/ico_header01.png") no-repeat 50% 100%;
                bottom: -12px;
            }
        }
    }*/

/*    .group01 {
        margin: 40px auto 50px;

        li {
            display: inline-block;
            margin-bottom: 15px;
            margin-right: 3em;

            &:nth-child(3n + 1) {
                clear: both;
            }

            a {
                text-decoration: underline;
                padding-left: 15px;
                background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px;

                &:hover {
                    color: #de0716;
                    position: relative;
                    opacity: 0.8;
                    -webkit-text-decoration: underline #de0716;
                    text-decoration: underline #de0716;
                    -webkit-transition-duration: 0ms;
                    transition-duration: 0ms;
                }
            }
        }

        .noLink {
            padding-left: 20px;
            background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px;
        }
    }*/

    /*.subArea01 {
        padding: 40px 0 100px;
        background: #f7f7f7;

        ul {
            text-align: center;
            margin: 0 auto;

            li {
                display: inline-block;
                margin: 0 10px;
            }
        }

        a {
            text-decoration: underline;
            padding-left: 8px;
            background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 8px;

            &:hover {
                color: #de0716;
                -webkit-text-decoration: underline #de0716;
                text-decoration: underline #de0716;
                -webkit-transition-duration: 0ms;
                transition-duration: 0ms;
            }
        }
    }*/

/*    .copyright01 {
        font-size: 12px;
        color: #818181;
        margin-top: 50px;
    }*/

  .pageTop {
    width: 60px;
    background: #fff;
    z-index: 10;
    position: fixed;
    right: 0px;
    bottom: 0px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    a {
      padding: 17px 0 22px;
      display: block;
      -webkit-filter: invert(1);
      filter: invert(1);
    }
  }
  .sp_tab_copy{display: none;}
}

@media screen and (max-width: 1000px) {
  .footer{
    padding-bottom: 30px;
    .footer_pc_wrap{
      padding: 30px 0 10px;
      .footer_corp_info{
        display: none;
      }
      .footer_link_wrap{
        width: 100%;
        .footer_link_inner{
          max-width: 600px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          li{
            margin: 0 10px 20px;
            .footer_area_inner_link{
              display: none;
            }
          }
        }
      }
    }
    .sp_tab_copy{
      display: block;
      font-size: 10px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer{
    .footer_pc_wrap{
      padding: 30px 20px 14px 40px;
/*     .footer_corp_info{
     }*/
      .footer_link_wrap{
        .footer_link_inner{
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, 1fr);
          grid-column-gap: 40px;
          max-width: 340px;
          .footer_link_area01 { grid-area: 1 / 1 / 2 / 2; }
          .footer_link_area02 { grid-area: 2 / 1 / 3 / 2; }
          .footer_link_area03 { grid-area: 3 / 1 / 4 / 2; }
          .footer_link_area04 { grid-area: 4 / 1 / 5 / 2; }
          .footer_link_area05 { grid-area: 1 / 2 / 2 / 3; }
          .footer_link_area06 { grid-area: 2 / 2 / 3 / 3; }
          .footer_link_area07 { grid-area: 3 / 2 / 4 / 3; }
          .footer_link_area08 { grid-area: 4 / 2 / 5 / 3; }
          .footer_link_area09 { grid-area: 5 / 1 / 6 / 2; }
          li{
            margin: 0 0 16px 0;
          }
        }
      }
    }
  }
}

/*@media screen and (max-width: 767px) {
    .footer .mainMenu01 {
        padding-bottom: 7px;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 .container {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 li {
        font-size: 14px;
        width: 50%;
        border-left: 0;
    }
}

@media screen and (max-width: 350px) {
    .footer .mainMenu01 li {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 li:not(:last-child):after {
        content: "";
        margin: 8px 0;
        border-top: 1px solid #ccc;
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 li:nth-child(2n + 1) a {
        border-right: 1px solid #ccc;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 li:nth-child(2n + 1) {
        clear: both;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 li:last-child {
        border-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01:not(.active) a:hover {
        color: #333;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01:not(.active) a:hover {
        &:before,
        &:after {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 .active a {
        color: #333;
    }
}

@media screen and (max-width: 767px) {
    .footer .mainMenu01 .active a {
        &:before,
        &:after {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 .title02 {
        font-size: 14px;
        margin-bottom: 0;
        padding: 13px 0;
        background: url("../../src/ja/images/common/ico_footer03.png") no-repeat 92% 50%, #fff;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 .title02.active {
        color: #fff;
        margin-bottom: 0;
        padding: 13px 0;
        background: url("../../src/ja/images/common/ico_footer04.png") no-repeat 92% 50%, #999;
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
    }
}

@media screen and (min-width: 768px) {
    .footer .group01 ul {
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 ul {
        width: 94%;
        margin: 25px auto 30px;
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 li {
        width: 100%;
        margin-right: 0;
        float: none;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 li:nth-child(3n + 1) {
        clear: none;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 li a {
        padding: 5px 15px 5px 8px;
        background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 13px;
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .footer .group01 .noLink {
        padding: 5px 15px 5px 8px;
        background: url("../../src/ja/images/common/ico_footer01.png") no-repeat 0 13px;
    }
}

@media screen and (max-width: 767px) {
    .footer .subArea01 {
        padding: 25px 0;
    }
}

@media screen and (max-width: 767px) {
    .footer .subArea01 ul {
        text-align: center;
        margin: 0 auto;
    }
}*/

/*@media screen and (max-width: 767px) {
    .footer .copyright01 {
        margin-top: 30px;
    }
}*/
/*--- /footer ---*/

.main404 {
    a {
        text-decoration: underline;
        font-weight: normal;

        &:hover {
            color: #ff0000;
        }
    }
    @media screen and (max-width: 767px) {
        width: 94%;
        margin: 0 auto;
    }
}

.indent-news-text {
    text-indent: 1rem;
}

// 新規追加 共通使用可能項目 2023 //
// レスポンシブ時 ページ内リンクの位置調整
@media screen and (max-width: 767px) {
    .anchorPoint {
        padding-top: 80px;
        margin-top: -80px;
    }
}
// pc tablet sp デバイス別表示切り替え
.DisplayTabOnly{
    display: none;
}
.DisplaySpOnly{
    display: none;
}
@media screen and (max-width: 820px) {
    .DisplayPcOnly{
        display: none;
    }
    .DisplayTabOnly{
        display: block;
    }
}
@media screen and (max-width: 767px) {
    .DisplayTabOnly{
        display: none
    }
    .DisplaySpOnly{
        display: block;
    }
}
// テキスト
.fontB {
    font-weight: bold;
}
.font10{
    font-size: 10px;
}
.txtRed {
    color: #de0716;
}
// 背景黒 リンクボタン
.btnBgBlack {
    max-width: 250px;
    margin: 20px auto 0;
    a {
        position: relative;
        display: block;
        padding: 14px 24px;
        color: #fff;
        background-color: #333;
        border: 1px solid #646464;
        border-radius: 3px;
        text-align: center;
        text-decoration: none;
        &::after {
            content: "";
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: url(../../src/ja/images/common/ico_common06.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: 8px;
            height: 10px;
        }
        &:hover {
            opacity: 0.8;
            color: #fff !important;
        }
    }
}
// 右側ファイルアイコン付きリンク
.fileIconLink {
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #333;
    padding-right: 25px;
    text-decoration: underline;
    &:hover {
        color: #de0716;
    }
    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 15px;
        background-image: url(../../src/ja/images/common/ico_common09.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
}
// 左側矢印付きリンク
.arrowLink {
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #333;
    padding-left: 15px;
    text-decoration: underline;
    &:hover {
        color: #de0716;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-image: url(../../src/ja/images/common/ico_common03.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
}